/**
 * Formulaire concernant l’honorabilité du candidat
 * visant le portail IMAS (Banque Centrale Européenne)
 * voir: https://app.asana.com/0/1181691983450197/1202434322809570/f
 *
 * NOTE: Les questions concernant les conflits d’intérêts avec l’entité
 * visée par la candidature sont implémentées dans la partie Candidature
 */
import React, { memo } from "react";
import { Typography, Box } from "@material-ui/core";
import TranslatableTextInputCustom from "../../components/input/TranslatableTextInputCustom";
import ConditionalInput from "../../components/input/ConditionalInput";
import TranslationInformation from "../../components/TranslationInformation";

import InfoBulle from "../../components/InfoBulle";

import Condamnation from "./Infraction/Condamnation";
import MesureDisciplinaire from "./Infraction/Procedure";
import Faillite from "./Infraction/Faillite";
import { useLangue } from "../../candidatures/transalations";

function Subtitle(props) {
  return (
    <Box maxWidth="72em">
      <Typography {...props} />
    </Box>
  );
}

export default memo(({ tabName, disabled }) => {
  const { t } = useLangue();

  return (
    <Box>
      <TranslationInformation />
      <Box display="flex" justifyContent="flex-end">
        <InfoBulle position="right" nonHoverable>
          Dans cette section , « vous » s’entend comme « la personne nommée
          personnellement » et inclut également toute personne morale, tout
          partenariat et toute entité sans personnalité juridique avec lequel ou 
          laquelle la personne nommée en tant que personne physique est ou a été
          associée en tant que membre du conseil d’administration, titulaire
          d’un poste clé, cadre supérieur(e), actionnaire, partenaire,
          associé(e), ou actionnaire qualifié(e). Seuls les actes répréhensibles
          qui auraient été commis pendant la période où la personne nommée était
          associée à l’entité doivent être déclarés. Les réponses apportées aux
          questions ci-dessous doivent inclure les affaires traitées dans des
          pays n’appartenant pas à l’Union européenne.
          <br />
          Les manquements ou infractions ayant donné lieu à amnistie n’ont pas à
          être mentionnés.
        </InfoBulle>
      </Box>

      {/* 4 A. page 16 */}
      <ConditionalInput
        disabled={disabled}
        onNo={(formData) => Condamnation.clear(formData)}
        property="infractionsIMAS.condamnation"
        label={t(
          "Faites-vous ou avez-vous fait l’objet d’une procédure pénale une procédure civile ou administrative (y compris toute procédure en cours, menée à terme ou en appel) ? Cette question couvre les enquêtes, les procédures de sanction ou les mesures prises ou imposées par des autorités publiques ou de surveillance ou des organisations professionnelles (par exemple : avertissement, blâme, etc.) compétentes dans toute juridiction."
        )}
        tabName={tabName}
      >
        <Condamnation tabName={tabName} />
      </ConditionalInput>

      <Box display="flex" justifyContent="flex-end">
        <InfoBulle position="right" nonHoverable>
          <p>{t("Il est inutile d’indiquer toute mention effacée du casier judiciaire officiel. Les mentions qui n’ont pas été effacées du casier judiciaire doivent être déclarées indépendamment du temps écoulé depuis que les faits sous-jacents ont été commis. Le cas échéant, veuillez consulter également la note de bas de page 17 du document sur les spécificités nationales figurant à la section 9.")}</p>
          <p>{t("Les exemples de procédures civiles ou administratives incluent les procédures dans les domaines suivants: de la banque, de l’assurance, des services d’investissement, des marchés des titres, des instruments de paiement, du blanchiment de capitaux, des retraites, de la gestion d’actifs ou dans tout secteur financier réglementé et comprennent tout avis officiel d’enquête ou de mise en accusation, de mesure disciplinaire en cours ou de procédure en cours de faillite, d’insolvabilité ou de procédure similaire en cours, ou de violation du droit de la concurrence. Dans tous les cas, les informations concernant les procédures administratives ou civiles doivent inclure les procédures portant sur les cinq critères d’honorabilité, de connaissances, de compétences et d’expérience, conformément à la législation nationale transposant la CRD. Pour plus d’informations sur ce qui doit être considéré comme pertinent, veuillez vous référer à la section 3.2 du Guide relatif à l’évaluation de l’honorabilité, des connaissances, des compétences et de l’expérience.")}</p>
        </InfoBulle>
      </Box>

      {/* 4 B - page 18 */}
      <ConditionalInput
        disabled={disabled}
        onNo={(formData) => MesureDisciplinaire.clear(formData)}
        property="infractionsIMAS.mesuresDisciplinaires"
        label={t(
          "Faites-vous ou avez-vous personnellement fait l’objet de mesures disciplinaires (y compris d’une exclusion d’une fonction de membre de l’organe de direction ou d’un renvoi d’un poste de confiance) ?"
        )}
        tabName={tabName}
      >
        <MesureDisciplinaire tabName={tabName} />
      </ConditionalInput>

      {/* 4 C page 18 */}
      <ConditionalInput
        property="infractionsIMAS.procedureFaillite"
        onNo={(formData) => Faillite.clear(formData)}
        disabled={disabled}
        label={t(
          "Avez-vous fait ou faites-vous l’objet d’une procédure de faillite ou d’insolvabilité ou d’une procédure similaire ?"
        )}
      >
        <Faillite tabName={tabName} />
      </ConditionalInput>

      {/* 4 D page 19 */}
      <ConditionalInput
        disabled={disabled}
        property="infractionsIMAS.restructuration"
        label={t(
          "Un établissement financier dans lequel vous occupez ou avez occupé une fonction de direction, ou dont vous influencez ou avez influencé concrètement la gestion, de quelque manière que ce soit, a-t-il déjà fait l’objet d’un renflouement ou été soumis à une procédure de restructuration, de rétablissement ou de résolution ?"
        )}
      />

      {/* 4 E page 20 */}
      <ConditionalInput
        property="infractionsIMAS.debiteurInsolvable"
        disabled={disabled}
        label={t(
          "À votre connaissance, avez-vous déjà personnellement figuré sur une liste de débiteurs peu fiables, faites-vous l’objet d’une appréciation négative sur une liste établie par une agence de crédit reconnue ou avez-vous fait l’objet d’une mesure d’exécution pour une dette inscrite sur une liste de ce type ?"
        )}
      />

      {/* 4 F page 19 */}
      <ConditionalInput
        property="infractionsIMAS.interdictionGestion"
        disabled={disabled}
        label={t(
          "Avez-vous déjà fait l’objet d’un refus, d’un retrait, d’une révocation, d’une suspension ou d’une annulation d’enregistrement, d’autorisation, d’adhésion ou de licence concernant l’exercice d’activités commerciales ou professionnelles ? Cette question porte également sur les évaluations d’aptitude antérieures réalisées par d’autres autorités compétentes (y compris à l’étranger)."
        )}
      />

      {/* 4 G page 19 */}
      <ConditionalInput
        property="infractionsIMAS.blanchiment"
        disabled={disabled}
        label={t(
          "En dehors des cas mentionnés par ailleurs dans vos réponses, avez-vous déjà été impliqué(e) directement ou indirectement dans une situation ayant suscité des préoccupations ou des soupçons quant à d’éventuelles activités de blanchiment de capitaux ou de financement du terrorisme ?"
        )}
      />

      {/* 4 - H page 20 */}
      <ConditionalInput
        property="infractionsIMAS.transparence"
        disabled={disabled}
        label={t(
          "Avez-vous déjà personnellement omis de déclarer toute information que l’autorité de surveillance aurait été en droit d’attendre ?"
        )}
      />

      <Subtitle variant="subtitle1">
        ▸{" "}
        {t(
          "À compléter par l’entité soumise à la surveillance prudentielle : s’il a été répondu « Oui » à au moins une des questions ci-dessus, évaluez la réputation de la personne nommée en tenant compte des faits pertinents et en expliquant précisément pourquoi vous considérez qu’ils ne nuisent pas à l’aptitude de la personne nommée."
        )}
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        fullWidth
        source="infractionsIMAS.reputationAssessement"
        id={`${tabName}#infractionIMAS.reputationAssessement`}
      />
    </Box>
  );
});
