import jsonServerProvider from "ra-data-json-server";
import { httpClient, authedFetch } from "./authProvider";

const { REACT_APP_API_URL } = process.env;

const dataProvider = jsonServerProvider(REACT_APP_API_URL, httpClient);

async function uploadFile(name, id, file) {
  const formData = new FormData();

  formData.append(`${name}Id-${id}`, file, file.name || "file");

  const results = await authedFetch(
    `${REACT_APP_API_URL}/candidatures/upload-files`,
    {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    }
  );

  if (results && results.status === 200) {
    return results.json();
  } else {
    throw new Error("Le fichier n’a pu être enregistré");
  }
}

function uploadFiles(name, id, files) {
  if (files.length === 0) return;
  return Promise.all(files.map((file) => uploadFile(name, id, file)));
}

async function removeFile({ id }) {
  const body = JSON.stringify({ fileId: id });

  const results = await authedFetch(
    `${REACT_APP_API_URL}/candidatures/remove-file`,
    {
      method: "POST",
      body,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  if (!results) {
    throw new Error("Le fichier n’a pas pu être supprimé");
  }
}

function deleteFiles(files) {
  return Promise.all(files.map((file) => removeFile(file)));
}

const CANDIDAT_DOCS = ["cv", "pieceIdentite"];
const REFUS_ET_VALIDATION_DOCS = ["justificatif"];
const CANDIDATURE_DOCS = [
  "politiqueConflitsInterets",
  "formulaireSuperviseur",
  "procesVerbal",
  "delegationPouvoir",
  "extraitCasier",
  "declarationCondamnation",
  "notificationEvaluation",
  "organigrammeEntite",
  "organigrammeGroupeAppartenance",
  "evaluationAdequation",
  "autre",
];

/* eslint-disable-next-line */
function getFilesToUpload(key, data, previousData) {
  let files = data[key];
  if (!Array.isArray(files)) files = [files];

  return (
    files
      // only files with no id (not on server) and a rawFile
      .filter((d) => !d.id && d.rawFile !== null)
      .map((d) => d.rawFile)
  );
}

function getFilesToDelete(key, data, previousData) {
  if (!data[key]) return [];
  let files = data[key];
  if (!Array.isArray(files)) files = [files];

  let previousFiles = previousData[key];
  if (!Array.isArray(previousFiles))
    previousFiles = [previousFiles].filter((i) => !!i);

  return previousFiles.filter((pf) => !files.find((f) => f && f.id === pf.id));
}

async function handleFiles(keys, params) {
  let { data, previousData } = params;
  data = Array.isArray(data) || !data ? data : [data];
  previousData =
    Array.isArray(previousData) || !previousData
      ? previousData
      : [previousData];

  for (let key of keys) {
    for (let d of data) {
      let p = undefined;

      if (d.id != null) {
        p = previousData.find((pd) => pd.id === d.id);
      }

      if (d[key]) {
        const filesToUpload = getFilesToUpload(key, d, p);

        if (filesToUpload.length > 0) {
          await uploadFiles(key, d.id, filesToUpload);
          delete params.data[key];
        }

        const filesToDelete = getFilesToDelete(key, d, p);

        if (filesToDelete.length > 0) {
          await deleteFiles(filesToDelete);
          delete params.data[key];
        }
      }
    }
  }
}

export default {
  ...dataProvider,
  async create(resource, params) {
    const dataHolder = {};

    if (resource === "candidats") {
      CANDIDAT_DOCS.forEach((key) => {
        if (params.data[key]) {
          dataHolder[key] = params.data[key];
          delete params.data[key];
        }
      });

      REFUS_ET_VALIDATION_DOCS.forEach((key) => {
        dataHolder[key] = [];

        params.data.refusEtValidations &&
          params.data.refusEtValidations.forEach((r, idx) => {
            if (r[key]) {
              dataHolder[key][idx] = r[key];
              delete params.data.refusEtValidations[key];
            }
          });
      });
    }

    if (resource === "candidatures") {
      CANDIDATURE_DOCS.forEach((key) => {
        if (params.data[key]) {
          dataHolder[key] = params.data[key];
          delete params.data[key];
        }
      });
    }

    const result = await dataProvider.create(resource, params);

    const candidatId = result?.data?.id;
    if (candidatId) {
      await Promise.all(
        Object.entries(dataHolder).map(([key, value]) => {
          if (!value) return Promise.resolve();

          let files = value;
          if (!Array.isArray(files)) files = [files];

          files = files.filter((f) => !!f).map((f) => f.rawFile);

          return uploadFiles(key, candidatId, files);
        })
      );
    }

    return result;
  },

  async update(resource, params) {
    if (resource === "candidats") {
      await handleFiles(CANDIDAT_DOCS, params);

      const refusEtValidationParams = {
        data: params.data.refusEtValidations,
        previousData: params.previousData.refusEtValidations,
      };

      await handleFiles(REFUS_ET_VALIDATION_DOCS, refusEtValidationParams);
    }

    if (resource === "candidatures") {
      await handleFiles(CANDIDATURE_DOCS, params);
    }

    return dataProvider.update(resource, params);
  },

  async delete(resource, params) {
    return dataProvider.delete(resource, params);
  },
};
