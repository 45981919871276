/**
 * Formulaire concernant l’honorabilité du candidat.
 * visant le portail Autorisations (ACPR, Banque de France)
 * voir: https://app.asana.com/0/1181691983450197/1205931704192931/f
 *
 * NOTE: Les questions concernant les conflits d’intérêts avec l’entité
 * visée par la candidature sont implémentées dans la partie Candidature
 */
import React, { memo } from "react";
import { Typography, Box } from "@material-ui/core";
import TranslatableTextInputCustom from "../../components/input/TranslatableTextInputCustom";
import ConditionalInput from "../../components/input/ConditionalInput";
import TranslationInformation from "../../components/TranslationInformation";

import InfoBulle from "../../components/InfoBulle";
import { useFieldState } from "../../candidatures/form/CandidatureFonctions/hooks";
import ConcerneDirectement from "./Infraction/ConcerneDirectement";
import ConcerneEntite from "./Infraction/ConcerneEntite";
import { useLangue } from "../../candidatures/transalations";

function Subtitle(props) {
  return (
    <Box maxWidth="72em">
      <Typography {...props} />
    </Box>
  );
}

export default memo(({ tabName, disabled }) => {
  const { t } = useLangue();

  const [condamnation] = useFieldState("infractionsACPR.condamnation");
  const [procedureEnCours] = useFieldState("infractionsACPR.procedureEnCours");
  const [mesuresDisciplinaires] = useFieldState("infractionsACPR.mesuresDisciplinaires");
  return (
    <Box>
      <TranslationInformation />
      <Box display="flex" justifyContent="flex-end">
        <InfoBulle position="right" nonHoverable>
          Dans l’ensemble de cette partie, les questions s’adressent à la
          personne nommée (renouvelée) ainsi qu’à toute personne morale (ou
          partenariat ou entité sans personnalité juridique) dont la personne
          nommée (renouvelée) est un actionnaire significatif (défini comme un
          actionnaire détenant au moins 10% du capital ou des droits de vote
          d’une entreprise) ou un associé (associé en nom ou associé commandité)
          ou un membre de l’organe de direction (ou toute autre fonction en
          rapport avec le délit présumé ou reconnu) au moment du délit (présumé
          ou reconnu), et qu’il conviendra de bien identifier le cas échéant.
          <br />
          Les manquements ou infractions ayant donné lieu à amnistie n’ont pas à
          être mentionnés.{" "}
        </InfoBulle>
      </Box>

      {/* 2.1 page 7 */}
      <ConditionalInput
        disabled={disabled}
        property="infractionsACPR.condamnation"
        noTranslate
        onNo={(formData) => (formData.condamnationText = null)}
        label={t(
          "Avez-vous personnellement ou la personne morale en lien avec vos responsabilités actuelles ou anciennes déjà fait l'objet d'une procédure, y compris une procédure clôturée ou en appel, dans le cadre d'une procédure pénale, civile ou administrative (ayant eu une incidence sur votre honorabilité ou une incidence significative sur votre situation financière) ?"
        )}
        textInputLabel="Veuillez apporter des précisions, en particulier concernant la nature des investigations ou des chefs d'accusation, le temps écoulé depuis le début de la procédure ou de la condamnation, la peine prononcée, le comportement de la personne nommée (renouvelée) depuis les faits en cause, tout éclairage d'ordre professionnel apporté par la personne nommée (renouvelée), toute autre circonstance atténuante et tout autre facteur aggravant :"
        tabName={tabName}
      />

      {/* 2.2 page 7 */}
      <ConditionalInput
        disabled={disabled}
        onNo={(formData) => (formData.procedureEnCoursText = null)}
        property="infractionsACPR.procedureEnCours"
        noTranslate
        label={t(
          "Faites-vous personnellement ou la personne morale en lien avec vos responsabilités actuelles ou anciennes l'objet d'une procédure pénale ou d'une procédure civile ou administrative en cours (y compris tout avis officiel d'enquête ou de mise en accusation) ?"
        )}
        textInputLabel="Veuillez apporter des précisions en particulier concernant la nature des chefs d’accusation, le temps écoulé depuis l’acte répréhensible présumé, la sanction/ peine probable en cas de condamnation, le stade de la procédure, toute autre circonstance atténuante et tout autre facteur aggravant :"
        tabName={tabName}
      />

      {/* 2.3 page 8 */}
      <ConditionalInput
        disabled={disabled}
        onNo={(formData) => (formData.mesuresDisciplinaires = null)}
        property="infractionsACPR.mesuresDisciplinaires"
        noTranslate
        label={t(
          "Des mesures disciplinaires ont-elles déjà été prises contre vous (ou la personne morale en lien avec vos responsabilités actuelles ou anciennes) ou sont-elles en instance vous concernant (y compris la révocation en tant que membre de l'organe de direction d'une société, le licenciement pour faute professionnelle, l'interdiction de gérer...) ?"
        )}
        textInputLabel="Veuilles apporter des précisions :"
        tabName={tabName}
      />

      {/* 2.4 page 8 */}
      <ConditionalInput
        property="infractionsACPR.procedureFaillite"
        onNo={(formData) => (formData.procedureFailliteText = null)}
        disabled={disabled}
        noTranslate
        textInputLabel="Veuillez apporter des précisions en indiquant si la faillite ou l'insolvabilité était demandée ou non :"
        label={t(
          "Avez-vous fait ou faites-vous l’objet d’une procédure de faillite ou d’insolvabilité ou d’une procédure similaire ?"
        )}
      />

      {/* 2.5 page 8 */}
      <Subtitle variant="subtitle1">
        {t(
          "La / les procédure(s) que vous avez déclarée(s) ci-dessus a-t-elle / ont-elles été réglée(s) à l’amiable ou dans le cadre d'un règlement alternatif des litiges (par exemple : la médiation) ?"
        )}
      </Subtitle>
      <ConditionalInput
        property="infractionsACPR.procedureRegleeAmiable"
        noTranslate
        disabled={disabled}
      />

      {/* 2.6 page 8 */}
      <ConditionalInput
        property="infractionsACPR.debiteurInsolvable"
        disabled={disabled}
        noTranslate
        label={t(
          "Avez-vous déjà figuré sur une liste de débiteurs insolvables, faites-vous l’objet d’une inscription négative dans une liste établie par une agence de crédit reconnue ou avez-vous fait l’objet d’une mesure d’ exécution en lien avec une dette ?"
        )}
      />

      {/* 2.7 page 8/9 */}
      <ConditionalInput
        property="infractionsACPR.interdictionGestion"
        disabled={disabled}
        noTranslate
        label={t(
          "Avez-vous déjà fait l’objet d’un refus, d’un retrait, d’une révocation, d’une interdiction de gérer ou d’ une annulation d’enregistrement, d’autorisation, d’adhésion ou de licence concernant l’exercice d’une activité commerciale ou professionnelle ? Une telle procédure est-elle en cours ?"
        )}
      />

      {/* 2.8 page 9 */}
      <ConditionalInput
        disabled={disabled}
        property="infractionsACPR.sanctionSuspension"
        noTranslate
        label={t(
          "Avez-vous jamais été sanctionné(e) par des autorités publiques ou des organisations professionnelles, ou des enquêtes ou procédures exécutoires sont-elles en cours ou ont-elles déjà été engagées contre vous ? En particulier, avez-vous fait l’objet d’une mesure de suspension ou d’exclusion d’une organisation professionnelle en France ou à l’étranger, ou d’une sanction prononcée par un organe central ?"
        )}
      />

      {/* 2.9 page 9 */}
      <ConditionalInput
        property="infractionsACPR.debatAspectsHonorabilite"
        disabled={disabled}
        noTranslate
        label={t(
          `L’organe de direction de l’entité soumise à la surveillance prudentielle a-t-il débattu de points particuliers en ce qui concerne des aspects de votre honorabilité ?`
        )}
        textInputLabel="Veuillez préciser la teneur et les conclusions de ces discussions :"
        infoBulle={`Le terme «&#x202F;organe de direction&#x202F;» désigne, en fonction de
          la forme juridique de l’établissement et de la structure choisie, le
          conseil d’administration ou le conseil de surveillance ou de tout
          organe ayant des pouvoirs équivalents (comité des nominations).`}
        infoBullePosition="right"
      >
      </ConditionalInput>

      {(condamnation || procedureEnCours || mesuresDisciplinaires ) && (
        <Box>
          {/* 2.9 page 9 */}
          <ConditionalInput
            property="infractionsACPR.concerneDirectement"
            disabled={disabled}
            noTranslate
            subQuestion
            label={t(
              "Les actes répréhensibles présumés, la procédure, l'enquête ou les sanctions vous concernent-ils directement ?"
              )}
              >
            <ConcerneDirectement tabName={tabName} />
          </ConditionalInput>

          {/* 2.9 page 10 */}
          <ConditionalInput
            property="infractionsACPR.concerneEntite"
            disabled={disabled}
            subQuestion
            label={t(
              "Les actes répréhensibles présumés, la procédure, l'enquête ou les sanctions concernent-ils des entités dans lesquelles vous occupez ou avez occupé des fonctions ?"
              )}
              >
            <ConcerneEntite tabName={tabName} />
          </ConditionalInput>
        </Box>
      )}

      <Subtitle variant="subtitle1">
        ▸{" "}
        {t(
          "À compléter par l’entité soumise à la surveillance prudentielle : s’il a été répondu « Oui » à au moins une des questions ci-dessus, évaluez la réputation de la personne nommée en tenant compte des faits pertinents et en expliquant pourquoi vous considérez qu’ils ne nuisent pas à l’aptitude de la personne nommée."
        )}
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        fullWidth
        noTranslate
        source="infractionsACPR.reputationAssessement"
        id={`${tabName}#infraction.reputationAssessement`}
      />
    </Box>
  );
});
