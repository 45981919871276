import React, { memo, useEffect, useRef } from "react";
import { useField } from "react-final-form";
import { ArrayInput, required } from "react-admin";
import { Typography, Box } from "@material-ui/core";
import TextInputCustom from "../../components/input/TextInputCustom";
import TranslatableTextInputCustom from "../../components/input/TranslatableTextInputCustom";
import DateInputCustom from "../../components/input/DateInputCustom";
import FileInputCustom from "../../components/input/FileInputCustom";
import FormIteratorCustom from "../../components/custom/FormIteratorCustom";
import { useLangue } from "../../candidatures/transalations";

const RefusEtValidation = ({ name, tabName, disabled }) => {
  const { input: dateFin } = useField(`${name}.dateFin`);
  const { t } = useLangue();

  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) return;

    if (dateFin) {
      dateFin.onBlur();
    }

    mounted.current = true;
  }, [dateFin]);

  return (
    <Box>
      <Box display="flex">
        <Box>
          <TextInputCustom
            disabled={disabled}
            label={t("Autorité compétente concernée")}
            source={`${name}.autoriteCompetente`}
            id={`${tabName}#${name}.autoriteCompetente`}
            validate={required()}
          />
        </Box>
        <Box ml="1em">
          <TextInputCustom
            disabled={disabled}
            label={t("Établissement concerné")}
            source={`${name}.etablissement`}
            id={`${tabName}#${name}.etablissement`}
          />
        </Box>
        <Box ml="1em">
          <TranslatableTextInputCustom
            disabled={disabled}
            label={t("Fonction concernée")}
            source={`${name}.fonction`}
            id={`${tabName}#${name}.fonction`}
          />
        </Box>
      </Box>

      <Box display="flex">
        <Box>
          <DateInputCustom
            disabled={disabled}
            label={t("Date de début")}
            source={`${name}.dateDebut`}
          />
        </Box>
        <Box ml="1em">
          <DateInputCustom
            disabled={disabled}
            label={t("Date de fin")}
            source={`${name}.dateFin`}
            validate={(value) => {
              try {
                /* eslint-disable-next-line no-eval */
                const dateDebut = eval(`allValues?.${name}?.dateDebut`);

                return (
                  dateDebut > value &&
                  "La date de fin doit être postérieure à la date de début"
                );
              } catch (_) {
                return;
              }
            }}
          />
        </Box>
        <Box ml="1em">
          <DateInputCustom
            disabled={disabled}
            label={t("Date de l'évaluation")}
            source={`${name}.dateEvaluation`}
          />
        </Box>
      </Box>

      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        label={t("Conclusion de l'évaluation")}
        source={`${name}.conclusion`}
        id={`${tabName}#${name}.conclusion`}
        fullWidth
        helperText="En cas de validation, fournir le justificatif, si l’autorité compétente en délivre un."
      />
      <FileInputCustom
        label={t("Justificatifs")}
        source={`${name}.justificatif`}
        multiple={false}
      />

      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        label={t(
          "Le cas échéant, veuillez indiquer les raisons du ou des refus de validation déclaré(s)"
        )}
        source={`${name}.commentaires`}
        id={`${name}.commentaires`}
        fullWidth
      />
    </Box>
  );
};

export default memo((props) => {
  const { tabName, disabled } = props;
  const { t } = useLangue();

  return (
    <Box mt="1em">
      <Typography variant="h6">
        {t(
          "Validation / refus de validation antérieure par des autorités compétentes bancaires ou financières en France ou à l’étranger (hors ACPR ou BCE) :"
        )}
      </Typography>
      <ArrayInput source="refusEtValidations" label="">
        <FormIteratorCustom
          disabled={disabled}
          component={
            <RefusEtValidation tabName={tabName} disabled={disabled} />
          }
        />
      </ArrayInput>
    </Box>
  );
});
