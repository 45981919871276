import React, { useCallback } from "react";
import { Box } from "@material-ui/core";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";

import { FormDataConsumer, FileInput /*, FileField */ } from "react-admin";

import { authedFetch } from "../../authProvider";
const { REACT_APP_API_URL } = process.env;

function fichierToFile(record, property) {
  const val = _.get(record, property);
  if (val && !val.title && val.name) {
    _.set(record, property, {
      ...val,
      rawFile: new File([], val.name),
      src: `${REACT_APP_API_URL}/candidats/download-file/${val.id}`,
      title: val.name,
    });
  }
}

function fichierToFileMulti(record, property) {
  const val = _.get(record, property);

  if (val && Array.isArray(val)) {
    _.set(
      record,
      property,
      val.map((fichier) => {
        if (!fichier.title && fichier.name) {
          return {
            ...fichier,
            rawFile: new File([], fichier.name),
            src: fichier.url,
            title: fichier.name,
          };
        }

        return fichier;
      })
    );
  }
}

function FichierAdapter({ info }) {
  return (
    <FormDataConsumer subscription={{ values: true }}>
      {({ formData: record }) => {
        Object.entries(info).forEach(([property, type]) => {
          if (type === "single") {
            fichierToFile(record, property);
          } else if (type === "multi") {
            fichierToFileMulti(record, property);
          } else {
            console.warn("Invalid file adaptation type:", type);
          }
        });
      }}
    </FormDataConsumer>
  );
}

const useStyles = makeStyles({
  dropZone: {
    background: "transparent",
    border: "1px dashed rgba(0,0,0,0.6)",
    borderRadius: "4px",
    fontSize: "small",
  },
  preview: {
    display: "inline-block",
    background: "#eee",
    borderRadius: "4px",
    fontSize: "0.8em",
    padding: "0.3em 0.6em",
    cursor: "pointer",
  },
});

async function downloadFile(id, name) {
  const response = await authedFetch(
    `${REACT_APP_API_URL}/candidats/download-file/${id}`
  );

  if (response) {
    const blob = await response.blob();
    const fileUrl =
      typeof window !== "undefined" && window.URL.createObjectURL(blob);

    downloadBlob(fileUrl, name);
  }
}

function downloadBlob(blobUrl, name) {
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = blobUrl;
  // the filename you want
  a.download = `${name}`;
  document.body.appendChild(a);
  a.click();

  typeof window !== "undefined" && window.URL.revokeObjectURL(blobUrl);
}

function FileField({ className, record }) {
  let blob = false;
  if (!record.id && record.rawFile && record.rawFile.size !== 0) {
    blob = record["undefined"];
    record = record.rawFile;
  }

  const title = record.title || record.name || "Fichier invalide";

  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (blob) {
        downloadBlob(blob, title);
      } else {
        downloadFile(record.id, title);
      }
    },
    [record.id, title, blob]
  );

  return (
    <div
      className={className}
      tabIndex={1}
      onClick={onClick}
      title="Télécharger"
    >
      <span>{title}</span>
    </div>
  );
}

export default React.forwardRef((props, ref) => {
  const t = props.multiple ? "multi" : "single";
  let label = props.label || "";

  const classes = useStyles();

  return (
    <Box maxWidth="74em">
      <FichierAdapter info={{ [props.source]: t }} />
      <FileInput
        classes={classes}
        ref={ref}
        {...props}
        label={label}
        labelSingle="Déposez le fichier à téléverser, ou cliquez pour le sélectionner (remplace le fichier présent)"
        labelMultiple="Déposez des fichiers à téléverser, ou cliquez pour en sélectionner (s’ajoute aux fichiers déjà présents)"
      >
        <FileField />
      </FileInput>
    </Box>
  );
});
