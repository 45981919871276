import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "../components/custom/TypographyCustom";
import { Alert, AlertTitle } from "@material-ui/lab";

function showAlert(createdAt) {
  let t = new Date(createdAt);
  const then = new Date("2022-06-14");
  return +t < +then;
}

export default function ConflitAlert({ createdAt }) {
  return (
    <>
      {showAlert(createdAt) && (
        <Box mt="1.75em">
          <Alert severity="warning" variant="filled">
            <AlertTitle>
              <strong>Le formulaire a été revisé</strong>
            </AlertTitle>
            <Typography variant="body1">
              Suite à une évolution du formulaire IMAS, certaines informations
              nécessitent une nouvelle saisie.
              <br />
              <br />
              Merci de vérifier la complétude de chaque onglet.
            </Typography>
          </Alert>
        </Box>
      )}
    </>
  );
}
