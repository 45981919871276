import React, { useMemo, useContext, useEffect, useState } from "react";
import { useFormState } from "react-final-form";

const DEFAULT = "imas";

const PortalTargetContext = React.createContext(DEFAULT);

export function PortalTargetProvider({ children }) {
  const [target, setTarget] = useState(DEFAULT);
  const { values: formData } = useFormState({ subscription: { values: true } });
  // const formData = record;
  const portalTarget = formData?.portalTarget;
  //

  useEffect(() => {
    setTarget(portalTarget);
  }, [portalTarget]);

  const ctx = useMemo(
    () => ({
      target,
      setTarget,
    }),
    [target]
  );

  return (
    <>
      <PortalTargetContext.Provider value={ctx}>
        {children}
      </PortalTargetContext.Provider>
    </>
  );
}

export function usePortalTarget() {
  const ctx = useContext(PortalTargetContext);
  return ctx;
}

export function IsACPR() {
  const portalTarget = usePortalTarget().target;
  return portalTarget === "acpr"
}

export function useOnlyForTargets(target, disabled) {
  let { target: portalTarget } = usePortalTarget();
  if (!portalTarget) portalTarget = "";

  if (!target) {
    return disabled;
  }

  disabled = !!disabled;
  if (!disabled) {
    const d = target !== portalTarget;

    return d;
  }

  return disabled;
}
