import React, { memo } from "react";
import { ArrayInput, FormDataConsumer } from "react-admin";

import Box from "@material-ui/core/Box";

import Typography from "../../components/custom/TypographyCustom";
import ConditionalInput from "../../components/input/ConditionalInput";
import FileInputCustom from "../../components/input/FileInputCustom";
import TextInputCustom from "../../components/input/TextInputCustom";
import NullableBooleanInputCustom from "../../components/input/NullableBooleanInputCustom";
import FormIteratorCustom from "../../components/custom/FormIteratorCustom";

import InfoBulle from "../../components/InfoBulle";

import Actionnaire from "./ConflitsInterets/Actionnaire";
import AssocieAutreEntreprise from "./ConflitsInterets/AssocieAutreEntreprise";
import LienFinancier from "./ConflitsInterets/LienFinancier";
import LienProfessionnel from "./ConflitsInterets/LienProfessionnel";
import ObligationFinanciere from "./ConflitsInterets/ObligationFinanciere";
import RelationNuisible from "./ConflitsInterets/RelationNuisible";
import RelationAffaire from "./ConflitsInterets/RelationAffaire";

import { useGlobalDisabled } from "./helper";
import { useLangue } from "../transalations";
import { usePortalTarget, IsACPR } from "../PortalTarget";

export default memo((props) => {
  const { tabName } = props;

  const disabled = useGlobalDisabled();
  const portalTarget = usePortalTarget().target;



  const { t } = useLangue();

  return (
    <Box maxWidth="72em" key={portalTarget}>
      <Box display="flex" justifyContent="flex-end">
        <InfoBulle position="right" nonHoverable>
          {portalTarget === "acpr" ? (
            <>
              Les questions s’adressent à la personne nommée (renouvelée), ainsi
              qu’à ses proches (conjoint, partenaire, concubin, enfant, parent
              ou tout autre personne qui partage son logement) et à toute
              personne morale dont la personne nommée (renouvelée) est ou a été
              un actionnaire significatif (défini comme un actionnaire détenant
              au moins 10% du capital ou des droits de vote d’une entreprise),
              un associé (associé en nom ou associé commandité), ou un membre de
              l’organe de direction.
            </>
          ) : (
            <>
              IMPORTANT : dans l'ensemble de la section 5, "vous" sous-entend
              "la personne nommée personnellement" mais inclut également ses
              proches (conjoint(e), partenaire enregistré(e), concubin(e),
              enfant, parent ou autre personne avec laquelle elle partage son
              logement) ainsi que toute personne morale pour laquelle la
              personne nommée est ou a été membre de l'organe de direction ou
              actionnaire à un moment donné.
            </>
          )}
        </InfoBulle>
      </Box>
      <ConditionalInput
        disabled={disabled}
        property="estAssocieAutreEntreprise"
        portalTargets="acpr"
        label={`${t(
          "Avez-vous été au cours des dix dernières années ou êtes-vous un actionnaire significatif, un associé en nom ou un associé commandité d’une autre entreprise ?"
        )}`}
      >
        <ArrayInput
          source="associeAutreEntreprises"
          label={t("Veuillez apporter des précisions :")}
        >
          <FormIteratorCustom
            disabled={disabled || portalTarget !== "acpr"}
            component={
              <AssocieAutreEntreprise disabled={disabled || portalTarget !== "acpr"} tabName={tabName} />
            }
          />
        </ArrayInput>
      </ConditionalInput>

      <Typography variant="subtitle1" gutterBottom>
        Politique conflits d'intérêts
        <FileInputCustom
          disabled={disabled}
          multiple={false}
          source={"politiqueConflitsInterets"}
        />
      </Typography>

      <Typography variant="subtitle1">
        {t("▸ Un lien personnel existe-t-il entre vous et :")}
        <ul>
          <li>
            <Box display="flex">
              <span>
                {t(
                  "d’autres membres de l’organe de direction et/ou des titulaires de postes clés au sein de l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ?"
                )}
              </span>
              <InfoBulle position="right">
                "Un lien personnel vise des relations qui ne sont pas d’ordre
                strictement professionnel et inclut des relations d’ordre amical
                ou familial. Suivant le guide de la BCE relatif à l’évaluation
                de la compétence et de l’honorabilité, un lien personnel étroit 
                comprend le conjoint, le partenaire enregistré, le concubin,
                l’enfant, le parent ou tout autre personne proche avec laquelle
                la personne nommée partage son logement."
                <br />
                –
                <br />
                Les opérations avec l’établissement régulé qui relèvent de la
                gestion courante des avoirs et sont conclues à des conditions
                normales du marché, n'ont pas à être déclarées.
              </InfoBulle>
            </Box>
          </li>
          <li style={{opacity: portalTarget === "imas" ? 1 : 0.5}}>
            <Box display="flex">
              <span>
                {t(
                  "des actionnaires qualifiés de l'entité soumise à la surveillance prudentielle, de la société mère ou de leurs filiales ?"
                )}
              </span>
            </Box>
          </li>
          <li style={{opacity: portalTarget === "acpr" ? 1 : 0.5}}>
            <Box display="flex">
              <span>
                {t(
                  "une personne détenant une participation qualifiée au sein de l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ?"
                )}
              </span>
            </Box>
          </li>
          {/* {portalTarget === "imas" && ( */}
          <li style={{opacity: portalTarget === "imas" ? 1 : 0.5}}>
            <Box display="flex">
              {/* ACPR - Pas de traductions */}
              <span>
                des clients, des fournisseurs ou des concurrents de l’entité
                soumise à la surveillance prudentielle, de la société mère ou
                des filiales de l’entité soumise à la surveillance
                prudentielle&nbsp;?
              </span>
            </Box>
          </li>
          {/* )} */}
          
        </ul>
      </Typography>
      <ConditionalInput disabled={disabled} property="lienPersonnelAvecEntite">
        {({ disabled, ...rest }) => (
          <Box>
            <Typography variant="subtitle1">
              {t("Veuillez apporter des précisions :")}
            </Typography>
            <TextInputCustom
              disabled={disabled}
              multiline
              source="lienPersonnelAvecEntiteText"
              id={`${tabName}#lienPersonnelAvecEntiteText`}
              fullWidth
              {...rest}
            />
          </Box>
        )}
      </ConditionalInput>

      <ConditionalInput
        disabled={disabled}
        portalTargets="acpr"
        property="lienAffaireAvecEntite"
        label={t(
          "Êtes-vous en relations d’affaires ou comptez-vous l’être (à titre privé ou professionnel ou par l’intermédiaire d’une société) avec l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ?"
        )}
        infoBulle={`La relation d’affaires escomptée implique que les opérations entre le
          candidat et l’entité soient sérieusement envisagées sans
          nécessairement donner lieu à la signature d’actes contractuels
          engageant les parties.
          <br />
          Les opérations avec l’établissement régulé qui relèvent de la gestion
          courante des avoirs et sont conclues à des conditions normales du
          marché, n'ont pas à être déclarées.`}
      >
        <ArrayInput
          source="relationAffaires"
          id={`${tabName}#relationAffaires`}
        >
          <FormIteratorCustom
            disabled={disabled}
            component={
              <RelationAffaire tabName={tabName} disabled={disabled} />
            }
          />
        </ArrayInput>
      </ConditionalInput>

      <ConditionalInput
        disabled={disabled}
        property="procedureJudiciaireAvecEntite"
        portalTargets="acpr"
        label={t(
          "Êtes-vous actuellement impliqué(e) dans des procédures judiciaires engagées contre l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales, que ce soit directement ou indirectement ?"
        )}
        infoBulle={`Le terme indirectement vise les procédures judiciaires qui peuvent
          être diligentées à l’encontre d’un établissement dans lequel la
          personne concernée exerçait un rôle exécutif ou non exécutif. Le
          superviseur évalue ensuite si la procédure engagée porte atteinte ou
          non à l’honorabilité du candidat.`}
      >
        {({ disabled, ...rest }) => (
          <Box>
            <Typography variant="subtitle1" portalTargets="acpr">
              {t(
                "Veuillez fournir des informations sur la teneur et l’état d’avancement des procédures judiciaires ainsi que sur l’entité concernée :"
              )}
            </Typography>
            <TextInputCustom
              disabled={disabled}
              multiline
              portalTargets="acpr"
              source="procedureJudiciaireAvecEntiteText"
              id={`${tabName}#procedureJudiciaireAvecEntiteText`}
              fullWidth
              {...rest}
            />
          </Box>
        )}
      </ConditionalInput>
      <ConditionalInput
        disabled={disabled}
        property="procedureJudiciaireAvecEntiteIMAS"
        portalTargets="imas"
        label={t(
          "Êtes-vous actuellement impliqué(e), que ce soit directement ou indirectement, dans des procédures judiciaires ou extrajudiciaires engagées contre l’entité soumise à la surveillance prudentielle, la société mère ou leurs filiales ?"
        )}
      >
        {({ disabled, ...rest }) => (
          <Box>
            <TextInputCustom
              disabled={disabled}
              multiline
              portalTargets="imas"
              label={t("Le contenu et le statut actuel de la procédure en question")}
              source="procedureJudiciaireAvecEntiteIMASText"
              id={`${tabName}#procedureJudiciaireAvecEntiteIMASText`}
              fullWidth
              {...rest}
            />
            <TextInputCustom
              disabled={disabled}
              multiline
              portalTargets="imas"
              label={t("L’/les entité(s) concernée(s)")}
              source="procedureJudiciaireAvecEntiteIMASEntite"
              id={`${tabName}#procedureJudiciaireAvecEntiteIMASEntite`}
              fullWidth
              {...rest}
            />
          </Box>
        )}
      </ConditionalInput>
      <Box>
        <Box display="flex">
          <Box>

            {/* {portalTarget === "acpr" && ( */}
              <Typography variant="subtitle1" portalTargets="acpr">
                {t(
                  "▸ Entretenez-vous ou avez-vous entretenu au cours des deux dernières années un lien professionnel (en tant que titulaire d'un poste de cadre et/ou de cadre supérieur par exemple) ou commercial avec :"
                )}
                <ul>
                  <li>
                    {t(
                      "l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ?"
                      )}
                  </li>
                  <li>
                    {t(
                      "des concurrents de l’entité soumise à la surveillance prudentielle, de sa société mère ou de ses filiales ?"
                      )}
                  </li>
                </ul>
                {t(
                  "Ce lien peut être direct ou au travers d’une structure juridique distincte."
                  )}
              </Typography>
              <Typography variant="subtitle1" portalTargets="imas">
                {t(
                  "▸ Entretenez-vous, personnellement ou en tant que membre de l'organe de direction, ou avez-vous entretenu au cours des deux dernières années une relation d'affaires, professionnelle ou commerciale avec :"
                  )}
                <ul>
                  <li>
                    {t(
                      "l'entité soumise à la surveillance prudentielle, la société mère ou leurs filiales ;"
                      )}
                  </li>
                  <li>
                    {t(
                      "des clients, des fournisseurs ou des concurrents de l'entité soumise à la surveillance prudentielle, de la société mère ou des filiales de l'entité soumise à la surveillance prudentielle ?"
                      )}
                  </li>
                </ul>
              </Typography>
            {/* )} */}
          </Box>
        <InfoBulle position="right">
          Lien professionnel&nbsp;: en tant que titulaire d’un contrat de
          travail.
          <br />
          <br />
          Lien commercial&nbsp;: à titre d'exemple, la société a-t-elle parmi
          ses clients ou fournisseurs une société au sein de laquelle vous
          exercez des fonctions de dirigeant effectif, d’actionnaire
          significatif, d’associé en nom ou d’associé commandité&#x202F;?
          <br />
          Dans un autre ordre d’idées, avez-vous l’intention d’ouvrir un (ou
          des) compte(s) dans les livres de l’établissement et, dans
          l’affirmative, envisagez-vous d’effectuer des opérations ne relevant
          pas d’une gestion courante de vos avoirs&#x202F;?
        </InfoBulle>
      </Box>
      </Box>
      {portalTarget === "imas" ? (
        <ConditionalInput disabled={disabled} property="lienProAvecEntite">
          <ArrayInput
            source="lienProfessionnels"
            label="Veuillez fournir des détails complémentaires (en cas de lien commercial, indiquez la valeur (financière) que celui-ci représente pour l’activité de la personne nommée (renouvelée) ou de ses relations personnelles ou professionnelles proches)&nbsp;:"
          >
            <FormIteratorCustom
              disabled={disabled}
              component={
                <LienProfessionnel disabled={disabled} tabName={tabName} />
              }
            />
          </ArrayInput>
        </ConditionalInput>
      ) : (
        <ConditionalInput textInputLabel="Veuillez fournir des détails complémentaires (en cas de lien commercial, indiquez la valeur (financière) que celui-ci représente pour l’activité de la personne nommée (renouvelée) ou de ses relations personnelles ou professionnelles proches) :" diabled={disabled} property="lienProAvecEntite" />
      )}






      <Box display="flex">
        <Typography variant="subtitle1" portalTargets="imas">
          {t(
            "▸ Êtes-vous soumis(e) à une période de carence (au titre d’un accord ou conformément à la législation) ?"
            )}
        </Typography>
      </Box>
      <NullableBooleanInputCustom
        disabled={disabled}
        portalTargets="imas"
        source="periodeCarence"
        id={`${tabName}#periodeCarence`}
      />


      <Box display="flex" style={{opacity: portalTarget === "imas" ? 1 : 0.5}}>
        <Typography variant="subtitle1">
          {t(
            "▸ Avez-vous des obligations financières envers l'entité soumise à la surveillance prudentielle, la société mère ou leurs filiales, dont le montant total est supérieur à 200 000 euros (hors hypothèques privées) ou avez-vous contracté des prêts, quelle qu'en soit la valeur, qui n'ont pas été négociés « aux conditions normales du marché » ou qui sont performants (y compris des hypothèques) ?*"
            )}
        </Typography>

        <InfoBulle position="right">
          Note : Il n’est pas nécessaire de déclarer les prêts hypothécaires privés de quelque valeur que ce soit (s’ils sont performants, s’ils sont négociés aux conditions normales du marché et s’ils ne contreviennent à aucune règle interne d’approbation de crédit) s’ils ne sont pas de nature commerciale ou d’investissement. De plus, tous les prêts personnels (par exemple : cartes de crédit, facilités de découvert et prêts-auto) accordés à la personne nommée par l’entité soumise à la surveillance prudentielle (s’ils sont performants, s’ils sont négociés aux conditions normales du marché et s’ils ne contreviennent à aucune règle interne de crédit) n’ont pas besoin d’être déclarés tant qu’ils sont cumulativement inférieurs au seuil de 200 000 euros. Veuillez noter que ces prêts hypothécaires ou prêts doivent être déclarés s’ils sont, ou sont susceptibles de devenir, non performants pour quelque raison que ce soit.
        </InfoBulle>
      </Box>
      <Box display="flex" style={{opacity: portalTarget === "acpr" ? 1 : 0.5}}>
        <Typography variant="subtitle1">
          {t(
            "▸ Avez-vous actuellement des obligations financières significatives envers l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ?"
            )}
          <br />
          {t(
            "NB : par principe, les prêts consentis aux conditions normales du marché d’un montant inférieur à 200 000 euros et les prêts immobiliers garantis consentis aux conditions normales du marché, ne sont pas considérés comme significatifs."
            )}
        </Typography>
        <InfoBulle position="right">
          Les opérations avec l’établissement régulé qui relèvent de la gestion
          courante des avoirs et sont conclues à des conditions normales du
          marché, n’ont pas à être déclarées.
        </InfoBulle>
      </Box>
      <ConditionalInput disabled={disabled} property="obligationsFinancieres">
        <ArrayInput
          source="obligations"
          id={`${tabName}#obligations`}
          label={t(
            "Veuillez fournir toutes les informations suivantes relatives à ces obligations :"
            )}
            >
          <FormIteratorCustom
            disabled={disabled}
            component={
              <ObligationFinanciere
              portalTarget={portalTarget}
              disabled={disabled}
              tabName={tabName}
              />
            }
            />
        </ArrayInput>
      </ConditionalInput>

      <Box display="flex">
        <Typography  variant="subtitle1" portalTargets="imas">
          {/* <div style={{opacity: portalTarget === "imas" ? 1 : 0.5}}> */}
            {t(
              "▸ Avez-vous une participation financière (en tant que propriétaire ou investisseur, par exemple) dans :"
              )}
            <ul>
              <li>
                {t(
                  "l’entité soumise à la surveillance prudentielle, la société mère ou leurs filiales ?"
                  )}
              </li>
              <li>
                {t(
                  "des clients, des fournisseurs ou des concurrents de l’entité soumise à la surveillance prudentielle, de la société mère ou des filiales de l’entité soumise à la surveillance prudentielle ?"
                  )}
              </li>
            </ul>
          {/* </div> */}
        </Typography>

        <InfoBulle position="right">
          {t(
            "Il n’est pas nécessaire de déclarer les participations actuelles inférieures à 1 % ou tout autre investissement d’une valeur équivalente."
          )}
        </InfoBulle>
      </Box>

      <Typography variant="subtitle1" portalTargets="acpr">
        ▸ Avez-vous, aussi bien personnellement que par l’intermédiaire d’une entreprise avec laquelle vous êtes étroitement lié(e), un intérêt financier significatif (en tant que propriétaire ou investisseur par exemple) dans l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales ou dans des concurrents ou clients de l’entité soumise à la surveillance prudentielle, de sa société mère ou de ses filiales ?
      </Typography>
      <ConditionalInput
        disabled={disabled}
        property="interetFinancierDansEntite"
        infoBulle={`N'ont pas à être déclarés : l’ensemble des prêts personnels garantis
          (tels que les hypothèques privées) accordés à un taux non préférentiel
          (c’est-à-dire aux conditions normales de marché) ; tous les autres
          prêts à taux non préférentiel de moins de 200 000 euros, garantis ou
          non ; les participations actuelles ≤ 1 % ou les autres investissements
          d’une valeur équivalente.`}
      >
        <ArrayInput
          source="lienFinanciers"
          id={`${tabName}#lienFinanciers`}
          label={t(
            "Veuillez fournir toutes les informations suivantes relatives à ce lien financier :"
          )}
        >
          <FormIteratorCustom
            disabled={disabled}
            component={<LienFinancier disabled={disabled} tabName={tabName} />}
          />
        </ArrayInput>
      </ConditionalInput>

      <ConditionalInput
        disabled={disabled}
        property="representeActionnaire"
        label={`${t(
          "Représentez-vous, de quelque façon que ce soit, un actionnaire de l’entité soumise à la surveillance prudentielle, de sa société mère ou de ses filiales ?"
        )}`}
        infoBulle={`Le candidat doit indiquer s’il représente un actionnaire de l’entité,
          de sa société mère ou de ses filiales.`}
        onNo={Actionnaire.clear}
      >
        <Actionnaire tabName={tabName} />
      </ConditionalInput>

      <Typography variant="subtitle1" portalTargets="acpr">
        {t(
          "▸ Occupez-vous ou avez-vous occupé au cours des deux dernières années un poste à forte influence politique (au plan national ou local) ?"
        )}
      </Typography>
      <Typography variant="subtitle1" portalTargets="imas">
        {t(
          "▸ Occupez-vous ou avez-vous occupé personnellement un poste à forte influence politique (sur le plan international, national ou local) au cours des deux dernières années ?"
        )}
      </Typography>
      <NullableBooleanInputCustom
        disabled={disabled}
        source="posteForteInfluence"
        id={`${tabName}#posteForteInfluence`}
      />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => {
          if (formData.posteForteInfluence) {
            return (
              <Box>
                <Typography variant="subtitle1">
                  {t("Veuillez fournir les informations suivantes :")}
                </Typography>
                <TextInputCustom
                  disabled={disabled}
                  multiline
                  label={t("Nature du poste")}
                  source="posteForteInfluenceNature"
                  id={`${tabName}#posteForteInfluenceNature`}
                  {...rest}
                  fullWidth
                />
                <TextInputCustom
                  disabled={disabled}
                  multiline
                  label={IsACPR() ? t("Les pouvoirs spécifiques ou obligations liés à ce poste") : t(
                    "Rôle et responsabilités propres au poste   "
                  )}
                  source="posteForteInfluencePouvoirs"
                  id={`${tabName}#posteForteInfluencePouvoirs`}
                  {...rest}
                  fullWidth
                />

                <Typography variant="subtitle1" portalTarget="acpr">
                  {t(
                    "Lien entre ce poste (ou l’entité où ce poste est occupé) et l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales"
                  )}
                </Typography>
                <Typography variant="subtitle1"  portalTargets="imas">
                  {t("Lien entre ce poste (ou l’entité au sein de laquelle ce poste est ou a été occupé) et l’entité soumise à la surveillance prudentielle, la société mère ou leurs filiales")}
                </Typography>
                <TextInputCustom
                  disabled={disabled}
                  multiline
                  source="posteForteInfluenceLien"
                  id={`${tabName}#posteForteInfluenceLien`}
                  {...rest}
                  fullWidth
                />
              </Box>
            );
          } else {
            formData.posteForteInfluenceNature = null;
            formData.posteForteInfluencePouvoirs = null;
            formData.posteForteInfluenceLien = null;
          }
        }}
      </FormDataConsumer>

      <Box style={{ opacity: portalTarget === "imas" ? 1 : 0.5 }}>
        <ConditionalInput
          disabled={disabled || portalTarget !== 'imas'}
          property="relationNuisible"
          label={t(
            "Avez-vous des relations et participations ou occupez-vous des postes qui n’ont pas été couvert(e)s dans les questions ci-dessus et qui pourraient nuire aux intérêts de l’entité soumise à la surveillance prudentielle ?"
            )}
            onNo={RelationNuisible.clear}
            >
          <RelationNuisible tabName={tabName} />
        </ConditionalInput>
      </Box>

      <Typography variant="subtitle1" portalTargets="acpr">
        {t(
          "▸ Êtes-vous lié par certains engagements pris au titre de fonctions précédemment exercées en France ou à l’étranger (clause de non concurrence, par exemple) ?"
        )}
      </Typography>
      <NullableBooleanInputCustom
        disabled={disabled}
        portalTargets="acpr"
        source="engagementsPrecedentesFonctions"
      />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => {
          if (formData.engagementsPrecedentesFonctions) {
            return (
              <Box>
                <Typography variant="subtitle1" portalTargets="acpr">
                  {t("Veuillez fournir des détails complémentaires :")}
                </Typography>
                <TextInputCustom
                  disabled={disabled}
                  multiline
                  portalTargets="acpr"
                  source="engagementsPrecedentesFonctionsText"
                  id={`${tabName}#engagementsPrecedentesFonctionsText`}
                  {...rest}
                  fullWidth
                />
              </Box>
            );
          } else {
            formData.engagementsPrecedentesFonctionsText = null;
          }
        }}
      </FormDataConsumer>

      <Typography variant="subtitle1" portalTargets="acpr">
        {t(
          "▸ Avez-vous d’autres liens, engagements ou occupez-vous d’autres postes que ceux couverts dans les questions ci-dessus et qui pourraient nuire aux intérêts de l’entité soumise à la surveillance prudentielle ?"
        )}
      </Typography>
      <NullableBooleanInputCustom
        disabled={disabled}
        portalTargets="acpr"
        source="engagementsNuisiblesEntite"
      />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => {
          if (formData.engagementsNuisiblesEntite) {
            return (
              <Box>
                <Typography variant="subtitle1" portalTargets="acpr">
                  {t(
                    "Veuillez fournir toutes les informations nécessaires (notamment relatives à la nature, la teneur, la période et, le cas échéant, au lien avec l’entité soumise à la surveillance prudentielle, sa société mère ou ses filiales) :"
                  )}
                </Typography>
                <TextInputCustom
                  disabled={disabled}
                  multiline
                  portalTargets="acpr"
                  source="engagementsNuisiblesEntiteText"
                  id={`${tabName}#engagementsNuisiblesEntiteText`}
                  {...rest}
                  fullWidth
                />
              </Box>
            );
          } else {
            formData.engagementsNuisiblesEntiteText = null;
          }
        }}
      </FormDataConsumer>

      <Box>
        <Typography variant="subtitle1" portalTargets="acpr">
          <span style={{ fontStyle: "italic" }}>
            À compléter par l’entité soumise à la surveillance prudentielle :
          </span>
        </Typography>
      </Box>
      <Box display="flex">
        <Typography variant="subtitle1" portalTargets="acpr">
          {t(
            "▸ Si besoin, indiquez comment prévenir et/ou encadrer un éventuel conflit d’intérêts tel qu’il ressort des déclarations ci-dessus de la personne nommée (renouvelée), que celui-ci soit considéré comme significatif ou non. À cet égard, veuillez joindre tout document utile (statuts, règlement intérieur, procédures, etc.)."
          )}
        </Typography>
        <InfoBulle position="right">
          Exemple de documents attendus&nbsp;:
          <br />
          Les Statuts (partie conventions réglementées notamment),
          <br />
          la Charte des conflits d’intérêt (ou équivalent) signée par le
          mandataire,
          <br />
          la Politique de prévention et de gestion des conflits d’intérêt des
          dirigeants effectifs et administrateur (ou équivalent),
          <br />
          le Règlement intérieur…
        </InfoBulle>
      </Box>
      <TextInputCustom
        portalTargets="acpr"
        disabled={disabled}
        multiline
        source="solutionConflitInteret"
        id={`${tabName}#solutionConflitInteret`}
        fullWidth
      />
      <Box display="flex">
        <Box>
          <Box>
            <Typography variant="subtitle1">
              <span style={{ fontStyle: "italic" }}>
                À compléter par l’entité soumise à la surveillance prudentielle :
              </span>
            </Typography>
          </Box>
          <Box display="">
            <Typography variant="subtitle1" portalTargets="acpr">
              {t(
                "▸ si vous avez répondu « Oui » à une ou plusieurs des questions ci-dessus, veuillez déterminer si l'éventuel conflit d'intérêts est significatif (si ce n'est pas le cas veuillez justifier votre réponse) et indiquez comment vous entendez l'atténuer ou le gérer."
                )}
            </Typography>
            <Typography variant="subtitle1" portalTargets="imas">
              {t(
                "▸ si vous avez répondu « Oui » à une ou plusieurs des questions ci-dessus, veuillez déterminer si l’éventuel conflit d’intérêts est significatif (si ce n’est pas le cas, veuillez expliquer les raisons sous-tendant votre réponse) et indiquer quelles mesures vous entendez prendre pour l’atténuer ou le gérer."
                )}
            </Typography>
          </Box>
        </Box>
        <InfoBulle position="right" disabled={portalTarget !== "imas"}>
          {t(
            "Pour préciser l’importance de l’éventuel conflit d’intérêts, veuillez consulter la section 3.3 du Guide relatif à l’évaluation de l’honorabilité, des connaissances, des compétences et de l’expérience et fournir la documentation correspondante (le cas échéant, statuts, règlement intérieur, politique en matière de conflits d’intérêts, etc.)"
            )}
        </InfoBulle>
      </Box>
      <TextInputCustom
        disabled={disabled}
        multiline
        source="solutionConflitInteretACPR"
        id={`${tabName}#solutionConflitInteretACPR`}
        fullWidth
      />
      {/* <Typography variant="subtitle1" portalTargets="imas">
        {t(
          "À compléter par l’entité soumise à la surveillance prudentielle : si vous avez répondu « Oui » à une ou plusieurs des questions ci-dessus, veuillez déterminer si l’éventuel conflit d’intérêts est significatif (si ce n’est pas le cas, veuillez expliquer les raisons sous-tendant votre réponse) et indiquer quelles mesures vous entendez prendre pour l’atténuer ou le gérer"
        )}
      </Typography> */}
    </Box>
  );
});
