import React, { useMemo, memo } from "react";
import { TextInput, TextField } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";

import { noSpecialCharacters } from "../../lib/validation";
import { useOnlyForTargets, usePortalTarget } from "../../candidatures/PortalTarget";

const useStyles = makeStyles({
  root: (props) => {
    return {
      minWidth: props.width || "18em",
    };
  },
});

function listWrap(o) {
  if (!o) return [];

  if (!Array.isArray(o)) return [o];

  return o;
}

function useValidate(props) {
  let validate = useMemo(
    () =>
      [...listWrap(props.validate), noSpecialCharacters()].filter((f) => !!f),
    [props.validate]
  );

  return validate;
}

const useDivStyles = makeStyles({
  container: {
    position: "relative",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    paddingRight: 12,
    paddingLeft: 12,
    paddingTop: 23,
    paddingBottom: 6,
    marginTop: 8,
    marginBottom: 4,
    minWidth: "18em",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
  },
  label: {
    color: "rgba(0, 0, 0, 0.57)",
    transform: "translate(12px, 7px) scale(0.75)",
    transformOrigin: "top left",
    position: "absolute",
    top: 0,
    left: 0,
  },
  field: {
    minHeight: "1.26em",
  },
});

const Div = (props) => {
  const classes = useDivStyles();
  return (
    <div className={classes.container}>
      <div className={classes.label}>{props.label}</div>
      <div className={classes.field}>
        <TextField source={props.source} />
      </div>
    </div>
  );
};

const TextInputCustom = React.forwardRef(
  ({ portalTargets, tabName, ...props }, ref) => {
    const classes = useStyles(props);
    let validate = useValidate(props);

    const disabled = useOnlyForTargets(portalTargets, !!props.disabled);
    const portal = usePortalTarget().target;
  
    if (portalTargets && portalTargets !== portal) {
      return <></>
    }
    

    validate = disabled ? void 0 : validate;

    const media = window.matchMedia("print").matches;

    const Component = media ? Div : TextInput;

    return (
      <Component
        ref={ref}
        {...props}
        disabled={disabled}
        validate={validate}
        label={props.label || " "}
        // minRows={1}
        // This minHeight is required to avoid the inputs to collapse to a 0px
        // height. 1em is a line height, 0.26em is (likely to be) the space
        // in between lines
        inputProps={{ style: { minHeight: "1.26em" } }}
        className={`${classes.root} ${classes.textCustom} ${props.className}`}
      />
    );
  }
);

export default memo(TextInputCustom);
