/**
 * Langue Context to be used by candidatures
 */
import React, { useMemo, useContext } from "react";

import { Langue } from "../../lib/langue";

import En from "./EN";
import Fr from "./FR";

const LABELS = {
  [Langue.Fr]: Fr,
  [Langue.En]: En,
};

const DEFAULT_LANGUE = Langue.Fr;

function createT(langue = DEFAULT_LANGUE) {
  return (sentence) => {
    if (
      LABELS[langue] &&
      typeof LABELS[langue] === "object" &&
      Object.hasOwnProperty.call(LABELS[langue], sentence)
    ) {
      return LABELS[langue][sentence];
    }

    return sentence;
  };
}

const Context = React.createContext({
  langue: DEFAULT_LANGUE,
  t: createT(DEFAULT_LANGUE),
});

/**
 * TranslateFunction looks up a translation for the input string
 * according to the language currently set in the context.
 * @typedef {(input: string) => string} TranslateFunction
 */

/**
 * @typedef {Object} LangueContext
 * @property {string} langue     The current language the `t` function translates into
 * @property {TranslateFunction} t translates the string passed as input
 */

/**
 * The object returned by `useLangue()` as two properties :
 * - `langue`: the targuet language, and
 * - `t`: a function that looks up a translation for the input string
 * @returns {{ langue: string, t: TranslateFunction }}
 */
export function useLangue() {
  return useContext(Context);
}

export function LangueProvider({ langue, children }) {
  langue = langue ?? DEFAULT_LANGUE;
  const t = useMemo(
    function () {
      return createT(langue);
    },
    [langue]
  );

  const state = useMemo(() => ({ langue, t }), [langue, t]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
}
