import {
  Button,
  BulkDeleteButton,
  useNotify,
  useUnselectAll,
  useQuery,
} from "react-admin";

import React, { useState, useEffect, useCallback } from "react";

import Send from "@material-ui/icons/Send";
import GetApp from "@material-ui/icons/GetApp";
import Modal from "@material-ui/core/Modal";
import Typography from "../components/custom/TypographyCustom";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";

import { makeStyles } from "@material-ui/core/styles";

import { httpClient, authedFetch } from "../authProvider";

const { REACT_APP_API_URL } = process.env;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
  },
  list: {
    padding: "0",
    margin: "1em 0",
    listStyle: "none",
    borderTop: "1px solid #eee",
  },
  listItem: {
    padding: "0.5em 1em",
    borderBottom: "1px solid #eee",
  },
  xPadded: {
    paddingLeft: "1em",
    paddingRight: "1em",
  },
}));

function MagicLinkButtonModal({ open, candidates, onCancel, onConfirm }) {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onCancel} className={classes.modal}>
      <Box width="34em" outline="none">
        <Card width="34em" outline="none">
          <Box p="1em">
            <Typography variant="h6" gutterBottom>
              Envoyer un lien vers le formulaire
            </Typography>
            <Typography variant="body1">
              Vous allez envoyer des liens de connexion au formulaire Fit &
              Proper aux personnes suivantes&nbsp;:
            </Typography>
          </Box>
          <ul className={classes.list}>
            {candidates &&
              candidates.map(({ nom, prenom }) => {
                return (
                  <li className={classes.listItem}>
                    {nom} {prenom}
                  </li>
                );
              })}
          </ul>
          <Box display="flex" justifyContent="space-between" p="1em" pt="1em">
            <Button
              label="Continuer"
              color="primary"
              variant="contained"
              onClick={onConfirm}
            />
            <Button label="Annuler" onClick={onCancel} />
          </Box>
        </Card>
      </Box>
    </Modal>
  );
}

const MagicLinkButton = (props) => {
  const { selectedIds } = props;

  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [candidates, setCandidates] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const candidatures = useQuery({
    type: "getMany",
    resource: "candidatures",
    payload: { ids: selectedIds },
  });

  useEffect(() => {
    const c = (candidatures.data || [])
      .filter((c) => !!c.candidat)
      .map((c) => c.candidat);

    setCandidates(c);
  }, [candidatures.data]);

  const onClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(true);
  }, []);

  const onCancel = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(false);
  }, []);

  const onConfirm = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        await Promise.all(
          selectedIds.map(async (id) => {
            let response = await httpClient(
              `${REACT_APP_API_URL}/magic-link/${id}`,
              {
                method: "POST",
              }
            );

            if (response) {
              const { link } = response.json;
              await httpClient(`${REACT_APP_API_URL}/candidatures/send-link`, {
                method: "POST",
                body: JSON.stringify({ candidatureId: id, link }),
              });
            }
          })
        );
        if (selectedIds.length === 1) {
          notify("Le ou la candidate recevra le lien prochainement");
        } else {
          notify("Les candidat·e·s recevront le lien prochainement");
        }
        unselectAll("candidatures");
      } catch (err) {
        notify(err.message, "warning");
      } finally {
        setShowModal(false);
      }
    },
    [notify, selectedIds, unselectAll]
  );

  return (
    <Button
      label="Envoyer un lien vers le formulaire"
      disabled={false}
      onClick={onClick}
    >
      <>
        <Send />
        <MagicLinkButtonModal
          open={showModal}
          candidates={candidates}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </>
    </Button>
  );
};

async function getACPR(candidatureIds, notify) {
  try {
    const response = await authedFetch(
      `${REACT_APP_API_URL}/candidatures/acpr`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ candidatureIds }),
      }
    );

    if (response.status !== 200) {
      throw new Error(
        `La génération des fichiers ACPR a échoué : ${response.status}`
      );
    }
    const blob = await response.blob();

    window.location.href = window.URL.createObjectURL(blob);
  } catch (err) {
    notify("Error: " + err, "warning");
  }
}

const ExportACPR = ({ selectedIds }) => {
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  return (
    <Button
      label="Exporter au format ACPR"
      disabled={false}
      onClick={() => {
        getACPR(selectedIds, notify);
        unselectAll("candidatures");
      }}
    >
      <GetApp />
    </Button>
  );
};

const BulkActionButtons = (props) => (
  <>
    <BulkDeleteButton undoable={false} {...props} />
    <MagicLinkButton {...props} />
    <ExportACPR {...props} />
  </>
);

export default BulkActionButtons;
