import React from "react";

import { Typography, Box } from "@material-ui/core";

import TranslatableTextInputCustom from "../../../components/input/TranslatableTextInputCustom";
import DateInputCustom from "../../../components/input/DateInputCustom";
import NullableBooleanInputCustom from "../../../components/input/NullableBooleanInputCustom";
import TypographyCustom from "../../../components/custom/TypographyCustom";

function Subtitle(props) {
  return (
    <Box maxWidth="72em">
      <Typography {...props} />
    </Box>
  );
}

export default function Procedure({ disabled, tabName, ...rest }) {
  return (
    <Box ml="2em" mr="2em">
      <Subtitle variant="subtitle1">Motif de la mesure disciplinaire</Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infractionsIMAS.mesuresDisciplinairesText"
        id={`${tabName}#infractionsIMAS.mesuresDisciplinairesText`}
        fullWidth
        {...rest}
      />

      <Box display="flex">
        <DateInputCustom
          label="Date ou période de déroulement des actes répréhensibles présumés"
          disabled={disabled}
          source="infractionsIMAS.procedureEnCoursDate"
          id={`${tabName}#infractionsIMAS.procedureEnCoursDate`}
        />
        <Box ml="0.5em">
          <NullableBooleanInputCustom
            label="Avez-vous déposé un recours contre la mesure disciplinaire ?"
            disabled={disabled}
            source="infractionsIMAS.procedureEnCoursAppeal"
            id={`${tabName}#infractionsIMAS.procedureEnCoursAppeal`}
          />
        </Box>
      </Box>

      <Subtitle variant="subtitle1">
        If applicable, provide details of the entity involved in the measures or
        actions
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        source="infractionsIMAS.procedureEnCoursDetailsEntite"
        id={`${tabName}#infractionsIMAS.procedureEnCoursDetailsEntite`}
      />

      <Subtitle variant="subtitle1">
        Any mitigating or aggravating factors
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        source="infractionsIMAS.procedureEnCoursCirconstancesAggravantes"
        id={`${tabName}#infractionsIMAS.procedureEnCoursCirconstancesAggravantes`}
      />
    </Box>
  );
}

Procedure.clear = function (formData) {
  if (formData.infractionsIMAS) {
    // formData.infractionsIMAS.procedureEnCoursIMAS = null;
    // formData.infractionsIMAS.procedureEnCoursTextIMAS = null;
    formData.infractionsIMAS.procedureEnCoursDate = null;
    formData.infractionsIMAS.procedureEnCoursAppeal = null;
    formData.infractionsIMAS.procedureEnCoursDetailsEntite = null;
    formData.infractionsIMAS.procedureEnCoursCirconstancesAggravante = null;
    if (formData.infractionsIMAS.traductions) {
      formData.infractionsIMAS.traductions.forEach((_traduction, idx) => {
        // formData.infractionsIMAS.traductions[idx].procedureEnCoursText = null;
        // formData.infractionsIMAS.traductions[idx].procedureEnCoursTextIMAS = null;
        formData.infractionsIMAS.traductions[
          idx
        ].procedureEnCoursDetailsEntite = null;
        formData.infractionsIMAS.traductions[
          idx
        ].procedureEnCoursCirconstancesAggravante = null;
      });
    }
  }
};
