import React, {
  Children,
  useCallback,
  isValidElement,
  useState,
  useMemo,
} from "react";
import classnames from "classnames";
import {
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

import { makeStyles } from "@material-ui/core/styles";
import { escapePath, FormWithRedirect } from "ra-core";
import get from "lodash/get";
import {
  Toolbar,
  Button,
  SaveButton,
  DeleteWithConfirmButton,
  FormDataConsumer,
  useNotify,
} from "react-admin";

import TabbedFormTabsCustom, { getTabFullPath } from "./TabbedFormTabsCustom";

const TabbedFormCustom = (props) => (
  <FormWithRedirect
    {...props}
    render={(formProps) => (
      <TabbedFormView
        {...formProps}
        recordId={props.recordId}
        isPrint={props.isPrint}
      />
    )}
  />
);

export const TabContext = React.createContext({ loading: false });

const ToolbarCustom = (props) => {
  const {
    tabInfo,
    basePath,
    resource,
    record,
    redirect,
    submitOnEnter,
    undoable,
    saving,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
  } = props;

  const history = useHistory();
  const notify = useNotify();
  const location = useLocation();

  const goToNextTab = useCallback(() => {
    history.push(
      `${basePath}/${tabInfo.id}/${tabInfo.tabIndex + 1}${location.search}`
    );
  }, [basePath, history, tabInfo.id, tabInfo.tabIndex, location.search]);

  const goToPreviousTab = useCallback(() => {
    const t = tabInfo.tabIndex - 1;

    if (t) {
      history.push(
        `${basePath}/${tabInfo.id}/${tabInfo.tabIndex - 1}${location.search}`
      );
    } else {
      history.push(`${basePath}/${tabInfo.id}${location.search}`);
    }
  }, [basePath, history, tabInfo.id, tabInfo.tabIndex, location.search]);

  const customHandleSubmitWithRedirct = useCallback(
    async (...args) => {
      const result = await handleSubmitWithRedirect(...args);

      if (invalid)
        notify("Formulaire invalide : vérifiez votre saisie", "error");

      return result;
    },
    [handleSubmitWithRedirect, invalid, notify]
  );

  const customHandleSubmit = useCallback(
    async (...args) => {
      const result = await handleSubmit(...args);

      if (invalid)
        notify("Formulaire invalide : vérifiez votre saisie", "error");

      return result;
    },
    [handleSubmit, invalid, notify]
  );

  return (
    <Toolbar>
      <Box display="flex" width="100%" justifyContent="space-between">
        <SaveButton
          label={"Enregistrer"}
          // disabled={invalid}
          basePath={basePath}
          resource={resource}
          record={record}
          redirect={redirect}
          submitOnEnter={submitOnEnter}
          saving={saving}
          handleSubmit={customHandleSubmit}
          handleSubmitWithRedirect={customHandleSubmitWithRedirct}
        />
        <Box display="flex" alignItems="center">
          <Box mr="1em">
            <Button
              label="Précédent"
              alignIcon="left"
              onClick={goToPreviousTab}
              disabled={tabInfo?.tabIndex === 0}
            >
              <ChevronLeft />
            </Button>
          </Box>

          <Button
            label="Suivant"
            alignIcon="right"
            onClick={goToNextTab}
            disabled={tabInfo?.tabIndex === tabInfo.maxTab}
          >
            <ChevronRight />
          </Button>
        </Box>

        <Box minWidth="8em">
          {!tabInfo.create && (
            <DeleteWithConfirmButton
              label="Supprimer"
              basePath={basePath}
              resource={resource}
              record={record}
              redirect="list"
              variant="text"
              undoable={undoable}
            />
          )}
        </Box>
      </Box>
    </Toolbar>
  );
};

const useStyles = makeStyles(
  (theme) => ({
    errorTabButton: { color: `${theme.palette.error.main} !important` },
    content: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }),
  { name: "RaTabbedForm" }
);

function getTabInfo(basePath, pathname, tabs) {
  const regex = new RegExp(`^${basePath}/(\\w+)(?:/(\\w+))?`);

  const match = regex.exec(pathname);
  let id, tabMatch;
  if (match !== null) {
    id = match[1];
    tabMatch = match[2];
  }

  const result = {
    create: id === "create",
    id: id,
    tabIndex: tabMatch ? parseInt(tabMatch) : 0,
    maxTab: tabs.length - 1,
  };

  return result;
}

export const TabbedFormView = (props) => {
  const {
    basePath,
    children,
    className,
    classes: _classesOverride,
    form,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    isPrint,
    pristine,
    record,
    recordId,
    redirect: defaultRedirect,
    resource,
    saving,
    setRedirect: _setRedirect,
    submitOnEnter,
    tabs,
    toolbar,
    translate: _translate,
    undoable,
    value: _value,
    variant,
    margin,
    onFormChange,
    ...rest
  } = props;

  const tabsWithErrors = findTabsWithErrors(children, form.getState().errors);
  const classes = useStyles(props);
  const match = useRouteMatch();
  const location = useLocation();

  const url = match ? match.url : location.pathname;

  const tabInfo = getTabInfo(basePath, location.pathname, children);
  if (recordId) tabInfo.id = recordId;

  const [loading, setLoading] = useState(false);

  const ctx = useMemo(() => {
    return {
      loading,
      setLoading,
    };
  }, [loading]);

  return (
    <TabContext.Provider value={ctx}>
      <form
        className={classnames("tabbed-form", className)}
        {...sanitizeRestProps(rest)}
      >
        {onFormChange && (
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData }) => {
              onFormChange(formData);
              return null;
            }}
          </FormDataConsumer>
        )}
        {React.cloneElement(
          tabs,
          {
            classes,
            url,
            tabsWithErrors,
          },
          children
        )}
        <Divider />
        <div className={classes.content} style={{ position: "relative" }}>
          {/* All tabs are rendered (not only the one in focus), to allow validation
                on tabs not in focus. The tabs receive a `hidden` property, which they'll
                use to hide the tab using CSS if it's not the one in focus.
                See https://github.com/marmelab/react-admin/issues/1866 */}
          {Children.map(
            children,
            (tab, index) =>
              tab && (
                <Route exact path={escapePath(getTabFullPath(tab, index, url))}>
                  {(routeProps) =>
                    isValidElement(tab)
                      ? React.cloneElement(tab, {
                          intent: "content",
                          resource,
                          record,
                          basePath,
                          hidden: !routeProps.match,
                          variant: tab.props.variant || variant,
                          margin: tab.props.margin || margin,
                        })
                      : null
                  }
                </Route>
              )
          )}
        </div>
        {toolbar &&
          !isPrint &&
          React.cloneElement(toolbar, {
            basePath,
            className: "toolbar",
            handleSubmitWithRedirect,
            handleSubmit,
            invalid,
            pristine,
            record,
            redirect: defaultRedirect,
            resource,
            saving,
            submitOnEnter,
            undoable,
            tabInfo,
          })}
      </form>
    </TabContext.Provider>
  );
};

TabbedFormView.defaultProps = {
  submitOnEnter: true,
  tabs: <TabbedFormTabsCustom />,
  toolbar: <ToolbarCustom />,
};

const sanitizeRestProps = ({
  anyTouched,
  array,
  asyncBlurFields,
  asyncValidate,
  asyncValidating,
  autofill,
  blur,
  change,
  clearAsyncError,
  clearFields,
  clearSubmit,
  clearSubmitErrors,
  destroy,
  dirty,
  dirtyFields,
  dirtyFieldsSinceLastSubmit,
  dirtySinceLastSubmit,
  dispatch,
  form,
  handleSubmit,
  hasSubmitErrors,
  hasValidationErrors,
  initialize,
  initialized,
  initialValues,
  modifiedSinceLastSubmit,
  modifiedsincelastsubmit,
  pristine,
  pure,
  redirect,
  reset,
  resetSection,
  save,
  staticContext,
  submit,
  submitAsSideEffect,
  submitError,
  submitErrors,
  submitFailed,
  submitSucceeded,
  submitting,
  touch,
  translate,
  triggerSubmit,
  undoable,
  untouch,
  valid,
  validate,
  validating,
  __versions,
  ...props
}) => props;

function findAllInputs(children, acc = []) {
  Children.toArray(children).forEach((child) => {
    let tabName = child?.props?.tabName;

    if (tabName) {
      let inputs = document.querySelectorAll("[id^=" + tabName + "]");

      for (let input of inputs) {
        acc.push(input);
      }

      inputs = document.querySelectorAll("[id^=" + tabName + "] + input");

      for (let input of inputs) {
        acc.push(input);
      }
    } else if (
      child &&
      child.props &&
      Object.hasOwnProperty(child.props, "children")
    ) {
      acc = findAllInputs(child.props.children, acc);
    }
  });

  return acc;
}

export const findTabsWithErrors = (children, errors) => {
  return Children.toArray(children).reduce((acc, child) => {
    if (!isValidElement(child)) {
      return acc;
    }

    const inputs = [];
    findAllInputs(child.props.children, inputs);

    if (
      inputs.some((input) => {
        const inputName = input.getAttribute("name");
        const v = get(errors, inputName);

        if (!!v) {
        }

        return !!get(errors, inputName);
      })
    ) {
      return [...acc, child.props.label];
    }

    return acc;
  }, []);
};

export default TabbedFormCustom;
