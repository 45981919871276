import React from "react";
import Box from "@material-ui/core/Box";

import Typography from "../../../components/custom/TypographyCustom";
import DateInputCustom from "../../../components/input/DateInputCustom";
import TextInputCustom from "../../../components/input/TextInputCustom";
import NumberInputCustom from "../../../components/input/NumberInputCustom";

import { useLangue } from "../../transalations";

export default function RelationAffaire({ tabName, name, disabled }) {
  const { t } = useLangue();

  return (
    <Box>
      <TextInputCustom
        label={t("Nom du débiteur")}
        source={`${name}.nomDebiteur`}
        id={`${tabName}#${name}#nomDebiteur`}
        disabled={disabled}
        portalTargets="acpr"
        helperText="s’il ne s’agit pas de la personne nommée / personne à nommer, précisez la relation entretenue avec celle-ci"
        fullWidth
      />
      <TextInputCustom
        label={t("Nom de l’entité")}
        source={`${name}.nomEntite`}
        id={`${tabName}#${name}#nomEntite`}
        disabled={disabled}
        fullWidth
      />
      <TextInputCustom
        label={t("Nature de l’obligation")}
        source={`${name}.type`}
        id={`${tabName}#${name}#type`}
        disabled={disabled}
        fullWidth
      />
      <TextInputCustom
        label={t("Montant de l’obligation")}
        source={`${name}.amount`}
        id={`${tabName}#${name}#amount`}
        disabled={disabled}
        fullWidth
      />
      <Typography variant="subtitle1" portalTargets="acpr">
        {t("Statut de l'obligation")}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        source={`${name}.statut`}
        portalTargets="acpr"
        id={`${tabName}#${name}#statut`}
        fullWidth
      />
      <Typography variant="subtitle1" portalTargets="acpr">
        {t("Conditions d’obtention")}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        multiline
        source={`${name}.conditions`}
        portalTargets="acpr"
        id={`${tabName}#${name}#conditions`}
        fullWidth
      />
      <DateInputCustom
        disabled={disabled}
        label={t("Échéance de l’obligation")}
        id={`${tabName}#${name}#echeance`}
        name={`${name}.echeance`}
      />
      <Typography variant="subtitle1">{t("Valeur de l’obligation (en pourcentage du total des fonds propres éligibles de l'entité)")}</Typography>
      <NumberInputCustom
        disabled={disabled}
        name={`${name}.value`}
        id={`${tabName}#${name}#value`}
      />
      <Typography variant="subtitle1">{t("Commentaires")}</Typography>
      <TextInputCustom
        disabled={disabled}
        multiline
        source={`${name}.commentaire`}
        portalTargets="acpr"
        id={`${tabName}#${name}#commentaire`}
        fullWidth
      />
    </Box>
  );
}
