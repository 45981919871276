import React, { useCallback, useState, useEffect } from "react";
import { useNotify } from "react-admin";
import { authedFetch, getPermissionsSync } from "../../authProvider";
import { reportError } from "../../lib/error-report";

import Box from "@material-ui/core/Box";
import WarningIcon from "@material-ui/icons/Warning";
import Amber from "@material-ui/core/colors/amber";
import EyeIcon from "@material-ui/icons/Visibility";
// import { useDebounce } from "react-use";

const { REACT_APP_API_URL } = process.env;

function Status(props) {
  const notify = useNotify();

  const { id } = props;
  const [status, setStatus] = useState(props.status);
  const [role, setRole] = useState("user");

  useEffect(() => {
    const r = getPermissionsSync();
    setRole(r);
  }, []);

  const setNextStatus = useCallback(
    async (nextStatus) => {
      if (nextStatus) {
        try {
          const result = await authedFetch(
            `${REACT_APP_API_URL}/candidatures/${id}/status`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ statut: nextStatus }),
            }
          );

          if (result.status === 200) {
            setStatus(nextStatus);
          } else {
            throw new Error(
              `Candidature Status update Failed (${result.status})`
            );
          }
        } catch (err) {
          reportError(err);
          notify("La mise à jour du statut a échoué", "error");
        }
      }
    },
    [id, notify],
    [id, notify]
  );

  const submitToBPCE = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setNextStatus("ValidationBpce");
    },
    [setNextStatus]
  );

  const validateSupervisor = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setNextStatus("ValidePourSuperviseur");
    },
    [setNextStatus]
  );

  const submitToEntite = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setNextStatus("ValidationEntite");
    },
    [setNextStatus]
  );

  switch (status) {
    default:
    case "Brouillon":
      return <span>Brouillon</span>;

    case "Abandonne":
      return <span>Abandonné</span>;

    case "SoumisCandidat":
      return <span>Soumis au candidat</span>;

    case "ValidationEntite":
      return <button onClick={submitToBPCE}>Soumettre à BPCE</button>;

    case "ValidationBpce":
      if (role === "superadmin") {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              onClick={submitToEntite}
              title="Envoyer à l’entité pour correction"
            >
              Envoyer pour correction
            </button>
            <button
              onClick={validateSupervisor}
              title="Valider pour transmission au superviseur"
            >
              À transmettre au superviseur
            </button>
          </div>
        );
      } else {
        return <span>En attente de BPCE</span>;
      }

    case "ValidePourSuperviseur":
      return <span>En attente du superviseur</span>;

    case "Transmis":
      return <span>Transmis</span>;

    case "Finalise":
      return <span>Finalisé</span>;

    case "Reevalue":
      return <span>Réévalué</span>;
  }
}

const Statuses = ["Transmis", "Abandonne", "Finalisé"];

const ICONS = [
  {
    condition: (record) => {
      const sentAt = +new Date(record.sentToCandidateOn);
      const openedAt = +new Date(record.linkOpenedAt);

      return openedAt >= sentAt && record.status === "SoumisCandidat";
    },
    title: "Le candidat a commencé la complétion de son formulaire",
    icon: <EyeIcon htmlColor="#777" />,
  },
  {
    condition: (record, status) => {
      return record.version !== "v2" && !Statuses.includes(status);
    },
    title: "Le calcul du temps consacré doit être révisé",
    icon: <WarningIcon htmlColor={Amber[700]} />,
  },
];

function Icons({ record, status }) {
  const i = [];

  ICONS.forEach(({ condition, title, icon }) => {
    if (condition(record, status)) {
      i.push(
        <Box mr="0.25em" title={title} display="flex" alignItems="center">
          {icon}
        </Box>
      );
    }
  });

  return i;
}

export default function CandidatureStatusField(props) {
  const { source } = props;
  const record = props.record || {};

  const status = record[source];

  return (
    <Box display="flex" alignItems="center">
      <Icons status={status} record={record} />
      <Status status={status} id={record.id} />
    </Box>
  );
}
