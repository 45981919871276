import { BooleanInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useOnlyForTargets, usePortalTarget } from "../../candidatures/PortalTarget";

const useStyles = makeStyles({
  booleanCustom: (props) => ({
    minWidth: props.width || "5em",
  }),
});

export default React.forwardRef(({ portalTargets, ...props }, ref) => {
  const classes = useStyles(props);
  const disabled = useOnlyForTargets(portalTargets, props.disabled);
  const portal = usePortalTarget().target;
  
  if (portalTargets && portalTargets !== portal) {
    return <></>
  }

  return (
    <BooleanInput
      ref={ref}
      {...props}
      disabled={disabled}
      label={props.label || ""}
      className={`${classes.booleanCustom} ${props.className}`}
    />
  );
});
