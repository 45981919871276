import React, { useState, useEffect } from "react";
import adminAuthProvider from "../authProvider";
import Box from "@material-ui/core/Box";

import Layout from "../users/auth-components/Layout";

const authProvider = adminAuthProvider;

export default ({ children }) => {
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [resetLink, setResetLink] = useState(true);
  useEffect(() => {
    const func = async () => {
      try {
        const r = await authProvider.isPasswordValid();
        if (r.status !== 200) return;

        const b = await r.text();

        const { success, resetLink } = JSON.parse(b);

        setIsPasswordValid(success);
        setResetLink(resetLink);
      } catch (e) {
        console.error(e);
      }
    };
    func();
  }, []);

  if (isPasswordValid) return children;

  return (
    <Layout>
      <Box display="flex" flexDirection="column">
        <Box mb="1em">
          Votre mot de passe n'est plus valide. Ce dernier doit être renouvelé
          tous les 90 jours. Pour le mettre à jour, merci de cliquer sur le{" "}
          <a href={resetLink}> lien </a>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="1em"></Box>
    </Layout>
  );
};
