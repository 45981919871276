import React, { memo } from "react";

import TabbedFormCustom from "../../components/custom/TabbedFormCustom";
import FormTabCustom from "../../components/custom/FormTabCustom";
import Typography from "../../components/custom/TypographyCustom";

import CandidatDiplomes from "./CandidatDiplomes";
import CandidatRefusEtValidations from "./CandidatRefusEtValidations";
import CandidatExperiences from "./CandidatExperiences";
import CandidatGeneral from "./CandidatGeneral";
import CandidatInfraction from "./CandidatInfraction";
import CandidatAnnexes from "./CandidatAnnexes";
import { useLangue } from "../../candidatures/transalations";

function RecordGetter(props) {
  if (props.recordRef) {
    props.recordRef.current = props.record;
  }
  return null;
}

const CandidatForm = ({
  onFormChange,
  disabled,
  recordId,
  isPrint,
  ...props
}) => {
  const isEditing = !!props.record.id;
  const { t } = useLangue();
  return (
    <>
      {isPrint && (
        <Typography variant="h4" gutterBottom>
          {t("Candidat")}
        </Typography>
      )}
      <TabbedFormCustom
        {...props}
        onFormChange={onFormChange}
        submitOnEnter={false}
        recordId={recordId}
        isPrint={isPrint}
      >
        <FormTabCustom label="Général" isPrint={isPrint}>
          <RecordGetter {...props} />
          <CandidatGeneral
            tabName="general"
            disabled={disabled}
            isEditing={isEditing}
            isPrint={isPrint}
          />
        </FormTabCustom>

        <FormTabCustom label="Diplômes" isPrint={isPrint}>
          <CandidatDiplomes tabName="diplome" disabled={disabled} />
        </FormTabCustom>

        <FormTabCustom label="Validations / Refus" isPrint={isPrint}>
          <CandidatRefusEtValidations
            tabName="validationRefus"
            disabled={disabled}
          />
        </FormTabCustom>

        <FormTabCustom label="Expériences" isPrint={isPrint}>
          <CandidatExperiences tabName="experiences" disabled={disabled} />
        </FormTabCustom>

        <FormTabCustom label="Infractions" isPrint={isPrint}>
          <CandidatInfraction tabName="infractions" disabled={disabled} />
        </FormTabCustom>

        <FormTabCustom label="Annexes" isPrint={isPrint}>
          <CandidatAnnexes tabName="annexes" disabled={disabled} />
        </FormTabCustom>
      </TabbedFormCustom>
    </>
  );
};

export default memo(CandidatForm);
