import React, { memo } from "react";
import Box from "@material-ui/core/Box";

import Typography from "../../components/custom/TypographyCustom";

import { FormDataConsumer, required } from "react-admin";
import TextInputCustom from "../../components/input/TextInputCustom";
import DateInputCustom from "../../components/input/DateInputCustom";
import CheckboxGroupInputCustom from "../../components/input/CheckboxGroupInputCustom";
import NullableBooleanInputCustom from "../../components/input/NullableBooleanInputCustom";
import InfoBulle from "../../components/InfoBulle";
import { useGlobalDisabled } from "./helper";
import { useLangue } from "../transalations";
import { usePortalTarget, IsACPR } from "../PortalTarget";

export default memo(function CandidatureDescription(props) {
  const { tabName } = props;
  const { t } = useLangue();

  const disabled = useGlobalDisabled();

  return (
    <Box maxWidth="72em" key={usePortalTarget}>
      <Typography variant="subtitle1">
        {t("La personne nommée remplace-t-elle quelqu’un d’autre ?")}
      </Typography>
      <NullableBooleanInputCustom
        source="remplacement"
        disabled={disabled}
        id={`${tabName}#remplacement`}
        validate={required()}
      />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => {
          if (formData.remplacement) {
            return (
              <>
                {!IsACPR() && (
                  <>
                    <TextInputCustom
                      id={`${tabName}#remplacementQui`}
                      disabled={disabled}
                      multiline
                      label={t("Indiquer le nom de la personne remplacée")}
                      source="remplacementQui"
                      fullWidth
                      {...rest}
                      />
                    <TextInputCustom
                      id={`${tabName}#remplacementPourquoi`}
                      disabled={disabled}
                      multiline
                      label={t("Raisons du remplacement")}
                      source="remplacementPourquoi"
                      fullWidth
                      {...rest}
                    />
                  </>
                )}
                {IsACPR() && (
                  <>
                    <TextInputCustom
                      id={`${tabName}#remplacementQui`}
                      disabled={disabled}
                      multiline
                      label={t("Identité de la personne remplacée")}
                      source="remplacementQui"
                      fullWidth
                      {...rest}
                      />
                    <DateInputCustom
                      disabled={disabled}
                      label={t('Date de fin de mandat de la personne remplacée')}
                      source="remplacementDateFinMandat"
                      width="30em"
                      id={`${tabName}#remplacementDateFinMandat`}
                    />
                    <TextInputCustom
                      id={`${tabName}#remplacementPourquoi`}
                      disabled={disabled}
                      multiline
                      label={t("Précisez pourquoi")}
                      source="remplacementPourquoi"
                      fullWidth
                      {...rest}
                    />
                  </>
                )}
              </>
            );
          } else {
            formData.remplacementText = null;
          }
        }}
      </FormDataConsumer>

<Typography variant="subtitle1">
        {t("La demande est-elle effectuée dans les délais requis ?")}
      </Typography>
      <NullableBooleanInputCustom
      source="demandeDansDelais"
        id={`${tabName}#demandeDansDelais`}
        disabled={disabled}
        validate={required()}
      />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => {
          if (formData.demandeDansDelais === false) {
            return (
              <Box display="flex">
                <TextInputCustom
                  id={`${tabName}#demandeDansDelaisText`}
                  multiline
                  label={t("Pourquoi ?")}
                  source="demandeDansDelaisText"
                  fullWidth
                  disabled={disabled}
                  {...rest}
                />
                <InfoBulle position="right" ml="1em" mt="0.5em">
                  Réponse communément indiquée en cas de délai : « En raison du
                  délai de traitement administratif du dossier. ».
                </InfoBulle>
              </Box>
            );
          } else {
            formData.demandeDansDelaisText = null;
          }
        }}
      </FormDataConsumer>

      {/* START ACPR-ONLY */}
      <Box display="flex">
        <Typography variant="subtitle1" portalTargets="acpr">
          Nature du contrat entre la personne nommée (renouvelée) et l’entité
          soumise à la surveillance prudentielle&nbsp;:
        </Typography>
        <InfoBulle position="right" ml="1em">
          Pour un administrateur qui n'a pas de relation contractuelle avec
          l'entité, il convient de cocher "mandat social". Quand le mandataire
          social nommé est aussi un salarié de l'entité, les cases "salarié" et
          "mandat social" doivent toutes deux être cochées.
        </InfoBulle>
      </Box>
      <CheckboxGroupInputCustom
        row={false}
        source="natureContrat"
        disabled={disabled}
        portalTargets="acpr"
        choices={[
          { id: "salarie", name: "Salarié" },
          { id: "mandat-social", name: "Mandat social" },
          {
            id: "convention-de-mise-a-disposition",
            name: "Convention de mise à disposition",
          },
        ]}
      />
      <TextInputCustom
        id={`${tabName}#natureContrat`}
        multiline
        label="Autre - Veuillez préciser"
        source="natureContratAutre"
        disabled={disabled}
        portalTargets="acpr"
        fullWidth
      />
      {/* END ACPR-ONLY */}

      <Typography variant="h6" gutterBottom>
        {t(
          "Si le dirigeant effectif n’a pas la qualité de mandataire social :",
        )}
      </Typography>
      <Typography variant="subtitle1" portalTargets="acpr">
        Quel est l’organe ou la personne qui vous a désigné à vos (nouvelles)
        fonctions ?
      </Typography>
      <TextInputCustom
        id={`${tabName}#organeDesignateur`}
        disabled={disabled}
        portalTargets="acpr"
        source="organeDesignateur"
      />

      <Typography variant="subtitle1" portalTargets="acpr">
        Quels sont les pouvoirs qui vous sont délégués ?
      </Typography>
      <TextInputCustom
        id={`${tabName}#pouvoirsDelegues`}
        multiline
        source="pouvoirsDelegues"
        disabled={disabled}
        portalTargets="acpr"
        fullWidth
      />

      <Typography variant="subtitle1" portalTargets="acpr">
        Domaines placés sous la responsabilité hiérarchique et/ou fonctionnelle
        du dirigeant effectif :
      </Typography>
      <TextInputCustom
        id={`${tabName}#domainesSousResponsabilite`}
        multiline
        source="domainesSousResponsabilite"
        disabled={disabled}
        portalTargets="acpr"
        fullWidth
      />

      <Typography variant="subtitle1" portalTargets="acpr">
        À quel(s) comité(s) participez-vous ? (Exemples : comité exécutif,
        comité des risques…)
      </Typography>
      <TextInputCustom
        id={`${tabName}#listeComites`}
        multiline
        disabled={disabled}
        source="listeComites"
        portalTargets="acpr"
        fullWidth
      />

      <Typography variant="subtitle1" portalTargets="acpr">
        Quelles sont les mesures existantes pour assurer la continuité de
        direction en toute circonstance (notamment en cas d’absence ou
        d’empêchement temporaire ou définitif du/des autre(s) dirigeant(s)) ?
      </Typography>
      <TextInputCustom
        id={`${tabName}#mesuresContinuiteDirection`}
        disabled={disabled}
        multiline
        source="mesuresContinuiteDirection"
        portalTargets="acpr"
        fullWidth
      />
    </Box>
  );
});
