import { Box, Typography } from "@material-ui/core";
import React from "react";
import {
  AutocompleteInput,
  FormWithRedirect,
  FormDataConsumer,
  ReferenceInput,
  ReferenceArrayInput,
  required,
  SelectInput,
  Toolbar,
  AutocompleteArrayInput,
  SaveButton,
  email,
} from "react-admin";

import TextInputCustom from "../../components/input/TextInputCustom";
import SelectInputCustom from "../../components/input/SelectInputCustom";
import NullableBooleanInputCustom from "../../components/input/NullableBooleanInputCustom";

import CountryNames from "../../lib/countryNames";

import { ETABLISSEMENT_IMPORTANT_CHOICES } from "../../candidatures/fonctionNames";
import { Langue } from "../../lib/langue";

const validateEmails = [
  (value) => {
    if (value) {
      const emails = value.split(';')
      const allEmailTests = emails.map(emailValue => email(emailValue)(emailValue))
      const badEmails = allEmailTests.filter(e => e)
      const nbBadEmails = badEmails.length
      if (badEmails.length > 0) {
        return `Email${nbBadEmails > 1 ?'s':''} non valide${nbBadEmails > 1 ?'s':''} : ${badEmails.map(error => error.message).join(', ')}`
      }
    }
  },
];

const CountryChoices = Object.entries(CountryNames).map(([id, name]) => ({
  id,
  name,
}));

export default (props) => (
  <FormWithRedirect
    {...props}
    render={(formProps) => {
      return (
        <form>
          <Box p="1em" maxWidth="72em">
            <Typography variant="h6">Identification</Typography>
            <Box>
              <TextInputCustom
                label="Dénomination sociale"
                source="denominationSociale"
                validate={required()}
                width="37em"
              />
            </Box>
            <Box display="flex">
              <Box mr="1em">
                <TextInputCustom
                  label="Forme Juridique"
                  source="formeJuridique"
                />
              </Box>
              <Box mr="1em">
                <NullableBooleanInputCustom
                  label="Entité cotée ?"
                  source="entiteCotee"
                />
              </Box>
            </Box>

            <Box display="flex">
              <Box mr="1em">
                <TextInputCustom
                  label="Code interbancaire"
                  source="codeInterbancaire"
                  validate={required()}
                />
              </Box>

              <Box mr="1em">
                <TextInputCustom
                  label="Code LEI"
                  source="codeLEI"
                  validate={required()}
                />
              </Box>
            </Box>

            <Box display="flex">
              <Box mr="1em">
                <AutocompleteInput
                  source="pays"
                  label="Pays"
                  choices={CountryChoices}
                />
              </Box>
            </Box>

            <Typography variant="h6">Description</Typography>
            <Box>
              <Box mr="1em">
                <TextInputCustom
                  label="Description de l’activité de l’entité"
                  source="descriptionActivite"
                  width="37em"
                />
              </Box>
              <Box mr="1em">
                <TextInputCustom
                  label="Taille de l’entité"
                  source="tailleEntite"
                  width="37em"
                />
              </Box>
            </Box>

            <Box>
              <Box mr="1em">
                <SelectInputCustom
                  allowEmpty={true}
                  label="Structure de gouvernance"
                  source="structureDeGouvernance"
                  choices={[
                    { id: "one-tier", name: "Structure moniste" },
                    { id: "two-tier", name: "Structure dualiste" },
                    { id: "other", name: "Autre structure" },
                  ]}
                />
              </Box>
              <Box mr="1em">
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    if (formData.structureDeGouvernance === "other") {
                      return (
                        <TextInputCustom
                          label="Autre structure de gouvernance"
                          placeholder="Veuillez préciser…"
                          source="structureDeGouvernanceText"
                          multiline
                          width="37em"
                          {...rest}
                        />
                      );
                    }

                    return null;
                  }}
                </FormDataConsumer>
              </Box>
            </Box>

            <Typography variant="subtitle1">
              L’entité soumise à la surveillance prudentielle est-elle un «
              établissement important au titre de la CRD » conformément au droit
              national ?
            </Typography>
            <SelectInputCustom
              source="estEtablissementImportant"
              choices={ETABLISSEMENT_IMPORTANT_CHOICES[Langue.Fr]}
            />

            <Typography variant="h6">Réseau</Typography>
            <Box>
              <TextInputCustom
                label="Groupe d’appartenance"
                source="groupeAppartenance"
              />
            </Box>
            <Box display="flex">
              <Box>
                <TextInputCustom
                  label="Réseau/Filiale"
                  source="reseauFiliale"
                  validate={required()}
                />
              </Box>
              <Box ml="1em">
                <TextInputCustom
                  label="Entité mère"
                  source="entiteMere"
                  validate={required()}
                />
              </Box>
            </Box>

            <Box display="flex">
              <Box>
                <TextInputCustom
                  label="Palier"
                  source="palier"
                  validate={required()}
                />
              </Box>
              <Box ml="1em">
                <TextInputCustom
                  label="Sous-Palier"
                  source="sousPalier"
                  validate={required()}
                />
              </Box>
            </Box>

            <Box mt="3em" />
            <Typography variant="h6">Planchers</Typography>

            <Box width="100%">
              <ReferenceInput
                label="Planchers"
                source="plancherId"
                reference="planchers"
              >
                <SelectInput optionText="nom" />
              </ReferenceInput>
            </Box>

            <Box mt="3em" />

            <Typography variant="h6" gutterBottom>
              Contacts
            </Typography>
            <ReferenceArrayInput
              label="Contacts"
              source="contactIds"
              reference="users"
            >
              <AutocompleteArrayInput
                optionText={(contact) => {
                  if (contact) {
                    const { firstName, lastName } = contact;
                    return `${firstName} ${lastName}`;
                  }

                  return "";
                }}
              />
            </ReferenceArrayInput>

            <Box mr="1em">

                
              <TextInputCustom
                width="72em"
                label="Emails recevant les formations (séparées par des points virgules)"
                placeholder="email1@example.com;email2@example.com"
                source="formationEmail"
                validate={validateEmails}
                />
            </Box>
          </Box>



          <Toolbar
            record={formProps.record}
            basePath={formProps.basePath}
            undoable={false}
            invalid={formProps.invalid}
            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            saving={formProps.saving}
            pristine={formProps.pristine}
            submitOnEnter={false}
            resource="entites"
            redirect="list"
          >
            <SaveButton />
          </Toolbar>
        </form>
      );
    }}
  />
);
