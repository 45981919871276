import React, { memo, useEffect, useState } from "react";

import {
  RadioButtonGroupInput,
  SelectInput,
  FormDataConsumer,
  required,
} from "react-admin";
import { useField } from "react-final-form";

import Box from "@material-ui/core/Box";
import Typography from "../../components/custom/TypographyCustom";
import Divider from "@material-ui/core/Divider";

import { getPermissionsSync } from "../../authProvider";

import NullableBooleanInputCustom from "../../components/input/NullableBooleanInputCustom";
import ReferenceInputCustom from "../../components/input/ReferenceInputCustom";
import TextInputCustom from "../../components/input/TextInputCustom";
import AutocompleteInput from "../../components/input/AutocompleteInputCutom";
import SelectInputCustom from "../../components/input/SelectInputCustom";

import FileInput from "../../components/File";

import { useGlobalDisabled } from "./helper";
import { useLangue } from "../transalations";
import { usePortalTarget } from "../PortalTarget";

const candidatRenderer = (choice) =>
  choice && choice.nom && choice.prenom && `${choice.nom} ${choice.prenom}`;

const StatusesLabels = {
  Brouillon: "Brouillon",
  SoumisCandidat: "Soumis au candidat",
  ValidationEntite: "En attente de validation/correction par l’entité",
  ValidationBpce: "En attente de validation par BPCE",
  ValidePourSuperviseur: "Valide pour transmission au superviseur",
  Transmis: "Transmis au superviseur",
  Finalise: "Finalisé",
  Abandonne: "Abandonné",
  Reevalue: "Réévalué",
};

const AllowedStatuses = {
  Brouillon: [
    "SoumisCandidat",
    "Abandonne",
    "ValidationEntite",
    "ValidationBpce",
    "Reevalue",
  ],
  SoumisCandidat: [
    "Brouillon",
    "ValidationEntite",
    "Abandonne",
    "Finalise",
    "Reevalue",
  ],
  ValidationEntite: [
    "ValidationBpce",
    "SoumisCandidat",
    "Abandonne",
    "Finalise",
    "Reevalue",
  ],
  ValidationBpce: [
    "ValidePourSuperviseur",
    "ValidationEntite",
    "Abandonne",
    "Finalise",
    "Reevalue",
  ],
  ValidePourSuperviseur: [
    "Transmis",
    "ValidationBpce",
    "Abandonne",
    "Finalise",
    "Reevalue",
  ],
  Transmis: ["ValidePourSuperviseur", "Finalise", "Reevalue"],
  Abandonne: ["Brouillon", "Reevalue"],
  Reevalue: ["Brouillon", "SoumisCandidat", "ValidationEntite", "ValidationBpce", "ValidePourSuperviseur", "Transmis", "Finalise"],
};

function getValidStatutChoices(currentStatutId) {
  let s = currentStatutId;
  if (!Object.hasOwnProperty.call(AllowedStatuses, currentStatutId)) {
    console.warn(
      `Statut: ${currentStatutId} is invalid, defaulting to Brouillon`
    );
    s = "Brouillon";
  }

  let currentChoice = {
    id: currentStatutId,
    name: StatusesLabels[currentStatutId],
  };

  return [
    currentChoice,
    ...AllowedStatuses[s].map((statut) => ({
      id: statut,
      name: StatusesLabels[statut],
    })),
  ];
}

export default memo(function CandidatureGeneral(props) {
  const { tabName, isPrint } = props;
  const [role, setRole] = useState("user");

  const { t } = useLangue();
  const { target, setTarget } = usePortalTarget();

  useEffect(() => {
    const r = getPermissionsSync();
    setRole(r);
  }, []);

  const {
    input: { value: statut },
  } = useField("statut");

  const disabled = useGlobalDisabled();

  return (
    <Box maxWidth="72em">
      {!isPrint && (
        <ReferenceInputCustom
          label={t("Candidat")}
          source="candidatId"
          reference="candidats"
          allowEmpty
          sort={{
            field: "nom",
            order: "ASC",
          }}
          perPage={9999}
        >
          <AutocompleteInput
            optionText={candidatRenderer}
            width="36em"
            disabled={disabled}
          />
        </ReferenceInputCustom>
      )}
      <SelectInputCustom
        disabled={disabled}
        label={t("Type de candidat")}
        source="typeCandidat"
        id={`${tabName}#typeCandidat`}
        validate={required()}
        choices={[
          { id: "interne", name: t("Interne") },
          { id: "externe", name: t("Externe") },
          { id: "groupe", name: t("Groupe") },
        ]}
      />
      <Typography variant="subtitle1" portalTargets="imas">
        L’entité soumise à la surveillance prudentielle est-elle un «
        établissement important au titre de la CRD » conformément au droit
        national ?
      </Typography>
      <NullableBooleanInputCustom
        source="entiteDepasseSeuilFixe"
        disabled={disabled}
        portalTargets="imas"
        infoBulle={
          <>
            La CRD prévoit que la limitation du nombre de mandats (1 exécutif +
            2 non-exécutifs ou 4 non-exécutifs) s’applique dès lors que la
            fonction de direction est exercée au sein d’un établissement ayant
            une importance significative en raison de sa taille, de son
            organisation interne, ainsi que de la nature, de l'échelle et de la
            complexité de ses activités.
            <br />
            <br />
            Le guide BCE reprend cette notion et indique qu’un établissement de
            crédit est considéré comme important au titre de la CRD en vertu du
            droit national, sur la base d’un ensemble de critères qualitatifs et
            quantitatifs (le montant total des actifs, calculé sur une base
            individuelle ou consolidée, par exemple).
            <br />
            <br />
            La directive CRD a été transposée en droit français et notamment à
            l’article R 511-17 du CMF : celui-ci détermine l’importance d’un
            établissement de crédit par son total de bilan supérieur à 15 Md€
            pendant 2 exercices consécutifs mais aussi lorsque l’ACPR a décidé
            que l’établissement de crédit ou la société de financement revêt une
            importance significative en considération de son organisation
            interne ou de la nature, de l'échelle et de la complexité de ses
            activités.
          </>
        }
      />

      <Box>
        <Box mr="1em">
          <SelectInputCustom
            allowEmpty={true}
            label="Structure de gouvernance"
            source="structureDeGouvernance"
            portalTargets="imas"
            choices={[
              { id: "one-tier", name: "Structure moniste" },
              { id: "two-tier", name: "Structure dualiste" },
              { id: "other", name: "Autre structure" },
            ]}
          />
        </Box>
        <Box mr="1em">
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (formData.structureDeGouvernance === "other") {
                return (
                  <TextInputCustom
                    label="Autre structure de gouvernance"
                    placeholder="Veuillez préciser…"
                    source="structureDeGouvernanceText"
                    portalTargets="imas"
                    multiline
                    width="37em"
                    {...rest}
                  />
                );
              }

              return null;
            }}
          </FormDataConsumer>
        </Box>
      </Box>

      <Typography variant="subtitle1" portalTargets="acpr">
        Toute autre information jugée utile par la personne nommée (renouvelée)
        ou l’entité soumise à la surveillance prudentielle, aux fins de
        l’évaluation, et n'apparaissant pas dans ce formulaire, doit être
        ajoutée ici :
      </Typography>
      <TextInputCustom
        multiline
        disabled={disabled}
        portalTargets="acpr"
        id={`${tabName}#informationsComplementaires`}
        source="informationsComplementaires"
        fullWidth
      />
      <Typography variant="subtitle1">
        {t("S’agit-il d’un renouvellement de poste ?")}
      </Typography>
      <NullableBooleanInputCustom
        source="renouvellementPosition"
        disabled={disabled}
        initialValue={false}
      />
      {(statut === "ValidePourSuperviseur" || statut === "Transmis") && (
        <>
          <Divider />
          <Box mt="1em">
            <FileInput
              disabled={disabled && statut !== "Transmis"}
              source="formulaireSuperviseur"
              label="Formulaire téléchargé depuis le portail du superviseur"
            />
          </Box>
        </>
      )}

      <Divider />
      <Box mt="1em">
        <Typography variant="h6">
          Modalités de transmission du formulaire au superviseur&nbsp;:
        </Typography>
        <RadioButtonGroupInput
          source="portalTarget"
          label=""
          choices={[
            { id: "imas", name: "Portail IMAS" },
            { id: "acpr", name: "Portail Autorisations" },
          ]}
        />
        <FormDataConsumer>
          {({ formData }) => {
            if (formData?.portalTarget !== target) {
              setTimeout(() => setTarget(formData.portalTarget));
            }
          }}
        </FormDataConsumer>
      </Box>

      {role === "superadmin" && (
        <>
          <Divider />
          <Box mt="1em">
            <Typography variant="h6">État de la candidature</Typography>
            <SelectInput
              disabled={disabled && statut !== "Transmis"}
              source="statut"
              choices={getValidStatutChoices(statut)}
            />
          </Box>
        </>
      )}
    </Box>
  );
});
