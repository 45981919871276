import React, { useCallback, useState } from "react";
import { Alert } from "@material-ui/lab";
import { useLogin, /* useNotify,  */ Notification } from "react-admin";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Form } from "react-final-form";

import Input from "./Input";
import Layout from "./Layout";

const { REACT_APP_FRONT_URL } = process.env;

export default function LoginPage(props) {
  const { theme } = props;
  const login = useLogin();
  const [errorMessage, setErrorMessage] = useState(null);

  const submit = useCallback(
    async (values, _form) => {
      setErrorMessage(null);
      try {
        await login(values);
      } catch (error) {
        console.error("error: ", error);
        let message = error.message;

        if (message && message.startsWith("Trop de tentative")) {
          message = (
            <span>
              Votre compte a été verrouillé suite à 3 tentatives
              d’authentification échouées. Veuillez patienter 20 minutes avant
              un nouvel essai de connexion ou{" "}
              <a href="/forgot-password">cliquez ici</a> en cas de mot de passe
              oublié.
            </span>
          );
        } else if (message && message.startsWith("Mot de passe expiré")) {
          const resetLink = message.split(" | ")[1];

          message = (
            <span>
              Votre de mot de passe a expiré. Veuillez le renouveller en{" "}
              <a href={resetLink}>cliquant ici</a>.
            </span>
          );
        }

        setErrorMessage(message || "Nom d’utilisateur ou mot de pass invalide");
      }
    },
    [login]
  );

  return (
    <Layout theme={theme}>
      <Form
        onSubmit={submit}
        render={({ handleSubmit }) => (
          <form>
            <Box display="flex" flexDirection="column">
              <Box mb="1em">
                <Input name="email" label="Adresse e-mail" required />
              </Box>
              <Input
                name="password"
                type="password"
                label="Mot de passe"
                required
              />
            </Box>

            {errorMessage !== null && (
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            )}

            <Box mt="2em" width="100%">
              <Button
                variant="contained"
                type="submit"
                color="primary"
                onClick={handleSubmit}
                style={{ width: "100%" }}
              >
                Connexion
              </Button>
            </Box>

            <Box display="flex" justifyContent="space-between" mt="1em">
              <Link to="/forgot-password">Mot de passe oublié&#x202F;?</Link>
              <a href={`${REACT_APP_FRONT_URL}/accreditations/request-access`}>
                Première connexion
              </a>
            </Box>
          </form>
        )}
      />
      <Notification />
    </Layout>
  );
}
