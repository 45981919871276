import React, { useState, useCallback } from "react";

import { makeStyles } from "@material-ui/styles";

import Typography from "../components/custom/TypographyCustom";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/CheckCircleRounded";
//
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
//
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FilledInput from "@material-ui/core/FilledInput";

import { Langue } from "../lib/langue";

const useStyles = makeStyles({
  formControl: {
    width: "100%",
  },
});

function LangueSelect({ langue, onLangueChange, ...props }) {
  const classes = useStyles();
  return (
    <FormControl variant="filled" className={classes?.formControl}>
      <InputLabel htmlFor="filled-langue-simple">Langue</InputLabel>
      <Select
        value={langue}
        onChange={onLangueChange}
        input={<FilledInput name="langue" id="filled-langue-simple" />}
      >
        <MenuItem value={Langue.Fr}>Français</MenuItem>
        <MenuItem value={Langue.En}>Anglais</MenuItem>
      </Select>
    </FormControl>
  );
}

export default function LanguagePicker({ title, show, onCancel, onPick }) {
  const [langue, setLangue] = useState(Langue.Fr);

  const onLangueChange = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    setLangue(event?.target?.value);
  }, []);

  const onContinue = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      onPick(langue);
    },
    [langue, onPick]
  );

  const onClose = useCallback(
    (event, reason) => {
      event.preventDefault();
      event.stopPropagation();

      console.log(reason);

      onCancel(event);
    },
    [onCancel]
  );

  return (
    <Dialog
      open={show}
      onClose={onClose}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>Merci de sélectionner la langue utilisée&nbsp;:</Typography>
        <LangueSelect langue={langue} onLangueChange={onLangueChange} />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onClose}>
          Annuler
        </Button>
        <Button color="primary" onClick={onContinue}>
          <CheckIcon mr="0.1em" />
          Continuer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
