import React from "react";
import { FormDataConsumer } from "react-admin"
import Box from "@material-ui/core/Box";

import Typography from "../../../components/custom/TypographyCustom";
import TextInputCustom from "../../../components/input/TextInputCustom";

import { useLangue } from "../../transalations";

export default function LienProfessionnel({ disabled, tabName, name }) {
  const { t } = useLangue();
  return (
    <Box>
      <Typography variant="subtitle1">{t("Nom de l’entité")}</Typography>

      <TextInputCustom
        disabled={disabled}
        source={`${name}.nomEntite`}
        id={`${tabName}#nomEntite`}
      />
      <Typography variant="subtitle1">
        {t("Principales activités de l’entité")}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        source={`${name}.activitesEntreprise`}
        id={`${tabName}#activitesEntreprise`}
        fullWidth
        multiline
      />
      <Typography variant="subtitle1">
        {t("Type de relation avec l’entité")}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        source={`${name}.lienAvecEntite`}
        id={`${tabName}#lienAvecEntite`}
        fullWidth
        multiline
      />
      <Typography variant="subtitle1">
        {t("Date(s) de début et (le cas échéant) de fin de la relation")}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        source={`${name}.dateDebutFin`}
        id={`${tabName}#dateDebutFin`}
      />

      <Typography variant="subtitle1">
        {t("Versements annuels effectués par le payeur (en milliers d’euros)")}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        source={`${name}.valeurPaimentsAnnuels`}
        id={`${tabName}#valeurPaimentsAnnuels`}
      />
      <Typography variant="subtitle1">
        {t("Versements annuels effectués par le payeur (en pourcentage de son revenu brut (consolidé))")}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        source={`${name}.paiementsAnnuelsPayeur`}
        portalTargets="imas"
        id={`${tabName}#paiementsAnnuelsPayeur`}
      />
      <Typography variant="subtitle1">
        {t("Versements annuels reçus par le bénéficiaire (en pourcentage de son revenu brut (consolidé))")}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        source={`${name}.paiementsAnnuelsRecus`}
        portalTargets="imas"
        id={`${tabName}#paiementsAnnuelsRecus`}
      />
    
    </Box>
  );
}
