import React from "react";

import { Typography, Box } from "@material-ui/core";

import TranslatableTextInputCustom from "../../../components/input/TranslatableTextInputCustom";
import SelectInputCustom from "../../../components/input/SelectInputCustom";

import { Enum } from "../../../utils";

const Triggers = Enum({
  Appointee: "appointee",
  Entity: "entity",
});

const TRIGGER_CHOICES = [
  { id: Triggers.Appointee, name: "Le candidat" },
  { id: Triggers.Entity, name: "L’entité" },
];

function Subtitle(props) {
  return (
    <Box maxWidth="72em">
      <Typography {...props} />
    </Box>
  );
}

export default function Faillite({ disabled, tabName, ...rest }) {
  return (
    <Box ml="2em" mr="2em">
      <TranslatableTextInputCustom
        label="Temps écoulé depuis la date de la décision"
        disabled={disabled}
        source={"infractionsIMAS.failliteDepuis"}
        id={`${tabName}#infractionsIMAS.failliteDepuis`}
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Statut et conclusion (le cas échéant) de la procédure (si elle a été
        menée à terme, veuillez préciser si elle a conclu à une faute
        intentionnelle ou à une négligence)
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        fullWidth
        source={"infractionsIMAS.failliteStatut"}
        id={`${tabName}#infractionsIMAS.failliteStatut`}
        {...rest}
      />
      <TranslatableTextInputCustom
        label="Mesures de précaution ou de saisie"
        disabled={disabled}
        multiline
        fullWidth
        source={"infractionsIMAS.failliteMesuresPrecaution"}
        id={`${tabName}#infractionsIMAS.failliteMesuresPrecaution`}
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Qui a déclanché la procédure (vous-même ou l’entité concernée)&nbsp;?
      </Subtitle>
      <SelectInputCustom
        disabled={disabled}
        source={"infractionsIMAS.failliteDeclencheur"}
        id={`${tabName}#infractionsIMAS.failliteDeclencheur`}
        choices={TRIGGER_CHOICES}
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Le cas échéant, veuillez fournir des informations sur l’entité impliquée
        dans la procédure
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        fullWidth
        source={"infractionsIMAS.failliteDetailsEntite"}
        id={`${tabName}#infractionsIMAS.failliteDetailsEntite`}
        helperText="if applicable"
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Détails concernant le rôle que vous avez joué, en particulier si vous
        avez été déclaré(e) responsable de l’insolvabilité de l’entité
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        fullWidth
        source={"infractionsIMAS.failliteImplicationPersonnelle"}
        id={`${tabName}#infractionsIMAS.failliteImplicationPersonnelle`}
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Facteurs atténuants ou aggravants éventuels
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        fullWidth
        source={"infractionsIMAS.failliteCirconstancesAggravantes"}
        id={`${tabName}#infractionsIMAS.failliteCirconstancesAggravantes`}
        {...rest}
      />
    </Box>
  );
}

Faillite.clear = function (formData) {
  if (formData.infractionsIMAS) {
    formData.infractionsIMAS.failliteDepuis = null;
    formData.infractionsIMAS.failliteStatut = null;
    formData.infractionsIMAS.failliteMesuresPrecaution = null;
    formData.infractionsIMAS.failliteDeclencheur = null;
    formData.infractionsIMAS.failliteImplicationPersonnelle = null;
    formData.infractionsIMAS.failliteCirconstancesAggravantes = null;
    if (formData.infractionsIMAS.traductions) {
      formData.infractionsIMAS.traductions.forEach((_traduction, idx) => {
        formData.infractionsIMAS.traductions[idx].failliteDepuis = null;
        formData.infractionsIMAS.traductions[idx].failliteStatut = null;
        formData.infractionsIMAS.traductions[idx].failliteMesuresPrecaution =
          null;
        formData.infractionsIMAS.traductions[idx].failliteDeclencheur = null;
        formData.infractionsIMAS.traductions[
          idx
        ].failliteImplicationPersonnelle = null;
        formData.infractionsIMAS.traductions[
          idx
        ].failliteCirconstancesAggravantes = null;
      });
    }
  }
};
