/**
 * This component wraps React Admin’s ReferenceInput so that, when
 * embedded in an iframe, it can signal the parent window that
 * referenced data has been loaded and is ready to be displayed.
 * (In print setting for instance)
 */
import React, { useEffect, useState } from "react";
import { ReferenceInput } from "react-admin";
import { v4 as uuid } from "uuid";

function isIFrame() {
  return !!window.frameElement;
}

function registerToParent(id) {
  if (isIFrame()) {
    window.parent.postMessage(
      {
        type: "REGISTER",
        payload: id,
      },
      window.frameElement.baseURI
    );
  }
}

function setLoaded(id) {
  if (isIFrame()) {
    window.parent.postMessage(
      {
        type: "SET_LOADED",
        payload: id,
      },
      window.frameElement.baseURI
    );
  }
}

function Wrapper({ children, ...props }) {
  const { loaded } = props;
  const [id] = useState(uuid());

  useEffect(() => {
    registerToParent(id);
  }, [id]);

  useEffect(() => {
    if (loaded) {
      setLoaded(id);
    }
  }, [loaded, id]);

  return React.cloneElement(children, { ...props });
}

class ReferenceErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  static getDerivedStateFrom(error) {
    return { error };
  }

  componentDidCatch(error /* , errorInfo */) {
    error.message = `AutocompleteInput: ${error.message}\nerrorInfo`;
    console.error(error);
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    return (
      <>
        {error === null ? (
          children
        ) : (
          <div className="error">Une erreur est survenue</div>
        )}
      </>
    );
  }
}

const ReferenceInputCustom = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <ReferenceErrorBoundary>
      <ReferenceInput ref={ref} {...props}>
        <Wrapper>{children}</Wrapper>
      </ReferenceInput>
    </ReferenceErrorBoundary>
  );
});

ReferenceInputCustom.whyDidYouRender = true;

export default ReferenceInputCustom;
