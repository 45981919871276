import React from "react";
import { Show, SaveContextProvider } from "react-admin";
import Box from "@material-ui/core/Box";

// import Candidat from "../Candidat";
import Candidat from "../../../candidats/form/CandidatForm";
import Candidature from "../../../candidatures/form/CandidatureForm";

import { ErrorBoundary } from "../../../lib/error-report";
import { LangueProvider } from "./../../transalations";

const saveContext = {
  save: () => {},
};

function CandidatureView(props) {
  const langue = props.langue || props.record.langue;

  //FIXME: This is hacky as hell but it migh just work
  window.sessionStorage.setItem(
    "fp-candidat-portal-target",
    props.record.portalTarget || "acpr"
  );

  return (
    <ErrorBoundary>
      <SaveContextProvider value={saveContext}>
        <Box p="2em" py="3em">
          <LangueProvider langue={langue}>
            <Candidat
              record={props.record.candidat}
              basePath="/candidats"
              resource="candidats"
              recordId={props.record.candidat.id}
              isPrint
            />
            <Candidature
              record={props.record}
              basePath="/candidatures"
              resource="candidatures"
              recordId={props.record.id}
              isPrint
            />
          </LangueProvider>
        </Box>
      </SaveContextProvider>
    </ErrorBoundary>
  );
}

export default function CandidatureShow(props) {
  return (
    <Show {...props}>
      <CandidatureView />
    </Show>
  );
}
