import React from "react";
import Box from "@material-ui/core/Box";

import Typography from "../../../components/custom/TypographyCustom";
import DateInputCustom from "../../../components/input/DateInputCustom";
import TextInputCustom from "../../../components/input/TextInputCustom";
import SelectInputCustom from "../../../components/input/SelectInputCustom";
import NumberInputCustom from "../../../components/input/NumberInputCustom";

import { useLangue } from "../../transalations";

export default function ObligationFinanciere({
  name,
  tabName,
  disabled,
  portalTarget,
}) {
  const { t } = useLangue();

  if (portalTarget === "acpr") {
    return (
      <Box>
        <TextInputCustom
          label={t("Type de l’obligation")}
          source={`${name}.type`}
          id={`${tabName}#${name}.type`}
          disabled={disabled}
          fullWidth
        />
        <TextInputCustom
          label={t("Valeur de l’obligation")}
          source={`${name}.amount`}
          id={`${tabName}#${name}.amount`}
          disabled={disabled}
          fullWidth
        />
        <TextInputCustom
          label="Période d’existence de l’obligation"
          source={`${name}.periode`}
          id={`${name}#${name}.periode`}
          disabled={disabled}
          fullWidth
        />
        <TextInputCustom
          label="Commentaires"
          source={`${name}.commentaires`}
          disabled={disabled}
          fullWidth
          multiline
        />
      </Box>
    );
  }

  return (
    <Box>
      <TextInputCustom
        label={t("Nom du débiteur")}
        source={`${name}.nomDebiteur`}
        id={`${tabName}#${name}.nomDebiteur`}
        disabled={disabled}
        portalTargets="imas"
        helperText="s’il ne s’agit pas de la personne nommée / personne à nommer, précisez la relation entretenue avec celle-ci"
        fullWidth
      />
      <TextInputCustom
        label={t("Nom de l’entité")}
        source={`${name}.nomEntite`}
        id={`${tabName}#${name}.nomEntite`}
        portalTargets="imas"
        disabled={disabled}
        fullWidth
      />
      <TextInputCustom
        label={t("Nature de l’obligation")}
        source={`${name}.type`}
        id={`${tabName}#${name}.type`}
        disabled={disabled}
        fullWidth
      />
      <TextInputCustom
        label={t("Montant de l’obligation")}
        source={`${name}.amount`}
        id={`${tabName}#${name}.amount`}
        disabled={disabled}
        fullWidth
      />
      <Typography variant="subtitle1" portalTargets="imas">
        {t("Garantie, le cas échéant")}
      </Typography>
      <TextInputCustom
        disabled={disabled}
        multiline
        source={`${name}.guarantee`}
        portalTargets="imas"
        id={`${tabName}#${name}.guarantee`}
        fullWidth
        helperText="type et montant"
      />
      <Typography variant="subtitle1" portalTargets="imas">
        {t("Statut des obligations")}
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        multiline
        source={`${name}.statut`}
        portalTargets="imas"
        id={`${tabName}#${name}.statut`}
        fullWidth
        choices={[
          { id: "performing", name: "Performant(e)" },
          { id: "non-performing", name: "Non Performant(e)" },
        ]}
      />
      <Typography variant="subtitle1" portalTargets="imas">
        {t("Conditions des obligations")}
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        multiline
        source={`${name}.conditions`}
        portalTargets="imas"
        id={`${tabName}#${name}.conditions`}
        fullWidth
        choices={[
          { id: "market-conditions", name: "Conditions du marché" },
          {
            id: "negotiated-at-arms-length",
            name: "Conditions normales du marché",
          },
          { id: "special-conditions", name: "Conditions spéciales" },
          {
            id: "supported-under-the-collective-bargaining-agreements",
            name: "Couvert(e) par les conventions collectives",
          },
          {
            id: "contracts-with-standardised-terms-applied-together-and-on-a-regular-basis-to-a-large-number-of-customers",
            name: "Contrats assortis de clauses normalisées appliquées ensemble et de manière régulière à un grand nombre de clients",
          },
          { id: "other", name: "Autre" },
        ]}
      />
      <DateInputCustom
        disabled={disabled}
        label={t("Date de début")}
        id={`${tabName}#${name}.dateDebut`}
        name={`${name}.dateDebut`}
      />
      <Typography variant="subtitle1">
        {t(
          "Valeur de l’obligation en pourcentage du total des prêts du débiteur"
        )}
      </Typography>
      <NumberInputCustom
        disabled={disabled}
        name={`${name}.valueToLoans`}
        id={`${tabName}#${name}.valueToLoans`}
      />
      <Typography variant="subtitle1">
        {t(
          "Valeur de l’obligation en pourcentage du total des fonds propres éligibles de l’entité soumise à la surveillance prudentielle"
        )}
      </Typography>
      <NumberInputCustom
        disabled={disabled}
        name={`${name}.valueToCapital`}
        id={`${tabName}#${name}.valueToCapital`}
      />
    </Box>
  );
}
