import React, { useCallback, useState } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Form } from "react-final-form";

import Input from "./Input";
import Layout from "./Layout";

import authProvider from "../../authProvider";

export default function ForgotPassword(props) {
  const { theme } = props;
  const [sent, setSent] = useState(false);

  const submit = useCallback(async (values, _form) => {
    // For security reasons, we donot inform the user about
    // the existence or not of the email it sent
    try {
      await authProvider.forgotPassword(values.email);
    } catch {
      //
    }

    setSent(true);
  }, []);

  return (
    <Layout theme={theme}>
      {sent ? (
        <Box display="flex" flexDirection="column">
          <Typography variant="h6">Envoyé&#x202F;!</Typography>
          <Typography variant="body2">
            Si nous avons un compte validé associé à cette adresse e-mail, vous
            recevrez un e-mail vous permettant de réinitialiser votre mot de
            passe.
          </Typography>
        </Box>
      ) : (
        <Form
          onSubmit={submit}
          render={({ handleSubmit }) => (
            <Box display="flex" flexDirection="column">
              <Box mb="2em">
                <Typography variant="h6">
                  Vous avez oublié votre mot de passe&#x202F;?
                </Typography>
                <Typography variant="body2">
                  Pas d’inquiétude&#x202F;!
                  <br />
                  Entrez l’adresse e-mail avec laquelle vous vous connectez
                  habituellement, nous vous enverrons un e-mail avec un lien de
                  récupération&nbsp;:
                </Typography>
              </Box>
              <Box mb="1em">
                <Input
                  name="email"
                  type="email"
                  label="Adresse e-mail"
                  autoFocus
                  onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
                />
              </Box>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
              >
                Envoyer
              </Button>
            </Box>
          )}
        />
      )}
    </Layout>
  );
}
