import React from "react";

import _ from "lodash";
import Box from "@material-ui/core/Box";
import { FormDataConsumer } from "react-admin";

import Typography from "../custom/TypographyCustom";
import NullableBooleanInputCustom from "./NullableBooleanInputCustom";
import TranslatableTextInputCustom from "./TranslatableTextInputCustom";
import TextInputCustom from "./TextInputCustom";

import InfoBulle from "../../components/InfoBulle";

import { useOnlyForTargets, usePortalTarget } from "../../candidatures/PortalTarget";

function Subtitle(props) {
  return (
    <Box maxWidth="72em">
      <Typography {...props} />
    </Box>
  );
}

const DEFAULT_TEXT_INPUT_LABEL = "Veuillez apporter des précisions :";

function DefaultTextInput({
  disabled,
  // property,
  propertyText,
  textInputLabel,
  tabName,
  noTranslate,
  ...rest
}) {
  const TextInput = noTranslate ? TextInputCustom : TranslatableTextInputCustom;
  return (
    <Box>
      <Subtitle variant="subtitle1">
        {textInputLabel || DEFAULT_TEXT_INPUT_LABEL}
      </Subtitle>
      <TextInput
        multiline
        disabled={disabled}
        source={propertyText}
        id={`${tabName}#${propertyText}`}
        fullWidth
        {...rest}
      />
    </Box>
  );
}

export default (props) => {
  const {
    label,
    textInputLabel,
    infoBulle,
    infoBullePosition = "right",
    children,
    onNo,
    property,
    portalTargets,
    noTranslate = false,
    subQuestion = false,
    ...restOfProps
  } = props;
  const propertyText = `${property}Text`;

  const disabled = useOnlyForTargets(portalTargets, props.disabled);
  const portal = usePortalTarget().target;

  if (portalTargets && portalTargets !== portal) {
    return <></>
  }

  return (
    <>
      {label && (
        <Box display="flex">
          <Subtitle
            variant="subtitle1"
            portalTargets={portalTargets}
            dangerouslySetInnerHTML={{ __html: `${subQuestion ? '' : '▸ '}${label}` }}
          />
          {infoBulle && (
            <InfoBulle
              position={infoBullePosition}
              dangerouslySetInnerHTML={{ __html: infoBulle }}
            />
          )}
        </Box>
      )}

      <NullableBooleanInputCustom disabled={disabled} source={`${property}`} />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => {
          const data = _.get(formData, property);
          const dataText = _.get(formData, propertyText);
          if (data) {
            if (typeof children == "function") {
              return children({ ...rest, disabled });
            } else if (Array.isArray(children)) {
              return children.map((child) =>
                React.cloneElement(child, {
                  disabled,
                  property,
                  propertyText,
                  ...rest,
                  ...restOfProps,
                })
              );
            } else if (typeof children == "object") {
              return React.cloneElement(children, {
                disabled,
                property,
                propertyText,
                ...rest,
                ...restOfProps,
              });
            } else if (
              !children ||
              (Array.isArray(children) && children.length === 0)
            ) {
              return (
                <DefaultTextInput
                  disabled={disabled}
                  textInputLabel={textInputLabel}
                  property={property}
                  propertyText={propertyText}
                  noTranslate={noTranslate}
                  {...rest}
                  {...restOfProps}
                />
              );
            } else {
              return children;
            }
          } else if (onNo) {
            onNo(formData);
          } else if (dataText) {
            _.set(formData, propertyText, null);
          }
        }}
      </FormDataConsumer>
    </>
  );
};
