import React from "react";

import Box from "@material-ui/core/Box";

import TextInputCustom from "../../../components/input/TextInputCustom";

import { useLangue } from "../../transalations";

export default function RelationNuisible({ disabled, tabName, ...rest }) {
  const { t } = useLangue();

  return (
    <Box>
      <Box display="flex">
        <TextInputCustom
          disabled={disabled}
          label={t(
            "Nature et teneur de la relation, du poste, ou de la participation",
          )}
          source={"relationNuisibleNature"}
          portalTargets="imas"
          id={`${tabName}#relationNuisibleNature`}
          multiline
          fullWidth
          {...rest}
        />
      </Box>
      <Box display="">
        <Box>
          <TextInputCustom
            disabled={disabled}
            label={t("Date de début de la relation")}
            helperText="ou de la participation ou date d’entrée en fonction"
            source={"relationNuisibleDateDebut"}
            portalTargets="imas"
            id={`${tabName}#relationNuisibleDateDebut`}
            multiline
            fullWidth
            {...rest}
          />
        </Box>
        <Box >
          <TextInputCustom
            disabled={disabled}
            label={t("Lien entre ce poste (ou l’entité au sein de laquelle ce poste est ou a été occupé) et l’entité soumise à la surveillance prudentielle, la société mère ou leurs filiales")}
            source={"relationNuisibleInfluence"}
            portalTargets="imas"
            id={`${tabName}#relationNuisibleInfluence`}
            multiline
            fullWidth
            {...rest}
          />
        </Box>
      </Box>
    </Box>
  );
}

RelationNuisible.clear = function (formData) {
  formData.relationNuisibleNature = null;
  formData.relationNuisibleDateDebut = null;
  formData.relationNuisibleInfluence = null;
};
