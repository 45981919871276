import { NullableBooleanInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import InfoBulle from "../InfoBulle";
import { useOnlyForTargets, usePortalTarget } from "../../candidatures/PortalTarget";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
  },

  booleanCustom: (props) => ({
    minWidth: props.width || "5em",
  }),
});

function sanitizeProps({ infoBulle, ...props }) {
  return props;
}

export default React.forwardRef(
  ({ portalTargets, validate, ...props }, ref) => {
    const classes = useStyles(props);

    const disabled = useOnlyForTargets(portalTargets, props.disabled);
    const portal = usePortalTarget().target;
  
    if (portalTargets && portalTargets !== portal) {
      return <></>
    }
    
    validate = disabled ? void 0 : validate;

    return (
      <div className={classes.root}>
        <NullableBooleanInput
          ref={ref}
          {...sanitizeProps(props)}
          validate={validate}
          disabled={disabled}
          label={props.label || ""}
          className={`${classes.booleanCustom} ${props.className}`}
        />
        {props.infoBulle && (
          <InfoBulle position={props.infoBullePosition} mt="0.5em">
            {props.infoBulle}
          </InfoBulle>
        )}
      </div>
    );
  }
);
