import React, { memo, useEffect, useRef } from "react";
import { useField } from "react-final-form";
import { ArrayInput, required } from "react-admin";
import { Box, Typography, Divider } from "@material-ui/core";
import NumberInputCustom from "../../components/input/NumberInputCustom";
import TranslatableTextInputCustom from "../../components/input/TranslatableTextInputCustom";
import DateInputCustom from "../../components/input/DateInputCustom";
import SelectInputCustom from "../../components/input/SelectInputCustom";
import FormIteratorCustom from "../../components/custom/FormIteratorCustom";
import InfoBulle from "../../components/InfoBulle";
import TranslationInformation from "../../components/TranslationInformation";

import { Enum } from "../../utils";
import { Langue } from "../../lib/langue";
import { useLangue } from "../../candidatures/transalations";

const HierarchicalLevel = Enum({
  Senior: "senior",
  High: "high",
  Mangerial: "managerial",
  Other: "other",
});

const HIERARCHICAL_LEVEL_CHOICES = {
  [Langue.Fr]: [
    { id: HierarchicalLevel.Senior, name: "Poste de très haut niveau" },
    { id: HierarchicalLevel.High, name: "Poste de haut niveau" },
    { id: HierarchicalLevel.Mangerial, name: "Autre poste de direction" },
    { id: HierarchicalLevel.Other, name: "Autre" },
  ],
  [Langue.En]: [
    { id: HierarchicalLevel.Senior, name: "Senior" },
    { id: HierarchicalLevel.High, name: "High" },
    { id: HierarchicalLevel.Mangerial, name: "Managerial" },
    { id: HierarchicalLevel.Other, name: "Other" },
  ],
};

const Experience = memo(({ name, tabName, disabled }) => {
  const { input: dateFin } = useField(`${name}.dateFin`);
  const { t, langue } = useLangue();

  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) return;

    if (dateFin) {
      dateFin.onBlur();
    }

    mounted.current = true;
  }, [dateFin]);

  return (
    <Box style={{ maxWidth: "49em" }}>
      <TranslatableTextInputCustom
        disabled={disabled}
        label={t("Fonction/Mandat")}
        source={`${name}.fonction`}
        id={`${tabName}#${name}.fonction`}
        validate={required()}
      />
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        label={t("Domaines d’expertise et principales responsabilités")}
        source={`${name}.domainesExpertise`}
        id={`${tabName}#${name}.domainesExpertise`}
        fullWidth
      />
      <Box display="flex">
        <Box>
          <SelectInputCustom
            disabled={disabled}
            label={t("Rang/Niveau hiérarchique du poste")}
            source={`${name}.niveauHierarchique`}
            id={`${tabName}#${name}.niveauHierarchique`}
            choices={HIERARCHICAL_LEVEL_CHOICES[langue]}
          />
        </Box>
        <Box ml="1em">
          <TranslatableTextInputCustom
            disabled={disabled}
            label={t("Organisation, entreprise, etc.")}
            source={`${name}.organisation`}
            id={`${tabName}#${name}.organisation`}
            helperText="dans laquelle le mandat est exercé"
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box>
          <TranslatableTextInputCustom
            disabled={disabled}
            label={t("Groupe d’appartenance")}
            source={`${name}.groupeAppartenance`}
            id={`${tabName}#${name}.groupeAppartenance`}
            helperText="ACPR uniquement"
          />
        </Box>
        <Box ml="1em">
          <NumberInputCustom
            disabled={disabled}
            label={t("Pourcentage de détention")}
            source={`${name}.pourcentageDetention`}
            id={`${tabName}#${name}.pourcentageDetention`}
            helperText="ACPR uniquement"
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box>
          <TranslatableTextInputCustom
            disabled={disabled}
            label={t("Forme Juridique")}
            source={`${name}.formeJuridique`}
            id={`${tabName}#${name}.formeJuridique`}
            helperText="ACPR uniquement"
          />
        </Box>
        <Box ml="1em">
          <TranslatableTextInputCustom
            disabled={disabled}
            label={t("Pays")}
            source={`${name}.paysEntite`}
            id={`${tabName}#${name}.paysEntite`}
            helperText="ACPR uniquement"
          />
        </Box>
      </Box>
      <Box display="flex" width="100%">
        <Box width="100%">
          <TranslatableTextInputCustom
            disabled={disabled}
            label={t("Domaines d'activité de l'entité")}
            source={`${name}.activiteEntreprise`}
            id={`${tabName}#${name}.activiteEntreprise`}
            fullWidth
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box display="flex">
          <TranslatableTextInputCustom
            disabled={disabled}
            label={t("Taille")}
            helperText="en millions d’euros"
            source={`${name}.taille`}
            id={`${tabName}#${name}.taille`}
          />
          <InfoBulle position="right" mt="0.5em" ml="0.5em">
            Est entendu par taille: total de bilan (social ou, s’il existe,
            consolidé), chiffre d’affaires, résultat net, nombre d’employés,
            présence géographique…
          </InfoBulle>
        </Box>
        <Box ml="1em" width="32em">
          <NumberInputCustom
            disabled={disabled}
            style={{ minWidth: "24em" }}
            label={t("Nombre d’employés placés sous sa responsabilité")}
            source={`${name}.nombreEmployes`}
            id={`${tabName}#${name}.nombreEmployes`}
          />
        </Box>
      </Box>

      <Box display="flex">
        <Box>
          <DateInputCustom
            disabled={disabled}
            label={t("Date de début")}
            source={`${name}.dateDebut`}
          />
        </Box>
        <Box ml="1em">
          <DateInputCustom
            disabled={disabled}
            id={`${tabName}#${name}.dateFin`}
            label={t("Date de fin")}
            source={`${name}.dateFin`}
            validate={(value) => {
              try {
                /* eslint-disable-next-line no-eval */
                const dateDebut = eval(`allValues?.${name}?.dateDebut`);

                return (
                  dateDebut > value &&
                  "La date de fin doit être postérieure à la date de début"
                );
              } catch (_) {
                return;
              }
            }}
          />
        </Box>
        <InfoBulle position="right" mt="0.5em" ml="0.5em">
          Si le jour et le mois ne sont pas connus, il est convenu d’écrire
          01/01/AAAA
        </InfoBulle>
      </Box>

      <Box display="flex">
        <TranslatableTextInputCustom
          disabled={disabled}
          multiline
          label={t("Motif de la fin du contrat/mandat")}
          source={`${name}.motifFin`}
          id={`${tabName}#${name}.motifFin`}
          fullWidth
          helperText="ACPR uniquement"
        />
        <InfoBulle position="right" mt="0.5em" ml="0.5em">
          Dans le cas où le contrat/mandat est toujours en cours, il convient
          d'indiquer «&#x202F;en cours&#x202F;» dans le champs motif de fin de
          contrat/mandat.
        </InfoBulle>
      </Box>
    </Box>
  );
});

export default memo(({ tabName, disabled }) => {
  const { t } = useLangue();
  return (
    <Box>
      <TranslationInformation />
      <Box mt="1em" />

      <Typography variant="h6">
        {t(
          "Expériences dans le domaine bancaire/financier au cours des dix dernières années :"
        )}
      </Typography>
      <ArrayInput
        source="candidatExperienceBancaire"
        label=""
        disabled={disabled}
      >
        <FormIteratorCustom
          component={<Experience tabName={tabName} disabled={disabled} />}
        />
      </ArrayInput>
      <Divider />

      <Box mt="1em" />

      <Box display="flex">
        <Typography variant="h6">
          {t(
            "Autres expériences pertinentes hors du secteur financier (ex. : postes universitaires, mandats politiques, autres mandats commerciaux ou autre expériences spécialisées, etc.) :"
          )}
        </Typography>
      </Box>
      <ArrayInput
        source={t("candidatExperienceAutre")}
        label=""
        disabled={disabled}
      >
        <FormIteratorCustom
          disabledTOTO={disabled}
          component={<Experience tabName={tabName} disabled={disabled} />}
        />
      </ArrayInput>
      <Divider />

      <Box mt="1em" />
      <Box display="flex">
        <TranslatableTextInputCustom
          disabled={disabled}
          multiline
          label={t("Autres experiences specifiques")}
          source="experiencesAutre"
          id={`${tabName}#experiencesAutre`}
          fullWidth
        />
        <InfoBulle position="right" mt="0.5em" ml="0.5em">
          Il s’agit de renseigner les compétences transversales découlant de
          l’ensemble des expériences professionnelles du candidat
        </InfoBulle>
      </Box>
    </Box>
  );
});
