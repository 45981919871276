import React from "react";
import ss from "sourcemapped-stacktrace";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const { REACT_APP_API_URL } = process.env;

function mapStackTrace(error) {
  return new Promise((resolve) => {
    ss.mapStackTrace(error.stack || "", (stack) => {
      resolve({
        message: error.message,
        stack: stack.join("\n"),
      });
    });
  });
}

/**
 * @param {Error} error
 */
export async function reportError(error) {
  const mappedError = await mapStackTrace(error);

  const errorReport = {
    service: "back-office",
    url: window.location.href,
    error: mappedError,
  };

  const body = JSON.stringify(errorReport);

  const response = await fetch(`${REACT_APP_API_URL}/report-error`, {
    method: "POST",
    headers: {
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/json; charset=utf-8",
      "Content-Length": body.length,
    },
    body: body,
  });

  if (response.status !== 200) {
    const text = await response.text();

    try {
      const { message, error } = JSON.parse(text);

      if (message) {
        console.error(message);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error: error,
    };
  }

  componentDidCatch(error, errorInfo) {
    // if (errorInfo?.componentStack) {
    //   error.message += errorInfo.componentStack;
    // }

    console.error("error caught by error boundary");

    reportError(error);
  }

  render() {
    const { hasError, error } = this.state;

    if (!hasError) {
      return this.props.children || null;
    }

    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
        pt="5em"
        style={{ opacity: 0.5 }}
      >
        <Typography variant="h4" gutterBottom>
          <strong>Une erreur est survenue</strong>
        </Typography>

        <Typography variant="body1" gutterBottom>
          Notre équipe en a été informée et mettra tout en œuvre pour résoudre
          le problème au plus vite.
        </Typography>

        <Typography variant="body2">
          Message de l’erreur&nbsp;: {error.message.split("\n")[0]}
        </Typography>
      </Box>
    );
  }
}
