import React, { memo } from "react";
import { ArrayInput, required } from "react-admin";
import { Box } from "@material-ui/core";
import TranslatableTextInputCustom from "../../components/input/TranslatableTextInputCustom";
import DateInputCustom from "../../components/input/DateInputCustom";
import SelectInputCustom from "../../components/input/SelectInputCustom";
import FormIteratorCustom from "../../components/custom/FormIteratorCustom";
import InfoBulle from "../../components/InfoBulle";
import TranslationInformation from "../../components/TranslationInformation";

import { Enum } from "../../utils.js";
import { Langue } from "../../lib/langue";
import { useLangue } from "../../candidatures/transalations";

const NiveauDiplomes = Enum({
  Bachelor: "bachelor",
  Master: "master",
  Phd: "phd",
  Certificate: "certificate",
  Other: "other",
});

const NIVEAU_CHOICES = {
  [Langue.En]: [
    { id: NiveauDiplomes.Bachelor, name: "Bachelor" },
    { id: NiveauDiplomes.Master, name: "Master" },
    { id: NiveauDiplomes.Phd, name: "Phd" },
    { id: NiveauDiplomes.Certificate, name: "Certificate" },
    { id: NiveauDiplomes.Other, name: "Other" },
  ],
  [Langue.Fr]: [
    { id: NiveauDiplomes.Bachelor, name: "Licence" },
    { id: NiveauDiplomes.Master, name: "Master" },
    { id: NiveauDiplomes.Phd, name: "Doctorat" },
    { id: NiveauDiplomes.Certificate, name: "Certificat" },
    { id: NiveauDiplomes.Other, name: "Autre" },
  ],
};

const Diplome = ({ name, tabName, disabled }) => {
  const { t, langue } = useLangue();

  return (
    <Box>
      <Box display="flex">
        <Box display="flex">
          <TranslatableTextInputCustom
            disabled={disabled}
            label={t("Diplôme")}
            source={`${name}.nom`}
            id={`${tabName}#${name}.nom`}
            validate={required()}
          />
          <InfoBulle position="right" width="2em" mt="0.5em" ml="0.5em">
            En l’absence de diplôme, inscrire «&#x202F;Autodidacte&#x202F;»
          </InfoBulle>
        </Box>
        <Box ml="1em" display="flex">
          <SelectInputCustom
            disabled={disabled}
            label={t("Niveau d’étude atteint")}
            source={`${name}.niveau`}
            choices={NIVEAU_CHOICES[langue]}
          />
        </Box>
      </Box>

      <Box display="flex">
        <Box>
          <TranslatableTextInputCustom
            disabled={disabled}
            label={t("Domaine d’étude")}
            source={`${name}.domaineEtude`}
          />
        </Box>
        <Box ml="1em" display="flex">
          <DateInputCustom
            disabled={disabled}
            width="10em"
            label={t("Date d'obtention")}
            source={`${name}.dateObtention`}
          />
          <InfoBulle width="15em" mt="0.5em">
            Si le jour et le mois ne sont pas connus, il est convenu d’écrire
            01/01/AAAA
          </InfoBulle>
        </Box>
        <Box ml="1em">
          <TranslatableTextInputCustom
            disabled={disabled}
            label={t("Type d’établissement")}
            source={`${name}.typeEtablissement`}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default memo((props) => {
  const { disabled } = props;
  return (
    <Box>
      <TranslationInformation />
      <ArrayInput source="diplomes" label="">
        <FormIteratorCustom
          disabled={disabled}
          component={<Diplome {...props} />}
        />
      </ArrayInput>
    </Box>
  );
});
