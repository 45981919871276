import React from "react";
import { Typography, Box } from "@material-ui/core";
import FileInputCustom from "../../components/input/FileInputCustom";

export default ({ disabled }) => {
  return (
    <Box maxWidth="52em">
      <Typography variant="h6" gutterBottom>
        CV
      </Typography>
      <FileInputCustom
        source="cv"
        label=""
        disabled={disabled}
        multiple={false}
      />

      <Typography variant="h6" gutterBottom>
        Pièces d'identité
      </Typography>
      <FileInputCustom
        source="pieceIdentite"
        label=""
        disabled={disabled}
        multiple={true}
      />
    </Box>
  );
};
