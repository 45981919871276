import { AutocompleteInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useOnlyForTargets, usePortalTarget } from "../../candidatures/PortalTarget";

const useStyles = makeStyles({
  container: {},
  textCustom: (props) => ({
    minWidth: props.width || "18em",
  }),
});

class AutocompleteErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  static getDerivedStateFrom(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    error.message = `AutocompleteInput: ${error.message}\nerrorInfo`;
    console.error(error);
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    return (
      <>
        {error === null ? (
          children
        ) : (
          <div className="error">Une erreur est survenue</div>
        )}
      </>
    );
  }
}

export default React.forwardRef(({ portalTargets, ...props }, ref) => {
  const classes = useStyles(props);
  const disabled = useOnlyForTargets(portalTargets, props.disabled);
  const portal = usePortalTarget().target;
  
  if (portalTargets && portalTargets !== portal) {
    return <></>
  }

  return (
    <AutocompleteErrorBoundary>
      <AutocompleteInput
        ref={ref}
        {...props}
        disabled={disabled}
        label={props.label || " "}
        className={`${classes.textCustom} ${props.className}`}
      />
    </AutocompleteErrorBoundary>
  );
});
