import { useEffect, useState, useCallback, useRef } from "react";
import { useReference } from "react-admin";

import { useField } from "react-final-form";

import { FONCTIONS } from "../../fonctionNames";
import { cachedRemotelyComputeDedicatedDays } from "../helper";

import { Langue } from "../../../lib/langue";

const ENTITE_WITH_REUNIONS_DIRIGEANTS = ["BPCE", "Natixis", "BP", "CE"];

/**
 * @param {string} name
 * @return {[string, (event: string) => void]}
 */
export function useFieldState(name) {
  const {
    input: { value, onChange },
  } = useField(name);

  return [value, onChange];
}

export function useEntite(name, isPrimary) {
  const [entiteId] = useFieldState(`${name}.entiteId`);
  // const [_entite] = useFieldState(`${name}.entite`);

  const { referenceRecord: _entite } = useReference({
    reference: "entites",
    id: entiteId,
  });

  const { referenceRecord: plancher } = useReference({
    reference: "planchers",
    id: _entite?.plancherId,
  });

  /* eslint-disable-next-line no-unused-vars */
  const [_, setCandidatureEntiteId] = useFieldState("entiteId");

  const [entite, setEntite] = useState(_entite);

  useEffect(() => {
    if (isPrimary) {
      setCandidatureEntiteId(entiteId);
    }
  }, [entiteId, isPrimary, setCandidatureEntiteId]);

  useEffect(() => {
    if (_entite && plancher && _entite.plancher?.id !== plancher?.id) {
      setEntite({
        ..._entite,
        plancher,
      });
    } else {
      setEntite(_entite);
    }
  }, [_entite, plancher]);

  return [entite, entiteId];
}

export function isBPCE(entite) {
  return (
    entite?.denominationSociale === "BPCE" ||
    entite?.denominationSociale === "NATIXIS"
  );
}

export function canHaveReunionsDirigeants(entite) {
  return ENTITE_WITH_REUNIONS_DIRIGEANTS.includes(entite?.plancher?.nom);
}

export function useFonction(name, langue = Langue.Fr) {
  /* eslint-disable-next-line no-unused-vars */
  const [_intituleFonction, setIntituleFonction] = useFieldState(
    `${name}.intituleFonction`
  );

  const [fonctionId] = useFieldState(`${name}.fonctionId`);
  const [typeFonction, setTypeFonction] = useFieldState(`${name}.typeFonction`);

  const fonction = FONCTIONS[fonctionId];
  let fonctionName = "",
    fonctionType = "";
  if (fonction) {
    fonctionType = fonction.type;
    if (fonction.name[langue]) {
      fonctionName = fonction.name[langue];
    }
  }

  useEffect(() => {
    if (fonctionName && fonctionId !== "autre") {
      setIntituleFonction("");
      setTypeFonction(fonctionType);
    }
  }, [
    fonctionId,
    fonctionName,
    fonctionType,
    setIntituleFonction,
    setTypeFonction,
  ]);

  return {
    id: fonctionId,
    name: fonctionName,
    type: typeFonction,
    canBeIndependant: fonction ? fonction.canBeIndependant : undefined,
    isDirigeant: fonction ? fonction.isDirigeant : undefined,
    setIntituleFonction,
  };
}

export function isPresidentConseilSurveillance(fonction) {
  return fonction.id === "president-du-conseil-de-surveillance";
}

export function isMembreConseilSurveillance(fonction) {
  return fonction.id === "membre-du-conseil-de-surveillance";
}

export function canBeIndependant(fonction) {
  return fonction && fonction.canBeIndependant === true;
}

export function isDirigeant(fonctionId) {
  let fonction = FONCTIONS[fonctionId];
  if (fonction) {
    return fonction.isDirigeant;
  }
  return false;
}

export function useMembreIndependant(name) {
  const [membreIndependant] = useFieldState(`${name}.membreIndependant`);
  const isMembreIndependant =
    Array.isArray(membreIndependant) &&
    membreIndependant.includes("membre-independant");

  return [membreIndependant, isMembreIndependant];
}

export function useFinMandat(name) {
  const [finIndeterminee] = useFieldState(`${name}.finIndeterminee`);
  const [finMandat, setFinMandat] = useFieldState(`${name}.finMandat`);

  useEffect(() => {
    try {
      const isoFinMandat = finMandat;

      if (
        Array.isArray(finIndeterminee) &&
        finIndeterminee.includes("indeterminee") &&
        !isoFinMandat.startsWith("2999")
      ) {
        const finMandat = new Date("2999-12-31T23:59:59");

        setFinMandat(finMandat.toISOString());
      }
    } catch (e) {
      console.warn(e);
      // ignorable error
    }
  }, [finIndeterminee, finMandat, setFinMandat]);

  return finIndeterminee;
}

export function useDedicatedDays(
  isPrimary,
  candidatureId,
  entite,
  fonction,
  responsabilitesSupplementaires,
  nbJoursParAn,
  nbJoursReunionsDirigeantsParAn,
  membreIndependant,
  useJoursParAnManuels,
  joursParAnManuels,
  daysOfFormationCount
) {
  const sending = useRef();
  const dedicatedDaysParams = useRef();
  const [dDays, setDDays] = useState(0);

  const handleDayUpdate = useCallback(() => {
    if (useJoursParAnManuels) {
      setDDays(joursParAnManuels);
      return;
    }
    let jours = parseFloat(nbJoursParAn || 0, 10);

    const params = {
      entiteId: entite?.id,
      fonctionId: fonction.id,
      responsabilitesSupplementaires,
      nbJoursParAn: jours,
      typeFonction: fonction.type,
      membreIndependant,
      nbJoursReunionsDirigeantsParAn,
    };

    const paramsSerialized = JSON.stringify(params);

    if (paramsSerialized === dedicatedDaysParams.current) return;
    dedicatedDaysParams.current = paramsSerialized;

    if (sending.current !== undefined) {
      clearTimeout(sending.current);
    }

    sending.current = setTimeout(async () => {
      sending.current = true;

      let result = await cachedRemotelyComputeDedicatedDays(
        candidatureId,
        params
      );

      if (result < 0) {
        result = 0;
      }

      // if (isPrimary) {
      //   result += parseFloat(daysOfFormationCount || 0, 10);
      // }

      setDDays(result);
      sending.current = false;
    }, 500);
  }, [
    useJoursParAnManuels,
    nbJoursParAn,
    nbJoursReunionsDirigeantsParAn,
    entite,
    fonction.id,
    fonction.type,
    responsabilitesSupplementaires,
    membreIndependant,
    joursParAnManuels,
    candidatureId,
  ]);

  return [dDays, handleDayUpdate];
}
