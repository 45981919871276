import React from "react";
import Box from "@material-ui/core/Box";

import Typography from "../../../components/custom/TypographyCustom";
import TextInputCustom from "../../../components/input/TextInputCustom";
import NumberInputCustom from "../../../components/input/NumberInputCustom";

import { useLangue } from "../../transalations";

export default function Actionnaire({ tabName, disabled, ...rest }) {
  const { t } = useLangue();
  return (
    <Box>
      <Typography variant="subtitle1">
        {t("Veuillez fournir les informations suivantes :")}
      </Typography>
      <Box display="flex">
        <Box>
          <TextInputCustom
            disabled={disabled}
            label={t("Nom de l'actionnaire")}
            source="representeActionnaireNom"
            id={`${tabName}#representeActionnaireNom`}
            {...rest}
          />
        </Box>
      </Box>
      <Box>
        <NumberInputCustom
          fullWidth
          disabled={disabled}
          label={t("Pourcentage de participation (capital et droits de vote)")}
          source="representeActionnaireParticipation"
          id={`${tabName}#representeActionnaireParticipation`}
          {...rest}
        />
      </Box>
      <TextInputCustom
        disabled={disabled}
        multiline
        label={t("Nature de la représentation")}
        source="representeActionnaireNature"
        id={`${tabName}#representeActionnaireNature`}
        {...rest}
        fullWidth
      />
    </Box>
  );
}

Actionnaire.clear = function (formData) {
  formData.representeActionnaireNom = null;
  formData.representeActionnaireParticipation = null;
  formData.representeActionnaireNature = null;
};
