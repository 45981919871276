import React from "react";
import Alert from "@material-ui/lab/Alert";
import { FormDataConsumer } from "react-admin";

export default function TranslationInformation() {
  return (
    <FormDataConsumer>
    {({formData: record}) => (record.multilingue && (
    <Alert severity="warning">
      La traduction est à la main des correspondants Fit & Proper.
    </Alert>))}
    </FormDataConsumer>
  );
}
