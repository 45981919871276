import React, { cloneElement } from "react";
// import { Button, useNotify } from "react-admin";
// import CheckIcon from "@material-ui/icons/Check";
// import GetAppIcon from "@material-ui/icons/GetApp";
// import { downloadBlob } from "../utils";

import { TopToolbar, useListContext, sanitizeListRestProps } from "react-admin";

import CandidatureCreateButton from "./CandidatureCreateButton";
// import { authedFetch } from "../authProvider";
// const { REACT_APP_API_URL } = process.env;

// async function exportCandidatures(candidatureIds) {
//   try {
//     const response = await authedFetch(
//       `${REACT_APP_API_URL}/candidatures/export/csv`,
//       {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         // body: JSON.stringify({ candidatureIds }),
//       }
//     );
//
//     if (response.status !== 200) {
//       throw new Error(
//         `L'export des KPI n'a pas fonctionné' : ${response.status}`
//       );
//     }
//
//     const blob = await response.blob();
//
//     downloadBlob(blob, "KPI.zip");
//   } catch (err) {}
// }

// const ExportBtn = () => {
//   return (
//     <Button
//       color="primary"
//       size="small"
//       onClick={() => {
//         exportCandidatures();
//       }}
//     >
//       <>
//         <GetAppIcon size="small" />
//         Exporter KPI
//       </>
//     </Button>
//   );
// };

export default function CandidatureListToolbar(props) {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {props.filters &&
        cloneElement(props.filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {/* <ExportBtn /> */}
      <CandidatureCreateButton />
    </TopToolbar>
  );
}
