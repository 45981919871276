import React, { memo } from "react";
import Box from "@material-ui/core/Box";

import Typography from "../../components/custom/TypographyCustom";

import { FormDataConsumer, required } from "react-admin";
import { useFormState } from "react-final-form";
import TextInputCustom from "../../components/input/TextInputCustom";
import TranslatableTextInputCustom from "../../components/input/TextInputCustom";
import NullableBooleanInputCustom from "../../components/input/NullableBooleanInputCustom";
import SelectInputCustom from "../../components/input/SelectInputCustom";
import InfoBulle from "../../components/InfoBulle";
import { useGlobalDisabled } from "./helper";
import { useLangue } from "../transalations";
import { Langue } from "../../lib/langue";
import TypographyCustom from "../../components/custom/TypographyCustom";
import { usePortalTarget } from "../PortalTarget";

const EVALUATION_CHOICES_IMAS = {
  [Langue.Fr]: [
    { id: "significative", name: "Significative" },
    { id: "moyennementSignificative", name: "Moyennement significative" },
    { id: "peuSignificative", name: "Peu significative" },
  ],
  [Langue.En]: [
    { id: "significative", name: "High" },
    { id: "moyennementSignificative", name: "Medium" },
    { id: "peuSignificative", name: "Low" },
  ],
};

const EVALUATION_CHOICES_ACPR = {
  [Langue.Fr]: [
    { id: "significative", name: "Significative" },
    { id: "moyennementSignificative", name: "Moyennement significative" },
    { id: "peuSignificative", name: "Peu significative" },
    { id: "nonSignificative", name: "Non significative" },
  ],
  [Langue.En]: [
    { id: "significative", name: "High" },
    { id: "moyennementSignificative", name: "Medium" },
    { id: "peuSignificative", name: "Low" },
    { id: "nonSignificative", name: "Not significant" },
  ],
};
/**
 * @todo translate
 */
export default memo((props) => {
  const { tabName } = props;

  const disabled = useGlobalDisabled();
  const { values: record } = useFormState({ subscription: { values: true } });
  const portalTarget = usePortalTarget().target;

  const { t, langue } = useLangue();
  const evaluationChoices =
    record.portalTargets === "imas"
      ? EVALUATION_CHOICES_IMAS[langue]
      : EVALUATION_CHOICES_ACPR[langue];

  return (
    <Box maxWidth="72em" key={portalTarget}>
      {/* START ACPR-ONLY */}
      <Typography variant="subtitle1" portalTargets="acpr">
        {t(
          "Pour apprécier le niveau d’expérience de la personne nommée (renouvelée) sur la base des réponses apportées aux questions posées dans la section Expériences de la partie Candidat, il sera tenu compte des critères suivants, pour lesquels il convient d’apporter toutes les précisions nécessaires :"
        )}
        <ul>
          <li>
            {t(
              "la nature du poste à responsabilité et le niveau hiérarchique correspondant;"
            )}
          </li>
          <li>
            {t(
              "la nature et la complexité de l’entreprise, y compris en raison de sa structure organisationnelle;"
            )}
          </li>
          <li>
            {t(
              "l’étendue des compétences, pouvoirs décisionnaires et responsabilités, et le nombre de subordonnés."
            )}
          </li>
        </ul>
        {t(
          "Vous pouvez ajouter à ces différents égards, des informations complémentaires :"
        )}
      </Typography>
      <TextInputCustom
        id={`${tabName}#infoComplementairesExperience`}
        portalTargets="acpr"
        disabled={disabled}
        multiline
        source="infoComplementairesExperience"
        fullWidth
      />
      <Typography variant="subtitle1" portalTargets="acpr">
        {t(
          "Si la personne nommée ne dispose pas de l’expérience nécessaire, veuillez indiquer ci-dessous tout autre facteur à prendre en considération qui serait de nature à compenser le cas échéant le manque d’expérience requise, par exemple :"
        )}
        <ul>
          <li>{t(`taille de l’entité;`)}</li>
          <li>{t(`autre expérience alternative;`)}</li>
          <li>{t(`diplôme/formation universitaire;`)}</li>
          <li>{t(`capacité d’analyse et de jugement confirmée;`)}</li>
          <li>
            {t(
              `compétence collective des membres de l’organe de direction déjà présents;`
            )}
          </li>
          <li>
            {t(`connaissances spécialisées dont a besoin l’établissement;`)}
          </li>
          <li>
            {t(
              `périmètre de tâches limité de la personne nommée (renouvelée);`
            )}
          </li>
          <li>
            {t(
              `autres cas particuliers (ex : exigence légale de nommer des représentants du personnel au sein de l’organe de direction).`
            )}
          </li>
        </ul>
      </Typography>
      <TextInputCustom
        portalTargets="acpr"
        id={`${tabName}#defautExperience`}
        disabled={disabled}
        multiline
        source="defautExperience"
        fullWidth
      />
      {/*
       *
       */}
      <Typography variant="subtitle1" portalTargets="imas">
        La personne nommée atteint-elle les seuils ?
      </Typography>
      <NullableBooleanInputCustom
        id={`${tabName}#exigenceExperiencesAdequates`}
        portalTargets="imas"
        disabled={disabled}
        source="exigenceExperiencesAdequates"
      />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => {
          if (false === formData.exigenceExperiencesAdequates) {
            return (
              <>
                <Typography variant="subtitle1" portalTargets="imas">
                  <Box display="flex">
                    <span>
                      {t(
                        "Veuillez répertorier ci-dessous tout facteur complémentaire (ou compensatoire), comme le prévoit le Guide relatif à l’évaluation de l’honorabilité, des connaissances, des compétences et de l’expérience."
                      )}
                    </span>
                    <InfoBulle position="right">
                      Par exemple&nbsp;:
                      <br />
                      la participation à des missions spécifiques&#x202F;;
                      <br />
                      taille de l’entité&#x202F;;
                      <br />
                      autre expérience alternative&#x202F;;
                      <br />
                      diplôme/formation universitaire&#x202F;;
                      <br />
                      capacité d’analyse et de jugement confirmée&#x202F;;{" "}
                      <br />
                      compétence collective des membres de l’organe de direction
                      déjà présents&#x202F;; <br />
                      connaissances spécialisées dont a besoin
                      l’établissement&#x202F;; <br />
                      périmètre de tâches limité de la personne nommée
                      (renouvelée)&#x202F;; <br />
                      autres cas particuliers (ex&nbsp;: exigence légale de
                      nommer des représentants du personnel au sein de l’organe
                      de direction)
                    </InfoBulle>
                  </Box>
                </Typography>
                <TranslatableTextInputCustom
                  id={`${tabName}#exigenceExperiencesAdequatesText`}
                  disabled={disabled}
                  multiline
                  source="exigenceExperiencesAdequatesText"
                  fullWidth
                  portalTargets="imas"
                  {...rest}
                />
              </>
            );
          } else if (formData.exigenceExperiencesAdequates) {
            formData.exigenceExperiencesAdequatesText = null;
          }
        }}
      </FormDataConsumer>
      {/* END ACPR-ONLY */}
      
      <Typography variant="h6" gutterBottom portalTargets="acpr">
        {t("Domaines requis d'expérience générale dans le secteur bancaire et financier: Évaluation (significative, moyennement significative, peu significative, non significative) au regard, notamment, de la durée de l'expérience acquise, de la taille, de la complexité de l'entreprise au sein de laquelle cette expérience a été acquise")} 
      </Typography>
      <Typography variant="h6" gutterBottom portalTargets="imas">
      {t(
        "Expérience générale dans le domaine bancaire, y compris, entre autres, dans les champs définis dans les orientations EBA/GL/2021/06"
        )}
      </Typography>
      {/**
      *
    */}

      <Typography variant="subtitle1" portalTargets="acpr">
        Marchés bancaires ou financiers :
      </Typography>
      <Typography variant="subtitle1" portalTargets="imas">
        Marchés bancaires et marchés financiers :
      </Typography>
      <SelectInputCustom
        id={`${tabName}#evalMarcheBancaire`}
        disabled={disabled}
        source="evalMarcheBancaire"
        choices={evaluationChoices}
        validate={portalTarget === 'imas' ? required(): undefined}
      />
      <TextInputCustom
        disabled={disabled}
        id={`${tabName}#evalMarcheBancaireText`}
        source="evalMarcheBancaireText"
        multiline
        fullWidth
        label={portalTarget === 'imas' ? "Éléments à l'appui de votre réponse":"Justification"}
        placeholder="Justification"
        portalTargets="imas"
        validate={portalTarget === 'imas' ? required(): undefined}
      />
      {/**
       *
       */}
      <Typography variant="subtitle1" portalTargets="acpr">
        {t("Exigences légales et réglementaires applicables à un établissement assujetti notamment dans le domaine de la lutte contre le blanchiment de capitaux et le financement du terrorisme")}
      </Typography>
      <Typography variant="subtitle1" portalTargets="imas">
        {t("Exigences juridiques et cadre réglementaire")}
      </Typography>

      <SelectInputCustom
        disabled={disabled}
        source="evalExigencesLegales"
        id={`${tabName}#evalExigencesLegales`}
        choices={evaluationChoices}
        validate={portalTarget === 'imas' ? required(): null}
        />
      <TextInputCustom
        disabled={disabled}
        source="evalExigencesLegalesText"
        id={`${tabName}#evalExigencesLegalesText`}
        multiline
        fullWidth
        label={portalTarget === 'imas' ? "Éléments à l'appui de votre réponse":"Justification"}
        placeholder="Justification"
        portalTargets="imas"
        validate={portalTarget === 'imas' ? required(): null}
      />
      {/**
       *
       */}
      <Typography variant="subtitle1" portalTargets="imas">
        Lutte contre le blanchiment de capitaux et le financement du terrorisme
        :
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        source="evalBlanchiment"
        id={`${tabName}#evalBlanchiment`}
        choices={evaluationChoices}
        portalTargets="imas"
        validate={portalTarget === 'imas' ? required(): null}
      />
      <TextInputCustom
        disabled={disabled}
        source="evalBlanchimentText"
        id={`${tabName}#evalBlanchimentText`}
        multiline
        fullWidth
        label="Éléments à l'appui de votre réponse"
        placeholder="Justification"
        portalTargets="imas"
        validate={portalTarget === 'imas' ? required(): null}
      />
      {/**
       *
       */}
      
      <Typography variant="subtitle1" portalTargets="acpr">
        Planification stratégique et compréhension de la stratégie commerciale
        ou du projet d’affaires (business plan) d’un établissement assujetti et
        de sa mise en œuvre :
      </Typography>
      <Typography variant="subtitle1" portalTargets="imas">
        Planification stratégique, compréhension de la stratégie commerciale ou du plan d'activité d'un établissement de crédit et de sa mise en œuvre *
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        source="evalPlanification"
        id={`${tabName}#evalPlanification`}
        choices={evaluationChoices}
        validate={portalTarget === 'imas' ? required(): null}
      />
      <TextInputCustom
        disabled={disabled}
        source="evalPlanificationText"
        id={`${tabName}#evalPlanificationText`}
        multiline
        fullWidth
        label={portalTarget === 'imas' ? "Éléments à l'appui de votre réponse":"Justification"}
        placeholder="Justification"
        portalTargets="imas"
        validate={portalTarget === 'imas' ? required(): null}
      />

      <Typography variant="subtitle1" portalTargets="acpr">
        Gestion des risques (identification, évaluation, suivi, contrôle et atténuation des principaux types de risques d'un établissement assujetti, y compris le risque climatique)
      </Typography>
      <Typography variant="subtitle1" portalTargets="imas">
        Gestion des risques (détecter, évaluer, suivre, contrôler et atténuer les principaux types de risques auxquels un établissement de crédit est exposé)
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        source="evalGestionRisques"
        id={`${tabName}#evalGestionRisques`}
        choices={evaluationChoices}
        validate={portalTarget === 'imas' ? required(): null}
      /> 
      <TextInputCustom
        disabled={disabled}
        source="evalGestionRisquesText"
        id={`${tabName}#evalGestionRisquesText`}
        multiline
        fullWidth
        label={portalTarget === 'imas' ? "Éléments à l'appui de votre réponse":"Justification"}
        placeholder="Justification"
        portalTargets="imas"
        validate={portalTarget === 'imas' ? required(): null}
      />

      <Typography variant="subtitle1" portalTargets="imas">
        Connaissances et expérience en matière de risques liés au climat et à l’environnement :
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        source="evalRisquesEnvironementaux"
        id={`${tabName}#evalRisquesEnvironementaux`}
        portalTargets="imas"
        choices={evaluationChoices}
        validate={portalTarget === 'imas' ? required(): null}
      />
      <TextInputCustom
        disabled={disabled}
        source="evalRisquesEnvironementauxText"
        id={`${tabName}#evalRisquesEnvironementauxText`}
        multiline
        fullWidth
        label="Éléments à l'appui de votre réponse"
        placeholder="Justification"
        portalTargets="imas"
        validate={portalTarget === 'imas' ? required(): null}
      />

      <Typography variant="subtitle1" portalTargets="imas">
        Comptabilité et Audit
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        source="evalComptabiliteEtAudit"
        id={`${tabName}#evalComptabiliteEtAudit`}
        portalTargets="imas"
        choices={evaluationChoices}
        validate={portalTarget === 'imas' ? required(): null}
      />
      <TextInputCustom
        disabled={disabled}
        source="evalComptabiliteEtAuditText"
        id={`${tabName}#evalComptabiliteEtAuditText`}
        multiline
        fullWidth
        label="Éléments à l'appui de votre réponse"
        placeholder="Justification"
        portalTargets="imas"
        validate={portalTarget === 'imas' ? required(): null}
      />

      <Typography variant="subtitle1" portalTargets="acpr">
        {t(
          "Evaluation de l’efficacité des dispositifs de gouvernance d’un établissement assujetti, mise en place de mécanismes de surveillance et de contrôle efficaces de celle-ci :"
        )}
      </Typography>
      <Typography variant="subtitle1" portalTargets="imas">
        {t(
          "Évaluation de l'efficacité des dispositifs d'un établissement de crédit, garantissant une gouvernance, une surveillance et des contrôles efficaces"
        )}
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        source="evalEfficaciteDispositifs"
        id={`${tabName}#evalEfficaciteDispositifs`}
        choices={evaluationChoices}
        validate={portalTarget === 'imas' ? required(): null}
      />
      <TextInputCustom
        disabled={disabled}
        source="evalEfficaciteDispositifsText"
        id={`${tabName}#evalEfficaciteDispositifsText`}
        multiline
        fullWidth
        label={portalTarget === 'imas' ? "Éléments à l'appui de votre réponse":"Justification"}
        placeholder="Justification"
        portalTargets="imas"
        validate={portalTarget === 'imas' ? required(): null}
      />

      <Typography variant="subtitle1" portalTargets="acpr">
        Interprétation des informations comptables et financières d'un établissement assujetti, identification des problèmes majeurs révélés par cette information et mise en place des mesures et contrôles appropriés.
      </Typography>
      <Typography variant="subtitle1" portalTargets="imas">
        Interprétation des informations financières d'un établissement de crédit, identification des principaux problèmes sur la base de ces informations et contrôles et mesures appropriés
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        source="evalInfoComptableFinanciere"
        id={`${tabName}#evalInfoComptableFinanciere`}
        choices={evaluationChoices}
        validate={portalTarget === 'imas' ? required(): null}
      />
      <TextInputCustom
        disabled={disabled}
        source="evalInfoComptableFinanciereText"
        id={`${tabName}#evalInfoComptableFinanciereTe`}
        multiline
        fullWidth
        label={portalTarget === 'imas' ? "Éléments à l'appui de votre réponse":"Justification"}
        placeholder="Justification"
        portalTargets="imas"
        validate={portalTarget === 'imas' ? required(): null}
      />
      
      <TypographyCustom variant="h6" gutterBottom portalTargets="acpr">
        Autres experiences spécifiques (à détailler) :
      </TypographyCustom>
      <TextInputCustom
        disabled={disabled}
        source="autreExperienceSignificative"
        multiline
        fullWidth
        portalTargets="acpr"
      />
    </Box>
  );
});
