import React from "react";

import { Typography, Box } from "@material-ui/core";
import TranslatableTextInputCustom from "../../../components/input/TranslatableTextInputCustom";

import { useLangue } from "../../../candidatures/transalations";

function Subtitle({ children, ...props }) {
  const { t } = useLangue();

  let c = typeof children === "string" ? t(children) : children;

  return (
    <Box maxWidth="72em">
      <Typography {...props}>{c}</Typography>
    </Box>
  );
}

export default function ConcerneDirectement({ disabled, tabName, ...rest }) {
  return (
    <Box ml="2em" mr="2em">
      <Box maxWidth="72em">
        <Subtitle>
          Précisez les circonstances et les raisons de votre implication directe
          :
        </Subtitle>
        <TranslatableTextInputCustom
          disabled={disabled}
          multiline
          source="infractionsACPR.concerneDirectementCirconstances"
          id={`${tabName}#infractionsACPR.concerneDirectementCirconstances`}
          fullWidth
        />
        <Subtitle>
          Décrivez les mesures que vous avez prises pour prévenir les actes
          répréhensibles et/ou éviter qu’ils soient commis
        </Subtitle>
        <TranslatableTextInputCustom
          disabled={disabled}
          multiline
          source="infractionsACPR.concerneDirectementMesuresPrises"
          id={`${tabName}#infractionsACPR.concerneDirectementMesuresPrises`}
          fullWidth
        />
        <Subtitle>
          Qu’auriez-vous pu faire de plus pour éviter ces actes répréhensibles
          présumés et avez-vous tiré des enseignements de ce qu'il s'est passé ?
        </Subtitle>
        <TranslatableTextInputCustom
          disabled={disabled}
          multiline
          source="infractionsACPR.concerneDirectementEnseignements"
          id={`${tabName}#infractionsACPR.concerneDirectementEnseignements`}
          fullWidth
        />
      </Box>
    </Box>
  );
}

ConcerneDirectement.clear = function (formData) {
  if (!formData.infractionsACPR) return;

  formData.infractionsACPR.concerneDirectementCirconstances = null;
  formData.infractionsACPR.concerneDirectementMesuresPrises = null;
  formData.infractionsACPR.concerneDirectementEnseignements = null;
};
