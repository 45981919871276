import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";

import Typography from "../../../components/custom/TypographyCustom";
import TextInputCustom from "../../../components/input/TextInputCustom";

import { useLangue } from "../../transalations";
import { usePortalTarget, IsACPR } from "../../PortalTarget";

export default function LienFinancier({ name, tabName, disabled }) {
  const { t } = useLangue();
  const portalTarget = usePortalTarget().target;

  return (
    <Box key={portalTarget}>
      <Box display="flex">
          <TextInputCustom
            label={IsACPR() ? t("Nom de l'entreprise") : t("Nom de l'entité")}
            source={`${name}.nomEntreprise`}
            id={`${tabName}#${name}.nomEntreprise`}
            disabled={disabled}
          />
        <Box ml="1em">
          <TextInputCustom
            label={IsACPR() ? t("Nature de ses activités") : t("Principales activités de l’entité")}
            source={`${name}.activitesEntreprise`}
            id={`${tabName}#${name}.activitesEntreprise`}
            disabled={disabled}
          />
        </Box>
      </Box>
      {!IsACPR() && (
        <>
          <Typography variant="subtitle1">
            {t(
              "Type de relation entre l’entité et l’entité soumise à la surveillance prudentielle"
            )}
          </Typography>
          <TextInputCustom
            disabled={disabled}
            multiline
            source={`${name}.lien`}
            id={`${tabName}#${name}.lien`}
            fullWidth
          />
        </>
      )}
      {IsACPR() && (
        <>
          <Box>
            <Typography variant="subtitle1">
              {t(
                "Niveau de la participation financière (% du capital et des droits de vote ou valeur d’investissement)"
              )}
            </Typography>
          </Box>
          <TextInputCustom
            disabled={disabled}
            source={`${name}.interetFinancier`}
            id={`${tabName}#${name}.interetFinancier`}
          />
        </>
      )}
      <Box>
        <TextInputCustom
          disabled={disabled}
          portalTarget="acpr"
          label={IsACPR() ? t("Période concernée") : t("Date de début de la participation financière")}
          source={`${name}.periodeLien`}
          id={`${tabName}#${name}.periodeLien`}
        />
      </Box>
      {IsACPR() && (
        <>
          <Typography variant="subtitle1">
            {t(
              "Liens entre ces entreprises et l'entité soumise à la surveillance prudentielle, sa société mère et ses filiales"
            )}
          </Typography>
          <TextInputCustom
            disabled={disabled}
            multiline
            source={`${name}.lien`}
            id={`${tabName}#${name}.lien`}
            fullWidth
          />
          </>
      )}

      {!IsACPR() && (
        <>
          <Box>
            <Typography variant="subtitle1">
              {t(
                "Taille de la participation financière"
              )}
            </Typography>
          </Box>
          <TextInputCustom
            disabled={disabled}
            source={`${name}.interetFinancier`}
            id={`${tabName}#${name}.interetFinancier`}
          />
        </>
      )}
    </Box>
  );
}
