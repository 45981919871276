import React, { memo } from "react";
import { ArrayInput, FormDataConsumer } from "react-admin";
import Box from "@material-ui/core/Box";

import Typography from "../../components/custom/TypographyCustom";
import TextInputCustom from "../../components/input/TextInputCustom";
import SelectInputCustom from "../../components/input/SelectInputCustom";
import NullableBooleanInputCustom from "../../components/input/NullableBooleanInputCustom";
import ConditionalInput from "../../components/input/ConditionalInput";
import FormIteratorCustom from "../../components/custom/FormIteratorCustom";
import DateInputCustom from "../../components/input/DateInputCustom";
import InfoBulle from "../../components/InfoBulle";
import { useGlobalDisabled } from "./helper";
import { useLangue } from "../transalations";
import { Langue } from "../../lib/langue";

const EXIGENCES_DIVERSTE = {
  oui: {
    [Langue.Fr]: "Oui",
    [Langue.En]: "Yes",
  },
  non: {
    [Langue.Fr]: "Non",
    [Langue.En]: "No",
  },
  "absence-d-objectifs": {
    [Langue.Fr]: "Absence d’objectifs ou de règles internes",
    [Langue.En]: "No internal target exist",
  },
};

const PRINCIPES_INTERNES_MATIERE_DIVERSITE = {
  oui: {
    [Langue.Fr]: "Oui",
    [Langue.En]: "Yes",
  },
  non: {
    [Langue.Fr]: "Non",
    [Langue.En]: "No",
  },
  "absence-d-objectifs": {
    [Langue.Fr]: "Sans objet/absence de principes internes",
    [Langue.En]: "No internal target exist",
  },
};

const EXIGENCES_DIVERSITE_CHOICES = Langue.reduce((acc, langue) => {
  if (!acc[langue]) acc[langue] = [];

  Object.entries(EXIGENCES_DIVERSTE).forEach(([id, variants]) => {
    const name = variants[langue] || variants[Langue.Fr];
    acc[langue].push({ id, name });
  });

  return acc;
}, {});

const PRINCIPES_INTERNES_MATIERE_DIVERSITE_CHOICES = Langue.reduce((acc, langue) => {
  if (!acc[langue]) acc[langue] = [];

  Object.entries(PRINCIPES_INTERNES_MATIERE_DIVERSITE).forEach(([id, variants]) => {
    const name = variants[langue] || variants[Langue.Fr];
    acc[langue].push({ id, name });
  });

  return acc;
}, {});

const DateRenouvellement = ({ name }) => {
  const disabled = useGlobalDisabled();

  return (
    <Box>
      <DateInputCustom
        label="Date renouvellement"
        portalTargets="acpr"
        disabled={disabled}
        source={name}
      />
    </Box>
  );
};

const Dirigeant = ({ name, tabName }) => {
  const disabled = useGlobalDisabled();

  const { t } = useLangue();

  return (
    <Box>
      <TextInputCustom
        id={`${tabName}#${name}.nom`}
        disabled={disabled}
        label={t("Prénom, Nom")}
        source={`${name}.nom`}
      />
      <TextInputCustom
        disabled={disabled}
        multiline
        label={t("Fonctions")}
        source={`${name}.fonctions`}
        id={`${tabName}#${name}.fonctions`}
        fullWidth
      />
      <TextInputCustom
        disabled={disabled}
        multiline
        portalTargets="acpr"
        label={t("Attributions")}
        source={`${name}.attributions`}
        id={`${tabName}#${name}.attributions`}
        fullWidth
      />

      <DateInputCustom
        disabled={disabled}
        portalTargets="imas"
        label={t("Date de nomination")}
        source={`${name}.dateNominationIMAS`}
        helperText={"ou de renouvellement"}
      />
      <br />

      <DateInputCustom
        disabled={disabled}
        portalTargets="acpr"
        label={t("Date de première nomination")}
        source={`${name}.datePremiereNomination`}
      />

      <Typography variant="subtitle2" portalTargets="acpr">
        Dates de renouvellement
      </Typography>

      <ArrayInput source={`${name}.dateRenouvellements`} label="">
        <FormIteratorCustom
          disabled={disabled}
          portalTargets="acpr"
          component={<DateRenouvellement />}
        />
      </ArrayInput>

      <TextInputCustom
        disabled={disabled}
        portalTargets="acpr"
        label={t("Durée du mandat en cours")}
        source={`${name}.dureeMandatEnCours`}
        id={`${tabName}#${name}.dureeMandatEnCours`}
      />
      <TextInputCustom
        disabled={disabled}
        multiline
        label={t("Formation initiale et continue")}
        portalTargets="acpr"
        source={`${name}.formation`}
        id={`${tabName}#${name}.formation`}
        fullWidth
      />
      <TextInputCustom
        disabled={disabled}
        multiline
        label={t("Expérience (bancaire/non bancaire en précisant la durée)")}
        source={`${name}.experience`}
        id={`${tabName}#${name}.experience`}
        portalTargets="acpr"
        fullWidth
      />
      <Box display="flex">
        <TextInputCustom
          disabled={disabled}
          multiline
          label={t("Compétences / principaux domaines d’expertise")}
          source={`${name}.competence`}
          id={`${tabName}#${name}.competence`}
          fullWidth
        />
        <InfoBulle position="right" ml="0.5em" mt="0.5em">
          Notamment au regard :<br />
          - Des marchés bancaires et des marchés financiers
          <br />
          - Des exigences légales et réglementaires applicables à
          l’établissement assujetti
          <br />
          - Du système de gouvernance de l’établissement
          <br />
          - Du contrôle interne de l’établissement
          <br />
          - De la planification stratégique et de sa mise en œuvre
          <br />
          - De la gestion des risques
          <br />
          - De l’information comptable et financière
          <br />
          - De la lutte contre le blanchiment de capitaux et le financement du
          terrorisme
          <br />- Des risques liés au climat et à l'environnement
        </InfoBulle>
      </Box>
    </Box>
  );
};

export default memo((props) => {
  const { tabName } = props;
  const disabled = useGlobalDisabled();
  const { t, langue } = useLangue();

  return (
    <Box maxWidth="72em">


      {/* IMAS */}
      <Box width="100%" display="flex">
        <Typography variant="subtitle1" portalTargets="imas">
          ▸ L’entité soumise à la surveillance prudentielle doit-elle respecter des exigences nationales en matière de diversité des genres ?
        </Typography>
        <InfoBulle position="right">
          Répondre « oui » quelque soit le type de candidat, car la question
          vise l’entité
        </InfoBulle>
      </Box>
      <ConditionalInput
        disabled={disabled}
        property="exigenceDiversiteRequise"
        id={`${tabName}#exigenceDiversiteRequise`}
        portalTargets="imas"
      >
        {({ disabled, ...rest }) => (
          <>
            <Typography variant="subtitle1" portalTargets="imas">
              L’entité soumise à la surveillance prudentielle respecte-t-elle les exigences nationales en matière de diversité des genres avec cette nomination?
            </Typography>
            <NullableBooleanInputCustom
              disabled={disabled}
              portalTargets="imas"
              source="exigenceDiversiteRespecteeIMAS"
              id={`${tabName}#exigenceDiversiteRespecteeIMAS`}
              {...rest}
            />
          </>
        )}
      </ConditionalInput>


      {/* ACPR */}
      <Box width="100%" display="flex">
        <Typography variant="subtitle1" portalTargets="acpr">
          ▸ L’entité soumise à la surveillance prudentielle doit-elle respecter des exigences nationales en matière de diversité des genres telles que définies par l’article L.225-18-1 du Code de commerce ?
        </Typography>
        <InfoBulle position="right">
          Répondre « oui » quelque soit le type de candidat, car la question
          vise l’entité
        </InfoBulle>
      </Box>
      <ConditionalInput
        disabled={disabled}
        property="exigenceDiversiteRequise"
        id={`${tabName}#exigenceDiversiteRequise`}
        portalTargets="acpr"
      >
        {({ disabled, ...rest }) => (
          <>
            <Typography variant="subtitle1" portalTargets="acpr">
              L'entité soumise à la surveillance prudentielle respecte-t-elle les exigences nationales en matière de diversité des genres avec cette nomination ?
            </Typography>
            <TextInputCustom
              disabled={disabled}
              portalTargets="acpr"
              multiline
              source="exigenceDiversiteRespecteeACPR"
              id={`${tabName}#exigenceDiversiteRespecteeACPR`}
              fullWidth
            />

            <Typography variant="subtitle1" portalTargets="acpr">
              Avec cette nomination, l'entité soumise à la surveillance prudentielle atteint-elle ses objectifs internes en matière de diversité, notamment des genres, au sein de l'organe de direction ?
            </Typography>
            <TextInputCustom
              disabled={disabled}
              portalTargets="acpr"
              multiline
              source="exigenceDiversiteGenresRespecteeACPR"
              id={`${tabName}#exigenceDiversiteGenresRespecteeACPR`}
              fullWidth
            />

          </>
        )}
      </ConditionalInput>









      <Typography variant="subtitle1" portalTargets="imas">
        ▸ Avec cette nomination, l’entité soumise à la surveillance prudentielle atteint-elle ses objectifs ou respecte-t-elle ses règles internes en matière de diversité des genres au sein de l’organe de direction ?
      </Typography>
      <SelectInputCustom
        portalTargets="imas"
        disabled={disabled}
        source="exigenceDiversiteNominationAtteintObjectifs"
        id={`${tabName}#exigenceDiversiteNominationAtteintObjectifs`}
        choices={EXIGENCES_DIVERSITE_CHOICES[langue]}
      />
      
      <Typography variant="subtitle1" portalTargets="imas">
        ▸ Avec cette nomination, l’entité soumise à la surveillance prudentielle respecte-t-elle ses autres principes internes en matière de diversité ?
      </Typography>
      <SelectInputCustom
        disabled={disabled}
        portalTargets="imas"
        source="exigenceDiversiteNominationAtteintObjectifsInternes"
        id={`${tabName}#exigenceDiversiteNominationAtteintObjectifsInternes`}
        choices={PRINCIPES_INTERNES_MATIERE_DIVERSITE_CHOICES[langue]}
      />
      <Typography variant="subtitle1" portalTargets="acpr">
        {langue === Langue.Fr && (
          // There doesn't seem to be a translation for this one
          <>
            ▸ Comment se situe la personne nommée (renouvelée) au regard de la compétence collective des autres membres de l’organe de direction de l’entité soumise à la surveillance prudentielle&#x202F;?
            <br />
          </>
        )}
        {t(
          "Veuillez expliquer en quoi la personne proposée complète la compétence collective de celle-ci en vous référant, le cas échéant, au résultat de la plus récente auto-évaluation de la compétence collective de l’organe de direction :"
        )}
      </Typography>
      <Typography variant="subtitle1" portalTargets="imas">
          {t(
            "Veuillez décrire la contribution de la personne nommée à l’aptitude collective de l’organe de direction. Par ailleurs, expliquez en termes généraux les faiblesses qui ont été repérées dans la composition d’ensemble de l’organe de direction et la manière dont la nomination de la personne nommée contribue à remédier à tout ou partie de ces faiblesses."
          )}
        </Typography>
      <TextInputCustom
        disabled={disabled}
        multiline
        source="competenceCollective"
        id={`${tabName}#competenceCollective`}
        fullWidth
      />
      <Typography variant="subtitle1">
        ▸ Expliquez de façon générale les faiblesses éventuellement identifiées
        dans la composition d’ensemble de l’organe de direction :
      </Typography>
      <TextInputCustom
        disabled={disabled}
        multiline
        source="faiblesses"
        id={`${tabName}#faiblesses`}
        fullWidth
      />
      <Typography variant="subtitle1">
        ▸ Comment la personne nommée contribuera-t-elle à remédier à tout ou
        partie des éventuelles faiblesses évoquées dans votre réponse à la
        question précédente ?
      </Typography>
      <TextInputCustom
        disabled={disabled}
        multiline
        source="solutionsFaiblesses"
        id={`${tabName}#solutionsFaiblesses`}
        fullWidth
      />
      <Typography variant="subtitle1">
        {t(
          "▸ Liste des personnes composant la direction effective de l’établissement (dans le cas où le mandat pour lequel la présente déclaration est réalisée, est de nature exécutive)"
        )}
        <br />
        {t("OU")}
        <br />
        {t(
          "Liste des personnes composant l’organe social de l’établissement (dans le cas où le mandat pour lequel la présente déclaration est réalisée, est de nature non exécutive)"
        )}
      </Typography>
      <ArrayInput source="dirigeants" label="">
        <FormIteratorCustom
          disabled={disabled}
          component={<Dirigeant tabName={tabName} />}
        />
      </ArrayInput>
    </Box>
  );
});
