import React, { useEffect, useMemo } from "react";
import {
  ArrayInput,
  BooleanInput,
  CheckboxGroupInput,
  FormDataConsumer,
  minValue,
  maxValue,
  required,
} from "react-admin";

import {
  useFieldState,
  useEntite,
  useFonction,
  useMembreIndependant,
  useFinMandat,
} from "./hooks";

import * as Entite from "./hooks";
import * as Fonction from "./hooks";

import { Box, Typography } from "@material-ui/core";

import { usePortalTarget } from "../../../candidatures/PortalTarget";
import FormIteratorCustom from "../../../components/custom/FormIteratorCustom";
import TextInputCustom from "../../../components/input/TextInputCustom";
import ReferenceInputCustom from "../../../components/input/ReferenceInputCustom";
import AutocompleteInputCustom from "../../../components/input/AutocompleteInputCutom";
import SelectInputCustom from "../../../components/input/SelectInputCustom";
import DateInputCustom from "../../../components/input/DateInputCustom";
import NumberInputCustom from "../../../components/input/NumberInputCustom";
import NullableBooleanInputCustom from "../../../components/input/NullableBooleanInputCustom";
import InfoBulle from "../../../components/InfoBulle";

import {
  FONCTIONS_CHOICES,
  MANDATS_CHOICES,
  RESPONSABILITES_CHOICES,
  FONCTIONS,
  TYPES_CHOICES,
  TYPES,
  DECOMPTE_PRIVILEGIE_CHOICES,
} from "../../fonctionNames";

import { useLangue } from "../../transalations";

/**
 * Returns a short ISO like date (yyyy-mm-dd) using the UTC timezone
 * @param {Date} date
 * @returns {string}
 */
function shortDate(date) {
  const dateStr = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getUTCDate().toString().padStart(2, "0")}`;

  return dateStr;
}

/**
 * Returns true if a date is not past
 * @param {string} date  The date to validate, must be parsable by `new Date()`
 * @returns {boolean}
 */
function dateNotPast(date) {
  const now = shortDate(new Date());
  const then = shortDate(new Date(date));

  if (then < now) {
    return false;
  }

  return true;
}

/**
 * Validation for the mandates end dates.
 * They must not be past dates. Null dates are valid.
 * @param {(string) => string} Translation function for error messages
 * @returns {(string) => string[]} React-Admin compatible validation function
 */
function validateMandateEndDate(t) {
  return (date) => {
    if (date && !dateNotPast(date)) {
      return [t("Le mandat ne doit pas être échu")];
    }

    return;
  };
}

export default function MandatFonction(props) {
  const {
    candidatureId,
    name,
    disabled,
    tabName,
    onDedicatedDaysChange,
    daysOfFormationCount,
    index,
  } = props;

  const { langue } = useLangue();

  /** true if this is the fonction the candidature is for */
  const isPrimary = index === 0;

  const [entite, entiteId] = useEntite(name, isPrimary);
  const isBPCE = Entite.isBPCE(entite);
  const canHaveReunionsDirigeants = Entite.canHaveReunionsDirigeants(entite);

  const fonction = useFonction(name);
  const isPresidentConseilSurveillance =
    Fonction.isPresidentConseilSurveillance(fonction);
  const isDirigeant = Fonction.isDirigeant(fonction.id);

  const [membreIndependant, isMembreIndependant] = useMembreIndependant(name);

  const finIndeterminee = useFinMandat();

  // Dedicated Days Calculations
  const [responsabilitesSupplementaires] = useFieldState(
    `${name}.responsabilitesSupplementaires`
  );

  const [nbJoursParAn] = useFieldState(`${name}.nbJoursParAn`);
  // Days on Réunions de dirigeants
  // for executive functions
  const [nbJoursReunionsDirigeantsParAn, setNbJoursReunionsDirigeantsParAn] =
    useFieldState(`${name}.nbJoursReunionsDirigeantsParAn`);

  const { target: portalTarget } = usePortalTarget();

  useEffect(() => {
    if (!Fonction.isDirigeant(fonction.id)) {
      setTimeout(() => setNbJoursReunionsDirigeantsParAn(0), 1);
    }
  }, [
    fonction,
    fonction.id,
    nbJoursReunionsDirigeantsParAn,
    setNbJoursReunionsDirigeantsParAn,
  ]);
  const [useJoursParAnManuels] = useFieldState(`${name}.useJoursParAnManuels`);
  const [joursParAnManuels] = useFieldState(`${name}.joursParAnManuels`);

  const [_finIndeterminee] = useFieldState(`${name}.finIndeterminee`);
  const [_finMandat, setFinMandat] = useFieldState(`${name}.finMandat`);
  const [_dedicatedDays, setDedicatedDays] = useFieldState(
    `${name}.dedicatedDays`
  );

  useEffect(() => {
    if (_finIndeterminee && _finIndeterminee[0] === "indeterminee") {
      setFinMandat("2199-01-01");
    }
  }, [_finIndeterminee]);

  const [dedicatedDays, updateDedicatedDays] = Entite.useDedicatedDays(
    index === 0, // isPrimary
    candidatureId,
    entite ?? entiteId ? { id: entiteId } : undefined,
    fonction,
    responsabilitesSupplementaires,
    nbJoursParAn,
    nbJoursReunionsDirigeantsParAn,
    membreIndependant,
    useJoursParAnManuels,
    joursParAnManuels,
    daysOfFormationCount
  );

  useEffect(() => {
    onDedicatedDaysChange(dedicatedDays, index);
  }, [dedicatedDays, index, onDedicatedDaysChange]);

  useEffect(() => {
    updateDedicatedDays();
  }, [updateDedicatedDays]);

  const fonctionDays = useMemo(() => {
    if (dedicatedDays !== null && dedicatedDays !== undefined) {
      return dedicatedDays; // - daysOfFormationCount;
    }
  }, [dedicatedDays]);

  // END Dedicated Days Calculations

  const { t } = useLangue();

  const entityFilter = useMemo(() => {
    return { _scoped: isPrimary };
  }, [isPrimary]);

  return (
    <Box style={{ maxWidth: "49em" }} key={JSON.stringify(props)}>
      {isPrimary ? (
        <span>Sélection d’une entité existante</span>
      ) : (
        <span>Sélection d’une entité existante (le cas écheant)</span>
      )}

      <Box>
        <ReferenceInputCustom
          label={t("Entité")}
          source={`${name}.entiteId`}
          reference="entites"
          allowEmpty
          filter={entityFilter}
          perPage={9999}
        >
          <AutocompleteInputCustom
            optionText="denominationSociale"
            allowEmpty
            helperText={t(
              "Sélectionnez l’entité dans laquelle la fonction ou le mandat est exercé."
            )}
            disabled={disabled}
          />
        </ReferenceInputCustom>
      </Box>

      {/* Entité interne au Groupe */}
      {!!entiteId && (
        <Box mt="1em">
          <Typography variant="body2">
            Si les champs ci-dessous sont incomplets, merci de les renseigner
          </Typography>
          <Box display="flex">
            <Box mr="1em">
              <TextInputCustom
                disabled={disabled}
                label={t("Groupe d’appartenance")}
                portalTargets="acpr"
                source={`${name}.entiteGroupeAppartenance`}
                id={`${tabName}#${name}.entiteGroupeAppartenance`}
                validate={required()}
              />
            </Box>
          </Box>
          <Box display="flex">
            <Box mr="1em">
              <NumberInputCustom
                disabled={disabled}
                label={t("Pourcentage de détention")}
                portalTargets="acpr"
                source={`${name}.pourcentageDetention`}
                id={`${tabName}#${name}.pourcentageDetention`}
                min={0}
                max={100}
                validate={portalTarget === "acpr" ? required() : null}
              />
            </Box>

            <Box mr="1em">
              <TextInputCustom
                disabled={disabled}
                label={t("Identifiants")}
                portalTargets="acpr"
                source={`${name}.identifiants`}
                id={`${tabName}#${name}.identifiants`}
                validate={portalTarget === "acpr" ? required() : null}
              />
            </Box>
          </Box>
          <Box display="flex">
            <Box mr="1em">
              <TextInputCustom
                disabled={disabled}
                label={t("Forme Juridique")}
                source={`${name}.entiteFormeJuridique`}
                id={`${tabName}#${name}.entiteFormeJuridique`}
                validate={required()}
                portalTargets="acpr"
              />
            </Box>

            <Box ml="1em">
              <TextInputCustom
                disabled={disabled}
                label={t("Pays")}
                source={`${name}.pays`}
                id={`${tabName}#${name}.pays`}
                validate={required()}
              />
            </Box>
          </Box>

          <Box display="flex">
            <Box mr="1em" display="flex">
              <TextInputCustom
                disabled={disabled}
                label={t("Taille de l’entité")}
                source={`${name}.tailleEntite`}
                tabName={`${tabName}#${name}.tailleEntite`}
                validate={required()}
              />
              <InfoBulle position="right" width="15em" mt="0.5em" ml="0.5em">
                Total de bilan (social ou, s’il existe, consolidé), chiffre
                d’affaires, résultat net, nombre d’employés, présence
                géographique…
                <br />
                Dans le cas des SCM, prévoir en plus le total des engagements
                donnés hors bilan.
              </InfoBulle>
            </Box>
            <Box mr="1em">
              <NullableBooleanInputCustom
                disabled={disabled}
                label={t("Entité Cotée")}
                source={`${name}.entiteCotee`}
                id={`${tabName}#${name}.entiteCotee`}
                portalTargets="acpr"
                validate={!disabled ? required() : undefined}
              />
            </Box>
          </Box>
        </Box>
      )}
      {/* Entité extérieure */}
      {!entiteId && (
        <Box mt="1em">
          <Typography variant="body2">
            Si l’entité n’est pas présente dans la liste ci-dessus, veuillez
            nous communiquer les informations suivantes&nbsp;:
          </Typography>
          <Box display="flex">
            <Box>
              <TextInputCustom
                disabled={disabled}
                label={t("Nom entité")}
                helperText={t("Apposer une *, si la société est côtée")}
                source={`${name}.entiteNom`}
                id={`${tabName}#${name}.entiteNom`}
                validate={required()}
              />
            </Box>
            <Box ml="1em">
              <TextInputCustom
                disabled={disabled}
                label={t("Dénomination sociale")}
                source={`${name}.entiteDenominationSociale`}
                id={`${tabName}#${name}.entiteDenominationSociale`}
                validate={required()}
              />
            </Box>
          </Box>
          <Box display="flex">
            <Box mr="1em">
              <TextInputCustom
                disabled={disabled}
                label={t("Groupe d’appartenance")}
                source={`${name}.groupeAppartenance`}
                portalTargets="acpr"
                id={`${tabName}#${name}.groupeAppartenance`}
                multiline
                validate={required()}
                style={{ width: "37em" }}
              />
            </Box>

            <Box>
              <NumberInputCustom
                disabled={disabled}
                label={t("Pourcentage de détention")}
                source={`${name}.pourcentageDetention`}
                id={`${tabName}#${name}.pourcentageDetention`}
                portalTargets="acpr"
                min={0}
                max={100}
              />
            </Box>
          </Box>
          <Box display="flex">
            <Box flex={1} mr="0.5em" display="flex">
              <Box>
                <TextInputCustom
                  disabled={disabled}
                  label="Code CIB"
                  source={`${name}.entiteCodeCIB`}
                  id={`${tabName}#${name}.entiteCodeCIB`}
                  helperText="Si applicable"
                  multiline
                />
              </Box>
              <Box ml="1em">
                <TextInputCustom
                  disabled={disabled}
                  label="Code SIREN"
                  source={`${name}.entiteCodeSIREN`}
                  id={`${tabName}#${name}.entiteCodeSIREN`}
                  helperText="Si applicable"
                  multiline
                />
              </Box>
              <Box ml="1em">
                <TextInputCustom
                  disabled={disabled}
                  label="Code LEI"
                  source={`${name}.entiteCodeLEI`}
                  id={`${tabName}#${name}.entiteCodeLEI`}
                  helperText="Si applicable"
                  multiline
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex">
            <Box>
              <TextInputCustom
                disabled={disabled}
                label={t("Forme juridique")}
                source={`${name}.entiteFormeJuridique`}
                id={`${tabName}#${name}.entiteFormeJuridique`}
                validate={required()}
              />
            </Box>
            <Box ml="1em">
              <TextInputCustom
                disabled={disabled}
                label={t("Pays")}
                source={`${name}.pays`}
                id={`${tabName}#${name}.pays`}
                validate={required()}
              />
            </Box>
          </Box>
          <Box>
            <TextInputCustom
              disabled={disabled}
              label={t(
                "Taille de l'entité (total de bilan social ou consolidé)"
              )}
              source={`${name}.tailleEntite`}
              id={`${tabName}#${name}.tailleEntite`}
              multiline
              style={{ width: "56em" }}
            />
          </Box>
        </Box>
      )}
      <Box display="flex">
        <SelectInputCustom
          disabled={disabled}
          source={`${name}.fonctionId`}
          label={t("Fonction")}
          validate={required()}
          helperText={TYPES[fonction.type]}
          choices={
            isPrimary ? FONCTIONS_CHOICES[langue] : MANDATS_CHOICES[langue]
          }
        />
        {fonction.id === "autre" && (
          <>
            <Box ml="1em">
              <TextInputCustom
                disabled={disabled}
                label={t("Intitulé de la fonction")}
                source={`${name}.intituleFonction`}
                id={`${tabName}#${name}.intituleFonction`}
                validate={required()}
              />
            </Box>
            <Box ml="1em">
              <SelectInputCustom
                disabled={disabled}
                source={`${name}.typeFonction`}
                label={t("Type de fonction")}
                choices={TYPES_CHOICES[langue]}
                validate={required()}
              />
            </Box>
          </>
        )}
        {isBPCE && Fonction.canBeIndependant(fonction) && (
          <Box ml="1em">
            <CheckboxGroupInput
              disabled={disabled}
              row
              label=""
              source={`${name}.membreIndependant`}
              id={`${tabName}#${name}.membreIndependant`}
              choices={[
                { id: "membre-independant", name: "Membre Indépendant" },
              ]}
            />
          </Box>
        )}
        <InfoBulle position="right" width="12em" mt="0.5em" ml="0.5em">
          Maximum&nbsp;: 1 mandat exécutif + 2 mandats non éxécutifs OU 4
          mandats non éxécutifs dans le cas où l'établissement pour lequel le
          formulaire est complété est soumis à la régle de limitation de cumul
          des mandats.
          <br />
          –
          <br />
          Concernant les membres représentant des salariés «&nbsp;code de
          commerc&nbsp;», il est précisé que les règles prévues par le code de
          commerce sont compatibles avec les règles prévues par la Politique FAP
          Groupe pour l’ensemble des membres de Conseil excepté pour les temps
          de préparation des réunions de Comité. Les temps de préparation à
          observer sont donc les suivants&nbsp;: 2 jours minimum par réunion du
          Conseil et de Comité, avec un minimum de 18 jours par an pour les
          membres de CA et 15 jours par an pour les membres de COS. S’agissant
          des membres représentant les salariés sociétaires, les règles ci-avant
          peuvent être suivies sur une base volontaire (il n’existe aucune
          obligation légale ou réglementaire).
        </InfoBulle>
      </Box>
      {/*
        NOTE: Il y eu un champ descriptionActivite, mais il semble que les utilisateurs
        ont préféré utiliser celui dédié à la description de l’activité de l’entreprise
      */}
      <TextInputCustom
        disabled={disabled}
        label={t("Description de l'activité de l'entité")}
        source={`${name}.descriptionActiviteEntite`}
        id={`${tabName}#${name}.descriptionActiviteEntite`}
        multiline
        style={{ width: "56em" }}
        validate={required()}
      />

      {isPrimary && (
        <Box display="flex">
          <DateInputCustom
            disabled={disabled}
            label={t("Date de nomination ou d’élection")}
            source={`${name}.dateNomination`}
            validate={required()}
          />
        </Box>
      )}
      {isPrimary && (
        <Box display="flex">
          <DateInputCustom
            portalTargets="imas"
            disabled={disabled}
            label={t("Date d’évaluation")}
            source={`${name}.dateEvaluation`}
          />
        </Box>
      )}

      <Box display="flex">
        {((canHaveReunionsDirigeants &&
          isPresidentConseilSurveillance &&
          !isMembreIndependant) ||
          isDirigeant) && (
          <Box mr="1em">
            {isDirigeant && (
              <NumberInputCustom
                disabled={disabled}
                label="Réunions de dirigeants (nombre de jours)"
                source={`${name}.nbJoursReunionsDirigeantsParAn`}
                width="28em"
                maxValue={365}
              />
            )}
          </Box>
        )}
        <Box>
          <NumberInputCustom
            disabled={disabled}
            label={
              fonction.type === "mandat-non-executif" &&
              fonction.id !== "autre" &&
              entiteId
                ? t("Nombre de réunions par an")
                : t("Nombre de jours par an")
            }
            source={`${name}.nbJoursParAn`}
            onChange={updateDedicatedDays}
            id={`${tabName}#${name}.nbJoursParAn`}
            validate={[minValue(0), maxValue(365)]}
            helperText="hors responsabilités supplémentaires"
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box>
          <DateInputCustom
            disabled={disabled}
            label={t("Début (prévu) du mandat")}
            helperText="Dans le doute, saisissez le 1er janvier"
            source={`${name}.debutMandat`}
            width="10em"
          />
        </Box>

        <Box ml="1em">
          <DateInputCustom
            label={t("Fin du mandat")}
            validate={[validateMandateEndDate(t)]}
            helperText="Dans le doute, saisissez le 1er janvier"
            source={`${name}.finMandat`}
            width="10em"
            disabled={disabled || finIndeterminee.includes("indeterminee")}
          />
        </Box>
        <Box ml="1em">
          <CheckboxGroupInput
            disabled={disabled}
            row
            label=""
            source={`${name}.finIndeterminee`}
            choices={[{ id: "indeterminee", name: t("indéterminée") }]}
          />
        </Box>
      </Box>
      <Box key={name}>
        <Typography variant="subtitle2" portalTargets="imas">
          {t(
            "Responsabilités Supplementaires (appartenance à des comités, etc.) :"
          )}
        </Typography>
        <FormDataConsumer>
          {({ formData }) => (
            <ArrayInput
              disabled={disabled || formData.portalTarget === "acpr"}
              portalTargets="imas"
              key={`array-input-resp-sup-${name}`}
              source={`${name}.responsabilitesSupplementaires`}
              label=""
            >
              <FormIteratorCustom
                disabled={disabled}
                key={`form-iterator-resp-sup-${name}`}
                limit={RESPONSABILITES_CHOICES[langue]?.length ?? 0}
                component={
                  <ResponsabilitesSupplementaires
                    langue={langue}
                    disabled={disabled}
                    mandatIndex={index}
                    responsabilitesSupplementaires={
                      responsabilitesSupplementaires
                    }
                  />
                }
              />
            </ArrayInput>
          )}
        </FormDataConsumer>
      </Box>
      <Box display="flex">
        <FormDataConsumer>
          {({ formData }) => (
            <SelectInputCustom
              disabled={disabled || formData.portalTarget === "acpr"}
              label={t("Décompte privilégié")}
              source={`${name}.decomptePrivilegie`}
              id={`${tabName}#${name}.decomptePrivilegie`}
              choices={DECOMPTE_PRIVILEGIE_CHOICES[langue]}
              fullWidth
            />
          )}
        </FormDataConsumer>
        <InfoBulle position="right" mt="0.5em" ml="0.5em">
          <strong>Décompte privilégié&nbsp;:</strong> Appartenance à un réseau
          mutualiste, coopératif ou à un groupe (en précisant le nom du réseau
          ou du groupe d’appartenance au sens de l’article L. 233-16 et suivants
          du code de commerce)
          <br />
          ou détention d’une participation qualifiée (en précisant le
          pourcentage de détention et le nom de la société détentrice directe
          ainsi que du groupe d’appartenance de celle-ci).
          <br />
          NB&nbsp;: Concernant particulièrement l’appartenance à un groupe, il
          convient de préciser tout lien de contrôle ou d’influence notable, de
          nature capitalistique ou autre, entre les entités mentionnées. Les
          établissements affiliés à un réseau et l’organe central au sens de
          l’article L. 511-31 du code monétaire et financier sont considérés
          comme faisant partie d’un même groupe.
          <br />
          <br />
          <strong>Absence de décompte&nbsp;:</strong> fonctions exercées au sein
          d'entités dont l'objet n'est pas principalement commercial (ex.&nbsp;:
          sociétés civiles patrimoniales) / Représentant de l'état
          <br />
          –
          <br />
          réponse à indiquer pour toute entité appartement à un Groupe XXX dans
          lequel un mandat est détenu : « Décompte privilégié au titre
          d’appartenance au Groupe XXX »
        </InfoBulle>
      </Box>

      {!isPrimary && (
        <Box>
          <FormDataConsumer>
            {({ formData }) => (
              <NumberInputCustom
                disabled={disabled || formData.portalTargets === "acpr"}
                helpText=""
                label={t("Nombre de réunions par an")}
                source={`${name}.nbReunionsParAnIMAS`}
                id={`${tabName}#${name}.nbReunionsParAnIMAS`}
                helperText={t(
                  "Le cas échéant, préciser le détail par instance"
                )}
              />
            )}
          </FormDataConsumer>
        </Box>
      )}

      <TextInputCustom
        disabled={disabled}
        label={t("Informations complémentaires, commentaires")}
        source={`${name}.informationsComplementaires`}
        id={`${tabName}#${name}.informationsComplementaires`}
        style={{ width: "56em" }}
        multiline
      />
      <Box fontWeight="fontWeightRegular" mb="1em">
        {isPrimary
          ? `${t("Temps consacré ")}: ${fonctionDays ?? t("Non Spécifié")} ${t(
              "jours par an"
            )}${
              daysOfFormationCount > 0
                ? ` ${t("et")} ${daysOfFormationCount} ${t(
                    "jours de formation"
                  )}`
                : ""
            }`
          : `${t("Temps consacré ")}: ${dedicatedDays ?? t("Non Spécifié")}`}
        <input
          type="hidden"
          name={`${name}.dedicatedDays`}
          value={dedicatedDays}
        />
      </Box>
      <Box>
        <BooleanInput
          source={`${name}.useJoursParAnManuels`}
          label="Forcer le nombre de jours par an"
        />
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData }) => {
            if (formData.mandatFonctions === undefined) {
              return null;
            }
            const mandatFonction = formData.mandatFonctions[index];
            if (mandatFonction?.useJoursParAnManuels) {
              return (
                <NumberInputCustom
                  source={`${name}.joursParAnManuels`}
                  label={t("Nombre de jours par an")}
                  helperText={t("incluant les responsabilités supplémentaires")}
                  validate={[minValue(0), maxValue(365)]}
                />
              );
            }
            return null;
          }}
        </FormDataConsumer>
      </Box>
    </Box>
  );
}

MandatFonction.whyDidYouRender = true;

function ResponsabilitesSupplementaires({
  name,
  langue,
  disabled,
  tabName,
  mandatIndex,
  responsabilitesSupplementaires,
}) {
  const [responsabilite] = useFieldState(`${name}.responsabilite`);
  /* eslint-disable-next-line no-unused-vars */
  const [_custom, setCustom] = useFieldState(`${name}.custom`);
  const [type, setType] = useFieldState(`${name}.type`);

  useEffect(() => {
    if (responsabilite && responsabilite !== "autre") {
      let type = "",
        custom = undefined;
      if (FONCTIONS[responsabilite]) {
        type = FONCTIONS[responsabilite];
        custom = type;
      }
      setType(type);
      setCustom(custom);
    }
  }, [setCustom, responsabilite, setType]);

  const exclude = useMemo(
    () =>
      responsabilitesSupplementaires
        .map((respSup) => respSup?.responsabilite)
        .filter((f) => !!f),
    [responsabilitesSupplementaires]
  );

  const choices = useMemo(
    () =>
      RESPONSABILITES_CHOICES[langue].filter(
        ({ id }) =>
          responsabilite === id || id === "autre" || !exclude.includes(id)
      ),
    [exclude, langue, responsabilite]
  );

  const { t } = useLangue();

  return (
    <Box key={name}>
      <Box display="flex">
        <Box mr="1em">
          <SelectInputCustom
            disabled={disabled}
            label={t("Fonction")}
            helperText={TYPES[type]}
            source={`${name}.responsabilite`}
            allowEmpty={false}
            choices={choices}
          />
        </Box>
        {responsabilite === "autre" && (
          <Box display="flex">
            <Box mr="1em">
              <TextInputCustom
                disabled={disabled}
                label={t("Intitulé de la fonction")}
                source={`${name}.custom`}
              />
            </Box>
          </Box>
        )}
        <NumberInputCustom
          disabled={disabled}
          key={`${name}.nbReunionsParAn`}
          label={
            mandatIndex !== 0 || responsabilite === "autre"
              ? t("Nombre de jours par an")
              : t("Nombre de réunions par an")
          }
          defaultValue={1}
          validate={[minValue(0), maxValue(365)]}
          id={`${tabName}#${name}.nbReunionsParAn`}
          source={`${name}.nbReunionsParAn`}
        />
      </Box>
    </Box>
  );
}
