import React from "react";
import { CheckboxGroupInput } from "react-admin";
import { useOnlyForTargets, usePortalTarget } from "../../candidatures/PortalTarget";

export default React.forwardRef(({ portalTargets, ...props }, ref) => {
  const disabled = useOnlyForTargets(portalTargets, props.disabled);
  const portal = usePortalTarget().target;
  
  if (portalTargets && portalTargets !== portal) {
    return <></>
  }

  return <CheckboxGroupInput ref={ref} {...props} disabled={disabled} />;
});
