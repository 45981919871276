import React, { memo, useState, useCallback } from "react";
import { Box } from "@material-ui/core";

import InfoBulle from "../../components/InfoBulle";

import { SelectInput } from "react-admin";
import CandidatInfractionACPR from "./CandidatInfractionACPR";
import CandidatInfractionIMAS from "./CandidatInfractionIMAS";

function useInfractionPortalTarget() {
  const inStore = window.sessionStorage.getItem("fp-candidat-portal-target");
  const [portal, setPortal] = useState(inStore || "acpr");

  if (!inStore) {
    window.sessionStorage.setItem("fp-candidat-portal-target", portal);
  }

  const updatePortal = useCallback(
    (newPortal) => {
      if (portal !== newPortal) {
        window.sessionStorage.setItem("fp-candidat-portal-target", newPortal);
        setPortal(newPortal);
      }
    },
    [portal]
  );

  return [portal, updatePortal];
}

export default memo(({ tabName, disabled }) => {
  const [portal, setPortal] = useInfractionPortalTarget();

  return (
    <Box>
      <SelectInput
        source="infraction.target"
        label="Portail cible"
        onChange={({ target }) => setPortal(target.value)}
        defaultValue={portal}
        choices={[
          { id: "acpr", name: "Autorisations" },
          { id: "imas", name: "IMAS" },
        ]}
      />

      <Box display="flex" justifyContent="flex-end">
        <InfoBulle position="right" nonHoverable>
          {portal === "acpr"
            ? "Le formulaire ci-dessous vise le portail Autorisations"
            : "Le formulaire ci-dessous vise le portail IMAS de la BCE"}
        </InfoBulle>
      </Box>

      {portal === "acpr" ? (
        <CandidatInfractionACPR tabName={tabName} disabled={disabled} />
      ) : (
        <CandidatInfractionIMAS tabName={tabName} disabled={disabled} />
      )}
    </Box>
  );
});
