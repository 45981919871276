import React from "react";
import {
  Filter,
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

const STATUT_CHOICES = [
  { id: "Brouillon", name: "Brouillon" },
  { id: "Abandonne", name: "Abandonné" },
  { id: "SoumisCandidat", name: "Soumis au Candidat" },
  { id: "ValidationEntite", name: "En attente de validation par l’entité" },
  { id: "ValidationBpce", name: "En attente de validation par BPCE" },
  {
    id: "ValidePourSuperviseur",
    name: "En attente du superviseur",
  },
  { id: "Transmis", name: "Transmis" },
  { id: "Finalise", name: "Finalisé" },
  { id: "Reevalue", name: "Réévalué" },
];

export default (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="candidatId"
      reference="candidats"
      sort={{
        field: "nom",
        order: "ASC",
      }}
      perPage={9999}
    >
      <AutocompleteInput
        optionText={(choice) =>
          choice && choice.nom && choice.prenom
            ? `${choice.nom} ${choice.prenom}`
            : ""
        }
      />
    </ReferenceInput>
    <ReferenceInput
      source="entiteId"
      label="Entité"
      reference="entites"
      perPage={9999}
      sort={{
        field: "denominationSociale",
        order: "ASC",
      }}
    >
      <AutocompleteInput optionText="denominationSociale" />
    </ReferenceInput>
    <SelectInput source="statut" label="Statut" choices={STATUT_CHOICES} />
  </Filter>
);
