import React from "react";

import { Typography, Box } from "@material-ui/core";
import TranslatableTextInputCustom from "../../../components/input/TranslatableTextInputCustom";
import SelectInputCustom from "../../../components/input/SelectInputCustom";

import { Enum } from "../../../utils";
import { Langue } from "../../../lib/langue";
import { useLangue } from "../../../candidatures/transalations";
import { FormDataConsumer } from "react-admin";
import TextInputCustom from "../../../components/input/TextInputCustom";

function Subtitle({ children, ...props }) {
  const { t } = useLangue();

  let c = typeof children === "string" ? t(children) : children;

  return (
    <Box maxWidth="72em">
      <Typography {...props}>{c}</Typography>
    </Box>
  );
}

const TypePoste = Enum({
  MembreOrganeDirection: "membre-organe-direction",
  TitulaireFonctionCle: "titulaire-fonction-cle",
  CadreSuperieur: "cadre-superieur",
  Autre: "autre",
});

const POSTE_CHOICES = {
  [Langue.Fr]: [
    {
      id: TypePoste.MembreOrganeDirection,
      name: "Membre de l’organe de direction",
    },
    {
      id: TypePoste.TitulaireFonctionCle,
      name: "Titulaire d’une fonction clé",
    },
    { id: TypePoste.CadreSuperieur, name: "Cadre supérieur" },
    { id: TypePoste.Autre, name: "Autre" },
  ],
  // NOTE: Concerne l’ACPR, donc aucune traduction n’est possible
  [Langue.En]: [
    {
      id: TypePoste.MembreOrganeDirection,
      name: "Membre de l’organe de direction",
    },
    {
      id: TypePoste.TitulaireFonctionCle,
      name: "Titulaire d’une fonction clé",
    },
    { id: TypePoste.CadreSuperieur, name: "Cadre supérieur" },
    { id: TypePoste.Autre, name: "Autre" },
  ],
};

export default function ConcerneEntite({
  disabled,
  tabName /* , ...rest } */,
}) {
  const { /*t, */ langue } = useLangue();
  return (
    <Box ml="2em" mr="2em">
      <Box maxWidth="72em">
        <Subtitle>
          Précisez votre rôle au sein de l'entité et indiquez si vous êtes ou
          étiez à la tête d'un service ou d'une ligne métier concerné(e) par la
          procédure (y compris par les sanctions ou les mesures imposées) :
        </Subtitle>
        <TranslatableTextInputCustom
          disabled={disabled}
          multiline
          noTranslate
          source="infractionsACPR.concerneEntiteRole"
          id={`${tabName}#infractionsACPR.concerneEntiteRole`}
          fullWidth
        />
        <Subtitle>
          Étiez-vous membre de l’organe de direction, titulaire d'une
          fonction-clé ou cadre supérieur lors des actes répréhensibles présumés
          ?
        </Subtitle>
        <TextInputCustom
          disabled={disabled}
          noTranslate
          source="infractionsACPR.concerneEntiteTypePoste"
          id={`${tabName}#infractionsACPR.concerneEntiteTypePoste`}
          fullWidth
        />
        <Subtitle>
          Dans le cadre de votre rôle au sein de l’entité, qu’avez-vous fait
          pour prévenir les actes répréhensibles présumés et/ou pour éviter
          qu’ils soient commis ?
        </Subtitle>
        <TranslatableTextInputCustom
          disabled={disabled}
          multiline
          noTranslate
          source="infractionsACPR.concerneEntiteMesuresPrises"
          id={`${tabName}#infractionsACPR.concerneEntiteMesuresPrises`}
          fullWidth
        />
        <Subtitle>
          Auriez-vous pu en faire plus pour éviter que les actes répréhensibles
          présumés soient commis et avez-vous tiré des enseignements de ce qu’il
          s’est passé ?
        </Subtitle>
        <TranslatableTextInputCustom
          disabled={disabled}
          multiline
          noTranslate
          source="infractionsACPR.concerneEntiteEnseignements"
          id={`${tabName}#infractionsACPR.concerneEntiteEnseignements`}
          fullWidth
        />
      </Box>
    </Box>
  );
}

ConcerneEntite.clear = function (formData) {
  if (!formData.infractionsACPR) return;

  formData.infractionsACPR.concerneEntiteRole = null;
  formData.infractionsACPR.concerneEntiteTypePoste = null;
  formData.infractionsACPR.concerneEntiteMesuresPrises = null;
  formData.infractionsACPR.concerneEntiteEnseignements = null;
};
