import React from "react";

import Box from "@material-ui/core/Box";

import Typography from "../../../components/custom/TypographyCustom";
import TextInputCustom from "../../../components/input/TextInputCustom";

import { useLangue } from "../../transalations";

export default function AssocieAutreEntreprise({ name, tabName, disabled }) {
  const { t } = useLangue();

  return (
    <Box>
      <TextInputCustom
        label={t("Nom de l'entreprise")}
        source={`${name}.nomEntreprise`}
        id={`${tabName}#${name}.nomEntreprise`}
        disabled={disabled}
      />
      <TextInputCustom
        multiline
        label={t("Nature de ses activités")}
        source={`${name}.natureActivitesEntreprise`}
        id={`${tabName}#${name}.natureActivitesEntreprise`}
        fullWidth
        disabled={disabled}
      />
      <Typography variant="subtitle1">
        {t("Niveau de participation (en % du capital et des droits de vote) :")}
      </Typography>
      <TextInputCustom
        source={`${name}.niveauParticipation`}
        id={`${tabName}#${name}.niveauParticipation`}
        disabled={disabled}
      />
      <TextInputCustom
        multiline
        label={t("Période concernée")}
        source={`${name}.periodeConcernee`}
        id={`${tabName}#${name}.periodeConcernee`}
        fullWidth
        disabled={disabled}
      />
      <Typography variant="subtitle1">
        {t(
          "Liens entre ces entreprises et l’entité soumise à la surveillance prudentielle, sa société mère et ses filiales :",
        )}
      </Typography>
      <TextInputCustom
        multiline
        source={`${name}.liens`}
        id={`${tabName}#${name}.liens`}
        fullWidth
        disabled={disabled}
      />
    </Box>
  );
}
