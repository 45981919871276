import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  memo,
} from "react";
import {
  ArrayInput,
  FormDataConsumer,
  minValue,
  maxValue,
  required,
} from "react-admin";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import Typography from "../../../components/custom/TypographyCustom";
import TextInputCustom from "../../../components/input/TextInputCustom";
import NullableBooleanInputCustom from "../../../components/input/NullableBooleanInputCustom";
import BooleanInputCustom from "../../../components/input/BooleanInputCustom";
import NumberInputCustom from "../../../components/input/NumberInputCustom";
import FormIteratorCustom from "../../../components/custom/FormIteratorCustom";
import InfoBulle from "../../../components/InfoBulle";

import { getDaysOfFormationCount, useGlobalDisabled } from "../helper";
import AlertTooManyDays from "../AlertTooManyDays";

import MandatFonction from "./MandatFonction";

import { useFieldState } from "./hooks";
import { useLangue } from "../../transalations";
import SelectInputCustom from "../../../components/input/SelectInputCustom";
import AlertManyFonctions from "./AlertManyFonctions";

function floorInt(int, min = 0) {
  if (int < min) {
    return min;
  }

  return int;
}

function daysAYearToHoursAWeek(days) {
  return parseFloat(((days * 8) / 46).toFixed(1));
}

export default memo(function CandidatureFonction({ tabName, candidatureId }) {
  const disabled = useGlobalDisabled();

  // Bindings for calculating Dedicated Time
  const [overrideJoursParAn] = useFieldState("overrideJoursParAn");
  const [dayFonction] = useFieldState("fonctionJoursParAn");
  const [totalNbJoursParAnManuel, setNbJoursParAnManuel] = useFieldState(
    "totalNbJoursParAnManuel"
  );
  const [, setNbJoursParAnNonExecutifManuel] = useFieldState(
    "totalNbJoursParAnNonExecutifManuel"
  );
  const [formations] = useFieldState("formations");

  // Compute Dedicated Time Sums
  // And hours/week equivalence
  let daysOfFormationCount = getDaysOfFormationCount({ formations });
  const [dedicatedDaysList, setDedicatedDaysList] = useState([]);

  const onRemoveMandatFonction = useCallback(
    (index) => {
      setDedicatedDaysList([
        ...dedicatedDaysList.filter((d, i) => i !== index && d),
      ]);
    },
    [dedicatedDaysList]
  );

  const onDedicatedDaysChange = useCallback(
    (dDays, index) => {
      if (isNaN(dDays)) {
        dDays = 0;
      }
      let nextList = [...dedicatedDaysList];

      if (dedicatedDaysList[index] !== dDays) {
        nextList[index] = dDays;

        if (nextList.some((d, i) => d !== dedicatedDaysList[i])) {
          setDedicatedDaysList(nextList);
        }
      }
    },
    [dedicatedDaysList]
  );

  const loaded = useRef(false);

  // if in iFrame, it is a print scenario
  // register the dedicatedDays value as one to wait for
  useEffect(() => {
    if (!!window.frameElement) {
      window.parent.postMessage(
        {
          type: "REGISTER",
          payload: "dedicatedDays",
        },
        window.frameElement.baseURI
      );
    }
  }, []);

  // Total time spent on the function the candidature targets
  const firstFonctionDayTotal = useMemo(() => {
    return (
      floorInt(dedicatedDaysList[0] || 0) +
      parseFloat(daysOfFormationCount || 0, 10)
    );
  }, [daysOfFormationCount, dedicatedDaysList]);

  const timeOut = useRef(null);

  // if in iFrame, we are in a print sceneario
  // signal the parent the value is loaded so that
  // it opens the print dialog
  useEffect(() => {
    if (!window.frameElement) return;
    if (loaded.current) return;
    if (dedicatedDaysList.length === 0) return;

    if (timeOut.current) clearTimeout(timeOut.current);

    timeOut.current = setTimeout(() => {
      window.parent.postMessage(
        {
          type: "SET_LOADED",
          payload: "dedicatedDays",
        },
        window.frameElement.baseURI
      );

      loaded.current = true;
    }, 5000);
  }, [firstFonctionDayTotal, dedicatedDaysList]);

  // const firstFonctionHoursAWeek = daysAYearToHoursAWeek(firstFonctionDayTotal);

  const allFonctionsDayTotal = useMemo(() => {
    return (
      floorInt(
        dedicatedDaysList.reduce(
          (acc, d, _) => parseFloat(acc) + (parseFloat(d) || 0),
          0
        ),
        0
      ) + parseFloat(daysOfFormationCount || 0, 10)
    );
  }, [daysOfFormationCount, dedicatedDaysList]);

  const allFonctionsHoursAWeek = daysAYearToHoursAWeek(allFonctionsDayTotal);

  const otherFonctionsDayTotal = useMemo(() => {
    return floorInt(allFonctionsDayTotal - firstFonctionDayTotal);
  }, [allFonctionsDayTotal, firstFonctionDayTotal]);

  const otherFonctionsHoursAWeek = daysAYearToHoursAWeek(
    otherFonctionsDayTotal
  );

  // Dedidacted Time Overrides
  const prevOverride = useRef(overrideJoursParAn);

  useEffect(() => {
    if (overrideJoursParAn && prevOverride.current !== overrideJoursParAn) {
      setNbJoursParAnManuel(allFonctionsDayTotal);
      setNbJoursParAnNonExecutifManuel(allFonctionsDayTotal);
    }

    prevOverride.current = overrideJoursParAn;
  }, [allFonctionsDayTotal, allFonctionsHoursAWeek, dayFonction, firstFonctionDayTotal, overrideJoursParAn, setNbJoursParAnManuel, setNbJoursParAnNonExecutifManuel]);

  const { t } = useLangue();

  // Render all the things
  return (
    <Box maxWidth="72em">
      <Box display="flex" mb="1em">
        <InfoBulle nonHoverable mt="1em">
          Doit être rempli en utilisant les méthodes de calcul définies et
          diffusées par les équipes Gouvernance Groupe et en application de la
          Politique Groupe Fit & Proper
        </InfoBulle>
      </Box>

      <Box width="100%" display="flex">
        <Typography variant="subtitle1">
          {t(
            "Veuillez donner une description la plus détaillée possible des principales responsabilités et missions de la fonction concernée ainsi que l’effectif placé sous sa responsabilité."
          )}
        </Typography>
        <InfoBulle position="right">
          Cette question n’est pas réservée aux dirigeants effectifs, même si
          les réponses à cette question doivent être adaptées au rôle de la
          personne nommée. Par exemple, il n’est pas attendu que l’entité
          indique l’effectif placé sous la responsabilité de la personne si ce
          dernier n’est pas un dirigeant effectif. 
          <br />
          –
          <br />
          Si le membre de conseil nommé est par ailleurs nommé dans des comités
          spécialisés, ces informations doivent être indiquées dans cette
          rubrique. Pour les dirigeants effectifs compléter les comités de
          gestion interne (ex: comité des engagements, comité watch list, comité
          de contrôle interne …) auxquels ils participent au titre de leur
          fonction.
        </InfoBulle>
      </Box>

      <Box width="100%" display="flex">
        <Typography variant="subtitle1">
          {t(
            "Veuillez indiquer quelle(s) autre(s) fonction(s) la personne nommée (renouvelée) exercera éventuellement au sein de l’entité soumise à la surveillance prudentielle :"
          )}
        </Typography>
      </Box>
      <TextInputCustom
        multiline
        disabled={disabled}
        source="descriptionFonction"
        id={`${tabName}#descriptionFonction`}
        fullWidth
      />

      <Typography variant="subtitle1" portalTargets="acpr">
        Le cas échéant, précisez également à quel(s) comité(s) de l’organe de
        direction ou à quel(s) autre(s) comité(s) (de gestion) la personne
        concernée participera :
      </Typography>
      <TextInputCustom
        multiline
        disabled={disabled}
        portalTargets="acpr"
        source="participationAutresComites"
        id={`${tabName}#participationAutresComites`}
        fullWidth
      />

      <Typography variant="subtitle1">
        {t(
          "▸ Une fonction non exécutive supplémentaire au sein de l’organe de direction a-t-elle été autorisée par une autorité compétente ?"
        )}
      </Typography>
      <Box display="flex">
        <SelectInputCustom
          disabled={disabled}
          source="fonctionNonExecutiveSupplementaire"
          choices={[
            { id: "oui", name: "Oui" },
            { id: "non", name: "Non" },
            { id: "sans-objet", name: "Sans objet" },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.fonctionNonExecutiveSupplementaire === "yes" && (
              <Box ml="1em">
                <TextInputCustom
                  disabled={disabled}
                  portalTargets="imas"
                  source="fonctionNonExecutiveSupplementaireText"
                  label="Nom de l’autorité compétente"
                />
              </Box>
            )
          }
        </FormDataConsumer>
      </Box>

      <Typography variant="h6">
        {t(
          "Liste des fonctions exécutives et non exécutives et des autres activités professionnelles."
        )}
      </Typography>
      <Typography variant="subtitle1">
        Veuillez indiquer en premier lieu le poste ou le mandat dans l’organe de
        direction pour lequel cette candidature est complétée, puis l’ensemble
        des autres fonctions et autres activités professionnelles de la personne
        nommée (renouvelée).
        <br />
        <strong>NB&nbsp;:</strong> Le poste occupé au sein de l’entité soumise à
        la surveillance prudentielle doit en effet être indiqué.
      </Typography>

      <AlertManyFonctions alertAt={6} />

      <ArrayInput disabled={disabled} source="mandatFonctions" label="">
        <FormIteratorCustom
          keepTheFirstOne
          disabled={disabled}
          component={
            <MandatFonction
              candidatureId={candidatureId}
              tabName={tabName}
              disabled={disabled}
              daysOfFormationCount={daysOfFormationCount}
              onDedicatedDaysChange={onDedicatedDaysChange}
            />
          }
        />
      </ArrayInput>

      <AlertTooManyDays dayTotal={allFonctionsDayTotal} />
      <AlertManyFonctions alertAt={6} />

      <Box mt="1em" mb="1em">
        <Typography variant="subtitle1" portalTargets="imas">
          <b>
            {t(
              "▸ Concernant l’ensemble des fonctions, incluant la fonction ou le mandat faisant l’objet de la demande :"
            )}
          </b>
        </Typography>

        <Typography variant="subtitle1" portalTargets="imas">
          {t("Total des jours consacrés par an :")}{" "}
          <b>{allFonctionsDayTotal}</b>
        </Typography>
      </Box>
      <Divider />

      <Box mt="1em" mb="1em">
        <Typography variant="subtitle1" portalTargets="acpr">
          <b>
            {t(
              "▸ Concernant l’ensemble des fonctions ou mandats, excepté la fonction faisant l’objet de la demande :"
            )}
          </b>
        </Typography>

        <Typography variant="subtitle1" portalTargets="acpr">
          {t("Total des jours consacrés par an :")}{" "}
          <b>{otherFonctionsDayTotal}</b>
        </Typography>
      </Box>
      <Divider />

      <Box mt="1em" mb="1em">
        <Typography variant="subtitle1">
          <b>
            {t(
              "▸ Concernant l’ensemble des fonctions de direction d’un niveau supérieur à la fonction faisant l’objet de la demande :"
            )}
          </b>
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" portalTargets="imas">
            Total des jours consacrés par an :
          </Typography>
          <NumberInputCustom
            disabled={disabled}
            portalTargets="imas"
            source="nbJoursParFonctionsNiveauSuperieur"
            id={`${tabName}#nbJoursParFonctionsNiveauSuperieur`}
            min={0}
            defaultValue={0}
            max={365}
            step={0.1}
          />
        </Box>

        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" gutterBottom>
            {t("Total des heures consacrées par semaine :")}{" "}
            <FormDataConsumer>
              {({ formData }) => (
                <b>
                  {daysAYearToHoursAWeek(
                    formData.nbJoursParFonctionsNiveauSuperieur
                  )}
                </b>
              )}
            </FormDataConsumer>
          </Typography>
          <InfoBulle mt="-1em">
            Hypothèses de calcul : 1 an = 46 semaines et 1 jour = 8 heures
          </InfoBulle>
        </Box>
      </Box>

      <Box>
        <BooleanInputCustom
          label="Forcer le nombre de jours/an consacrés à l’ensemble des fonctions"
          defaultValue={false}
          disabled={disabled}
          source="overrideJoursParAn"
        />
        {overrideJoursParAn && (
          <Box display="flex">
            <NumberInputCustom
              label={t("Nombre de jours par an")}
              disabled={disabled}
              source="totalNbJoursParAnManuel"
              helperText={t("Consacrés à l’ensemble des fonctions")}
              id={`${tabName}#totalNbJoursParAnManuel`}
              validate={[minValue(0), maxValue(365)]}
            />
            <Box ml="1em">
              <NumberInputCustom
                disabled={disabled}
                label={t("Nombre de jours par an")}
                source="totalNbJoursParAnNonExecutifManuel"
                id={`${tabName}#totalNbJoursParAnNonExecutifManuel`}
                helperText={t("Consacrés aux seules fonctions non-exécutives")}
                validate={(value) => {
                  if (value < 0) {
                    return ["Mininum 0"];
                  }

                  if (value > totalNbJoursParAnManuel) {
                    return [`Maximum ${totalNbJoursParAnManuel}`];
                  }
                }}
              />
            </Box>
          </Box>
        )}
      </Box>

      <Box my="1em" />
      <Divider />
      <Box mt="1em" />

      <Box display="flex" width="100%">
        <Typography variant="subtitle1" portalTargets="imas">
          L’entité soumise à la surveillance prudentielle doit présenter son
          évaluation du temps que la personne nommée / personne à nommer est
          censée consacrer à l’exercice de ses fonctions (en tenant compte, à
          des fins de comparaison, du temps consacré à des postes similaires au
          sein de l’établissement, d’entités similaires du groupes ou d’autres
          entités soumises à la surveillance prudentielle)
        </Typography>
        <InfoBulle position="right">
          En fournissant les informations ci-dessus, l’entité soumise à la
          surveillance prudentielle doit tenir compte des facteurs présentés
          dans les orientations communes de l’AEMF et de l’ABE sur l’évaluation
          de l’aptitude (EBA/GL/2021/06)
        </InfoBulle>
      </Box>
      <TextInputCustom
        multiline
        disabled={disabled}
        portalTargets="imas"
        source="evaluationTempsConsacre"
        id={`${tabName}#evaluationTempsConsacre`}
        fullWidth
      />

      <Box display="flex" width="100%">
        <Typography variant="subtitle1" portalTargets="imas">
          Capacité à consacrer un temps suffisant à l’exercice des fonctions,
          telle qu’évaluée par la personne nommée / personne à nommer
        </Typography>
        <InfoBulle position="right">
          En fournissant les informations ci-dessus, l’entité soumise à la
          surveillance prudentielle doit tenir compte des facteurs présentés
          dans les orientations communes de l’AEMF et de l’ABE sur l’évaluation
          de l’aptitude (EBA/GL/2021/06)
        </InfoBulle>
      </Box>
      <TextInputCustom
        multiline
        disabled={disabled}
        portalTargets="imas"
        source="capaciteTempsConsacre"
        id={`${tabName}#capaciteTempsConsacre`}
        fullWidth
      />

      <Box my="1em" />
      <Divider />
      <Box mt="1em" />

      <Typography variant="subtitle1">
        ▸ Est-ce que l’un des mandats mentionnés ci-dessus se trouve dans un
        établissement de crédit, une entreprise d’investissement ou une société
        de financement établi(e) en France, dont le total de bilan, social ou
        consolidé, est supérieur, depuis 2 exercices consécutifs, à 15 milliards
        d’euros ?
      </Typography>
      <NullableBooleanInputCustom
        disabled={disabled}
        source="mandatMilliardEuros"
      />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) => {
          if (formData.mandatMilliardEuros) {
            return (
              <Box>
                <Typography variant="subtitle1">
                  Mentionner l’entité (les entités) en question :
                </Typography>
                <TextInputCustom
                  disabled={disabled}
                  multiline
                  source="mandatMilliardEurosText"
                  id={`${tabName}#mandatMilliardEurosText`}
                  fullWidth
                  {...rest}
                />
              </Box>
            );
          } else {
            formData.mandatMilliardEurosText = null;
          }
        }}
      </FormDataConsumer>
      <Box>
        <Typography variant="subtitle1">
          {t(
            "▸ Si le décompte privilégie s’applique, veuillez indiquer l’existence éventuelle de synergies entre les différents postes occupés dans les entreprises concernées, et d’économies d’échelle en termes de temps consacré à ces différents postes :"
          )}
        </Typography>
        <TextInputCustom
          disabled={disabled}
          multiline
          source="synergiePoste"
          id={`${tabName}#synergiePoste`}
          fullWidth
        />
      </Box>

      <Typography variant="subtitle1">
        {t(
          "▸ Nombre total de mandats exécutifs après application du décompte privilégié et des exceptions :"
        )}
      </Typography>
      <NumberInputCustom
        disabled={disabled}
        id={`${tabName}#nombreMandatExecutif`}
        source="nombreMandatExecutif"
        label="Champ requis"
        validate={required()}
      />

      <Typography variant="subtitle1">
        {t(
          "▸ Nombre total de mandats non exécutifs après application du décompte privilégié et des exceptions :"
        )}
      </Typography>
      <NumberInputCustom
        disabled={disabled}
        id={`${tabName}#nombreMandatNonExecutif`}
        source="nombreMandatNonExecutif"
        label="Champ requis"
        validate={required()}
      />

      <Divider />
    </Box>
  );
});
