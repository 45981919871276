import React from "react";
import { FormDataConsumer } from "react-admin";

import { Typography, Box } from "@material-ui/core";
import TranslatableTextInputCustom from "../../../components/input/TranslatableTextInputCustom";
import DateInputCustom from "../../../components/input/DateInputCustom";
import SelectInputCustom from "../../../components/input/SelectInputCustom";
import NullableBooleanInputCustom from "../../../components/input/NullableBooleanInputCustom";

import { Enum } from "../../../utils";
import { Langue } from "../../../lib/langue";
import { useLangue } from "../../../candidatures/transalations";

const SUBSCRIPTION_VALUES = { values: true };

const CondamnationType = Enum({
  Criminal: "criminal",
  Administrative: "administrative",
  Civil: "civil",
  Other: "other",
});

const CondamnationEtape = Enum({
  Pending: "pending",
  Concluded: "concluded",
  UnderAppeal: "en appel",
});

const CondamnationRelation = Enum({
  RelatedToMe: "related to me",
  RelatedToAssociatedEntity: "related to associated entity",
  NotRelated: "not related",
});

const TYPE_CHOICES = {
  [Langue.Fr]: [
    { id: CondamnationType.Criminal, name: "Pénale" },
    { id: CondamnationType.Administrative, name: "Administrative" },
    { id: CondamnationType.Civil, name: "Civile" },
    { id: CondamnationType.Other, name: "Autre" },
  ],
  [Langue.En]: [
    { id: CondamnationType.Criminal, name: "Criminal" },
    { id: CondamnationType.Administrative, name: "Administrative" },
    { id: CondamnationType.Civil, name: "Civil" },
    { id: CondamnationType.Other, name: "Other" },
  ],
};

const ETAPE_CHOICES = {
  [Langue.Fr]: [
    { id: CondamnationEtape.Pending, name: "En cours" },
    { id: CondamnationEtape.Concluded, name: "Menée à terme" },
    { id: CondamnationEtape.UnderAppeal, name: "En appel" },
  ],
  [Langue.En]: [
    { id: CondamnationEtape.Pending, name: "Pending" },
    { id: CondamnationEtape.Concluded, name: "Concluded" },
    { id: CondamnationEtape.UnderAppeal, name: "Under appeal" },
  ],
};

const RELATION_CHOICES = {
  [Langue.Fr]: [
    {
      id: CondamnationRelation.RelatedToMe,
      name: "Oui, la procédure concerne la personne nommée",
    },
    {
      id: CondamnationRelation.RelatedToAssociatedEntity,
      name: "Oui, la procédre concerne une entité à laquelle la peronne nommée est associée",
    },
    { id: CondamnationRelation.NotRelated, name: "Non" },
  ],
  [Langue.En]: [
    {
      id: CondamnationRelation.RelatedToMe,
      name: "Yes, the proceeding is related to me personnally",
    },
    {
      id: CondamnationRelation.RelatedToAssociatedEntity,
      name: "Yes, the proceeding is related to an entity with which I am or have been associated",
    },
    { id: CondamnationRelation.NotRelated, name: "No" },
  ],
};

function Subtitle({ children, ...props }) {
  const { t } = useLangue();

  let c = typeof children === "string" ? t(children) : children;

  return (
    <Box maxWidth="72em">
      <Typography {...props}>{c}</Typography>
    </Box>
  );
}

export default function Condamnation({ disabled, tabName, ...rest }) {
  const { t, langue } = useLangue();
  return (
    <Box ml="2em" mr="2em">
      <Box display="flex">
        <SelectInputCustom
          label={t("Type de procédure")}
          disabled={disabled}
          source="infractionsIMAS.condamnationType"
          id={`${tabName}#infractionsIMAS.condamnationType`}
          choices={TYPE_CHOICES[langue]}
          {...rest}
        />
        <Box ml="0.5em">
          <SelectInputCustom
            label={t("Stade où en est la procédure")}
            disabled={disabled}
            source="infractionsIMAS.condamnationEtape"
            id={`${tabName}#infractionsIMAS.condamnationEtape`}
            choices={ETAPE_CHOICES[langue]}
            {...rest}
          />
        </Box>
      </Box>
      <FormDataConsumer subscription={SUBSCRIPTION_VALUES}>
        {({ formData }) => {
          if (
            formData.infractionsIMAS.condamnationType === CondamnationType.Other
          ) {
            return (
              <>
                <TranslatableTextInputCustom
                  label={t("Veuillez préciser le type de procédure")}
                  disabled={disabled}
                  multiline
                  source="infractionsIMAS.condamnationTypeAutre"
                  id={`${tabName}#infractionsIMAS.condamnationTypeAutre`}
                  fullWidth
                  {...rest}
                />
              </>
            );
          } else {
            formData.condamnationTypeAutre = null;
          }
        }}
      </FormDataConsumer>
      <Subtitle variant="subtitle1">
        Veuillez décrire brièvement les charges et la nature des actes
        répréhensibles présumés (par exemple : faute intentionnelle ou
        négligence, etc.) et indiquer à quel stade en est la procédure
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infractionsIMAS.condamnationText"
        id={`${tabName}#infractionsIMAS.condamnationText`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Veuillez indiquer l’autorité chargée de la procédure et, si possible, la
        référence du dossier
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infractionsIMAS.condamnationAutorite"
        id={`${tabName}#infractionsIMAS.condamnationAutorite`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">
        La procédure vous concerne-t-elle personnellement ou concerne-t-elle une
        entité à laquelle vous êtes ou avez été associé(e) ?
      </Subtitle>
      <SelectInputCustom
        disabled={disabled}
        source="infractionsIMAS.condamnationRelation"
        id={`${tabName}#infractionsIMAS.condamnationRelation`}
        choices={RELATION_CHOICES[langue]}
        {...rest}
      />
      <FormDataConsumer subscription={SUBSCRIPTION_VALUES}>
        {({ formData, ...rest }) => {
          switch (formData.infractionsIMAS?.condamnationRelation) {
            case CondamnationRelation.RelatedToMe:
              clearRelationEntite(formData);
              return (
                <RelationDirecte
                  disabled={disabled}
                  tabName={tabName}
                  {...rest}
                />
              );

            case CondamnationRelation.RelatedToAssociatedEntity:
              clearRelationDirecte(formData);
              return (
                <RelationEntite
                  disabled={disabled}
                  tabName={tabName}
                  {...rest}
                />
              );

            case CondamnationRelation.NotRelated:
            default:
              clearRelationDirecte(formData);
              clearRelationEntite(formData);
              return null;
          }
        }}
      </FormDataConsumer>
      <Subtitle variant="subtitle1">
        Date et/ou période de déroulement des actes répréhensibles présumés
      </Subtitle>
      <DateInputCustom
        disabled={disabled}
        source="infractionsIMAS.condamnationDateMefait"
        id={`${tabName}#infractionsIMAS.condamnationDateMefait`}
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Date de la décision, du jugement ou des conclusions (le cas échéant)
      </Subtitle>
      <DateInputCustom
        disabled={disabled}
        source="infractionsIMAS.condamantionDateJugement"
        id={`${tabName}#infractionsIMAS.condamantionDateJugement`}
        helperText="if applicable"
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Résumé du raisonnement sous-tendant la décision, le jugement ou les
        conclusions
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infractionsIMAS.condamnationResumeJugement"
        id={`${tabName}#infractionsIMAS.condamnationResumeJugement`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Sanction ou peine reçue (ou probable en cas de condamnation si les
        procédures sont en cours)
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infractionsIMAS.condamnationSanction"
        id={`${tabName}#infractionsIMAS.condamnationSanction`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Précisez l’éventail des sanctions (à savoir la sanction la moins sévère
        et la section la plus sévère) qui pourraient ou auraient pu être
        infligées.
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infractionsIMAS.condamnationFourchetteSanction"
        id={`${tabName}#infractionsIMAS.condamnationFourchetteSanction`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">
        La procédure a-t-elle abouti à un règlement (y compris au moyen d’un
        règlement extrajudiciaire) ?
      </Subtitle>
      <NullableBooleanInputCustom
        disabled={disabled}
        source="infractionsIMAS.condamnationAmiable"
        id={`${tabName}#infractionsIMAS.condamnationAmiable`}
        {...rest}
      />
      <FormDataConsumer subscriptions={SUBSCRIPTION_VALUES}>
        {({ formData, ...rest }) => {
          if (formData.infractionsIMAS?.condamnationAmiable) {
            return (
              <>
                <Subtitle variant="subtitle1">
                  Veuillez fournir des détails (notamment les parties au
                  règlement, la date, les montants convenus lors du règlement et
                  toute autre information pertinente).
                </Subtitle>
                <TranslatableTextInputCustom
                  disabled={disabled}
                  multiline
                  source="infractionsIMAS.condamnationAmiableText"
                  id={`${tabName}#infractionsIMAS.condamnationAmiableText`}
                  fullWidth
                  {...rest}
                />
              </>
            );
          } else {
            formData.condamnationAmiableText = null;
          }

          return null;
        }}
      </FormDataConsumer>
      <Subtitle variant="subtitle1">
        Veuillez fournir des détails sur votre conduite ultérieure, notamment
        les enseignements tirés et les mesures correctrices prises.
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infractionsIMAS.condamnationConduiteSubsequente"
        id={`${tabName}#infractionsIMAS.condamnationConduiteSubsequente`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Décrivez tout autre facteur atténuant ou aggravant à l’aide du Guide
        relatif à l’évaluation de l’honorabilité, des connaissances, des
        compétences et de l’expérience
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infractionsIMAS.condamnationCirconstanceAttenuanteAggravante"
        id={`${tabName}#infractionsIMAS.condamnationCirconstanceAttenuanteAggravante`}
        fullWidth
        {...rest}
      />

      <Subtitle variant="subtitle1">
        Les procédures mentionnées ci-dessous ont-elles donné lieu à des
        récupérations de rémunération ?
      </Subtitle>
      <NullableBooleanInputCustom
        disabled={disabled}
        source="infractionsIMAS.condamnationClauseRecuperation"
        id={`${tabName}#infractionsIMAS.condamnationClauseRecuperation`}
        {...rest}
      />
      <FormDataConsumer subscriptions={SUBSCRIPTION_VALUES}>
        {({ formData, ...rest }) => {
          if (formData.infractionsIMAS?.condamnationClauseRecuperation) {
            return (
              <>
                <Subtitle variant="subtitle1">
                  Veuillez fournir des détails
                </Subtitle>
                <TranslatableTextInputCustom
                  disabled={disabled}
                  multiline
                  source="infractionsIMAS.condamnationClauseRecuperationText"
                  id={`${tabName}#infractionsIMAS.condamnationClauseRecuperationText`}
                  fullWidth
                  {...rest}
                />
              </>
            );
          } else {
            formData.infractionsIMAS.condamnationClauseRecuperationText = null;
          }

          return null;
        }}
      </FormDataConsumer>
    </Box>
  );
}

function clearRelationDirecte(formData) {
  formData.infractionsIMAS.condamnationRelationDirecteCirconstances = null;
  formData.infractionsIMAS.condamnationRelationDirecteEvitement = null;
  formData.infractionsIMAS.condamnationRelationDirecteLesson = null;
}

function clearRelationEntite(formData) {
  formData.infractionsIMAS.condamnationRelationEntiteNom = null;
  formData.infractionsIMAS.condamnationRelationEntiteRole = null;
  formData.infractionsIMAS.condamnationRelationEntiteManagementRole = null;
  formData.infractionsIMAS.condamnationRelationEntiteEvitement = null;
  formData.infractionsIMAS.condamnationRelationEntiteLesson = null;
}

function RelationDirecte({ disabled, tabName, ...rest }) {
  return (
    <Box>
      <Subtitle variant="subtitle1">
        Précisez dans quelles circonstances et pour quelles raisons vous êtes
        concerné(e) directement
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infractionsIMAS.condamnationRelationDirecteCirconstances"
        id={`${tabName}#infractionsIMAS.condamnationRelationDirecteCirconstances`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Décrivez les mesures que vous avez prises pour prévenir les actes
        répréhensibles et/ou éviter qu’ils soient commis
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infractionsIMAS.condamnationRelationDirecteEvitement"
        id={`${tabName}#infractionsIMAS.condamnationRelationDirecteEvitement`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Qu’auriez-vous pu faire de plus pour éviter que ces actes répréhensibles
        présumés soient commis et avez-vous tiré des enseignements de ce qu’il
        s’est passé ?
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infractionsIMAS.condamnationRelationDirecteLesson"
        id={`${tabName}#infractionsIMAS.condamnationRelationDirecteLesson`}
        fullWidth
        {...rest}
      />
    </Box>
  );
}

function RelationEntite({ disabled, tabName, ...rest }) {
  return (
    <Box>
      <Subtitle variant="subtitle1">
        Précisez le nom de l’entité concernée
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infractionsIMAS.condamnationRelationEntiteNom"
        id={`${tabName}#infractionsIMAS.condamnationRelationEntiteNom`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Précisez votre rôle au sein de l’entité et indiquez si vous êtes ou
        étiez à la tête d’un service ou d’une ligne métier concerné(e) par la
        procédure (y compris par les sanctions ou les mesures imposées)
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infractionsIMAS.condamnationRelationEntiteRole"
        id={`${tabName}#infractionsIMAS.condamnationRelationEntiteRole`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Étiez-vous membre de l’organe de direction, titulaire d’une fonction-clé
        ou cadre supérieur(e) lorsque les actes répréhensibles présumés ont été
        commis ?
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infractionsIMAS.condamnationRelationEntiteManagementRole"
        id={`${tabName}#infractionsIMAS.condamnationRelationEntiteManagementRole`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Dans le cadre de vos fonctions au sein de l’entité, qu’avez-vous fait
        pour prévenir les actes répréhensibles présumés et/ou pour éviter qu’ils
        soient commis ?
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infractionsIMAS.condamnationRelationEntiteEvitement"
        id={`${tabName}#infractionsIMAS.condamnationRelationEntiteEvitement`}
        fullWidth
        {...rest}
      />
      <Subtitle variant="subtitle1">
        Qu’auriez-vous pu faire de plus pour éviter que ces actes répréhensibles
        présumés soient commis et avez-vous tiré des enseignements de ce qu’il
        s’est passé ?
      </Subtitle>
      <TranslatableTextInputCustom
        disabled={disabled}
        multiline
        source="infractionsIMAS.condamnationRelationEntiteLesson"
        id={`${tabName}#infractionsIMAS.condamnationRelationEntiteLesson`}
        fullWidth
        {...rest}
      />
    </Box>
  );
}

Condamnation.clear = function (formData) {
  if (!formData.infractionsIMAS) return;

  formData.infractionsIMAS.condamnationEtape = null;
  formData.infractionsIMAS.condamnationType = null;
  formData.infractionsIMAS.condamnationTypeAutre = null;
  formData.infractionsIMAS.condamnationText = null;
  formData.infractionsIMAS.condamnationAutorite = null;
  formData.infractionsIMAS.condamnationRelation = null;
  clearRelationDirecte(formData);
  clearRelationEntite(formData);
  formData.infractionsIMAS.condamnationDateMefait = null;
  formData.infractionsIMAS.condamantionDateJugement = null;
  formData.infractionsIMAS.condamnationResumeJugement = null;
  formData.infractionsIMAS.condamnationSanction = null;
  formData.infractionsIMAS.condamnationFourchetteSanction = null;
  formData.infractionsIMAS.condamnationAmiable = null;
  formData.infractionsIMAS.condamnationAmiableText = null;
  formData.infractionsIMAS.condamnationConduiteSubsequente = null;
  formData.infractionsIMAS.condamnationCirconstanceAttenuanteAggravante = null;
  formData.infractionsIMAS.condamnationClauseRecuperation = null;
  formData.infractionsIMAS.condamnationClauseRecuperationText = null;

  if (formData.infractionsIMAS.traductions) {
    formData.infractionsIMAS.traductions.forEach((_traduction, idx) => {
      formData.infractionsIMAS.traductions[idx].condamnationTypeAutre = null;
      formData.infractionsIMAS.traductions[idx].condamnationText = null;
      formData.infractionsIMAS.traductions[idx].condamnationAutorite = null;
      formData.infractionsIMAS.traductions[idx].condamnationRelation = null;
      formData.infractionsIMAS.traductions[idx].condamnationResumeJugement =
        null;
      formData.infractionsIMAS.traductions[idx].condamnationSanction = null;
      formData.infractionsIMAS.traductions[idx].condamnationFourchetteSanction =
        null;
      formData.infractionsIMAS.traductions[idx].condamnationAmiableText = null;
      formData.infractionsIMAS.traductions[
        idx
      ].condamnationConduiteSubsequente = null;
      formData.infractionsIMAS.traductions[
        idx
      ].condamnationCirconstanceAttenuanteAggravante = null;
      formData.infractionsIMAS.traductions[
        idx
      ].condamnationClauseRecuperationText = null;
    });
  }
};
