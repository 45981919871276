import React, { useCallback, useRef, useMemo } from "react";
import Files from "react-files";
import { useNotify } from "react-admin";
import { authedFetch } from "../authProvider";
import FileDl from "./FileDl.js";
import { useField } from "react-final-form";

import { reportError } from "../lib/error-report";

const { REACT_APP_API_URL } = process.env;

function wrap(objectOrArray) {
  let array;
  if (Array.isArray(objectOrArray)) {
    array = objectOrArray;
  } else {
    array = [objectOrArray];
  }

  return array;
}

export default function File({
  entity,
  label,
  source,
  options,
  isArray,
  disabled: extDisabled,
}) {
  isArray = !!isArray;

  let { input } = useField(source);

  const notify = useNotify();
  let { value, onChange } = input;

  const entityInput = useField(entity);

  let files = useMemo(() => {
    return wrap(value || []).filter((a) => !!a);
  }, [value]);

  const candidat = useField("id");
  const refs = useRef();
  const disabled = useMemo(() => {
    if (extDisabled) return extDisabled;

    try {
      if (["pieceIdentite", "cv"].includes(source)) {
        if (!candidat.input.value) {
          throw new Error();
        }
      }

      if (source.includes("refusEtValidation")) {
        // const index = name.match(new RegExp("(?<=\\[).+?(?=\\])"));
        const rvEntity = entityInput.input.value;
        if (!rvEntity || (rvEntity && !rvEntity.id)) {
          throw new Error();
        }
      }

      return false;
    } catch (err) {
      return true;
    }
  }, [candidat.input.value, entityInput.input.value, extDisabled, source]);

  const associateFilesToEntity = useCallback(
    ({ files, name }) => {
      if (!candidat.input.value) {
        throw new Error(
          "Vous devez d'abord enregitrer votre entité avant de pouvoir sauvegarder un fichier"
        );
      }

      if (name.includes("refusEtValidation")) {
        // const index = name.match(new RegExp("(?<=\\[).+?(?=\\])"));
        const rvEntity = entityInput.input.value;

        if (!rvEntity || (rvEntity && !rvEntity.id)) {
          throw new Error(
            "Vous devez d'abord enregitrer votre entité avant de pouvoir sauvegarder un fichier"
          );
        }

        files = files.map((f) => {
          f.field = `justificatifId`;
          f.value = rvEntity.id;
          return f;
        });
      } else {
        files = files.map((f) => {
          f.field = `${name}Id`;
          f.value = candidat.input.value;

          return f;
        });
      }

      return files;
    },
    [candidat.input.value, entityInput.input.value]
  );

  const uploadFile = useCallback(
    async (token, name, files, candidature) => {
      const formData = new FormData();
      try {
        const newFiles = associateFilesToEntity({
          files,
          name,
          token,
          candidature,
        });

        Object.keys(newFiles).forEach((key) => {
          const file = newFiles[key];

          formData.append(
            `${file.field}-${file.value}`,
            new Blob([file], { type: file.type }),
            file.name || "file"
          );
        });
      } catch (err) {
        notify(
          "Vous devez d'abord enregitrer votre entité avant de pouvoir sauvegarder un fichier",
          "warning"
        );
        return;
      }

      try {
        const results = await authedFetch(
          `${REACT_APP_API_URL}/candidatures/upload-files`,
          {
            method: "POST",
            body: formData,
            headers: {
              Accept: "application/json",
            },
          }
        );

        if (results && results.status === 200) {
          notify("Fichier enregistré");
          return results.json();
        } else {
          reportError(
            `Erreur d’enregistrement de fichier: ${source} STATUS: ${results.status}`
          );
          notify("Le fichier n’a pas pu être enregistré", "warning");
        }
      } catch (err) {
        reportError(
          `Erreur d’enregistrement de fichier: ${source} ${err.message}`
        );
        notify("Le fichier n’a pas pu être enregistré", "warning");
      }
    },
    [notify, source, associateFilesToEntity]
  );

  const token = "";
  const candidature = "";

  // Ensure there is value, remove possible
  // null/falsy values in there that may crash
  // the app
  value = files;

  const onFilesError = useCallback(
    (error, _file) => {
      error.message = `${source}: ${error.message}`;
      reportError(error);
    },
    [source]
  );

  const handleChanges = async (f) => {
    if (value) {
      const existing = f.find((f) => value.find((v) => v.name === f.name));
      if (existing) {
        notify("Un fichier portant le même nom existe déjà");
        return;
      }

      f = f.filter((f) => !value.find((v) => v.name === f.name));
    }

    if (f.length > 0) {
      const result = await uploadFile(token, source, f, candidature);

      if (result?.files) {
        onChange(
          result.files.map((x) => ({ name: x.name, type: x.type, id: x.id }))
        );
      }
    }
  };

  let show = isArray;
  if (!isArray && value.length === 0) {
    show = true;
  }

  let maxFiles = options?.limit;
  if (!isArray) {
    maxFiles = 1;
  }

  return (
    <label htmlFor={source} style={{ opacity: disabled ? "50%" : "100%" }}>
      <span dangerouslySetInnerHTML={{ __html: label }} />
      {show && (
        <Files
          ref={(r) => (refs.current = r)}
          className={`mt-3 ${
            disabled ? "" : "cursor-pointer"
          } files-dropzone-list`}
          style={{
            border: "dashed",
            borderColor: "#9A9A9A",
            borderWidth: "thin",
            borderRadius: "0.25em",
            fontColor: "#9A9A9A",
            height: "100px",
            cursor: "pointer",
            fontSize: "small",
            padding: "0.5em",
          }}
          onChange={handleChanges}
          onError={onFilesError}
          multiple={isArray}
          maxFiles={maxFiles}
          maxFileSize={10000000}
          minFileSize={0}
          clickable={!disabled}
        >
          Cliquez ici pour téléverser vos documents
        </Files>
      )}
      <FileDl
        onChange={onChange}
        value={value}
        source={source}
        disabled={disabled}
      />
      {disabled && (
        <span style={{ color: "red" }}>
          Vous devez d'abord enregistrer avant de pouvoir sauvegarder un fichier
        </span>
      )}
    </label>
  );
}
