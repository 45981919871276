import React from "react";
import { FormDataConsumer } from "react-admin";

import Typography from "../../../components/custom/TypographyCustom";

export default function AlertManyFonctions({ alertAt }) {
  if (typeof alertAt !== "number")
    throw new Error("AlertManyFonctions: alertAt must be a number");
  if (alertAt < 0)
    throw new Error("AlertManyFonctions: alertAt must be a positive number");
  const threshold = Math.round(alertAt);
  if (threshold !== alertAt)
    console.warn(
      `AlertManyFonctions: value of "alertAt" should be an integer, bur is ${alertAt}. It has been rounded to ${threshold}`
    );

  return (
    <FormDataConsumer subscriptions={{ values: true }}>
      {({ formData: { mandatFonctions } }) => {
        if (mandatFonctions && mandatFonctions.length >= threshold) {
          return (
            <Typography variant="h6" color="error">
              Attention : le nombre total de mandats/fonctions atteint le nombre
              de {threshold.toString()}. Merci de porter une vigilance
              particulière aux critères de cumul des mandats et de disponibilité
              du candidat.
            </Typography>
          );
        }
        return false;
      }}
    </FormDataConsumer>
  );
}
