import React, { memo } from "react";
import { Typography, Box } from "@material-ui/core";
import FileInputCustom from "../../components/input/FileInputCustom";
import { useGlobalDisabled } from "./helper";

export default memo(() => {
  const disabled = useGlobalDisabled();

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Procès-verbaux relatifs à la nomination/renouvellement, lettre de
        désignation du représentant permanent
        <FileInputCustom multiple source={"procesVerbal"} disabled={disabled} />
      </Typography>

      <Typography variant="h6" gutterBottom>
        Copie certifiée conforme d’un extrait des délibérations de l’organe
        social lui ayant délégué les pouvoirs nécessaires à l’exercice d’une
        direction effective de l’établissement
        <FileInputCustom
          multiple={false}
          source={"delegationPouvoir"}
          disabled={disabled}
        />
      </Typography>

      <Typography variant="h6" gutterBottom>
        Extrait du casier judiciaire (pour les personnes ne résidant pas en
        France depuis trois ans au moins)
        <FileInputCustom
          multiple={false}
          source={"extraitCasier"}
          disabled={disabled}
        />
      </Typography>

      <Typography variant="h6" gutterBottom>
        Une copie de la notification de toute évaluation faite par une autorité
        bancaire ou financière autre que l’ACPR ou la BCE (il est uniquement
        demandé la notification de la décision finale lorsque celle-ci a été
        produite)
        <FileInputCustom
          multiple={false}
          source={"notificationEvaluation"}
          disabled={disabled}
        />
      </Typography>

      <Typography variant="h6" gutterBottom>
        La politique de gestion des conflits d’intérêts
        <FileInputCustom
          multiple={false}
          source={"politiqueConflitsInterets"}
          disabled={disabled}
        />
      </Typography>

      <Typography variant="h6" gutterBottom>
        Organigramme de l’entité soumise à la surveillance prudentielle
        <FileInputCustom
          multiple={false}
          source={"organigrammeEntite"}
          disabled={disabled}
        />
      </Typography>

      <Typography variant="h6" gutterBottom>
        Organigramme du groupe d’appartenance
        <FileInputCustom
          multiple={false}
          source={"organigrammeGroupeAppartenance"}
          disabled={disabled}
        />
      </Typography>

      <Typography variant="h6" gutterBottom>
        L’évaluation de l’adéquation de la personne nommée (renouvelée) réalisée
        par l’entité soumise à la surveillance prudentielle
        <FileInputCustom
          multiple={false}
          source={"evaluationAdequation"}
          disabled={disabled}
        />
      </Typography>

      <Typography variant="h6" gutterBottom>
        Autres
        <FileInputCustom disabled={disabled} multiple source={"autre"} />
      </Typography>
    </Box>
  );
});
