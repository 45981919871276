import { createTheme } from "@material-ui/core/styles";
import { defaultTheme } from "react-admin";
import merge from "lodash/merge";

console.log(defaultTheme);

export default createTheme(
  merge({}, defaultTheme, {
    spacing: (factor) => `${8 * factor}px`,
    breakpoints: { ...defaultTheme.breakpoints },
    palette: {
      // primary: indigo,
      // secondary: pink,
      // error: red,
      // contrastThreshold: 3,
      // tonalOffset: 0.2,
    },
    typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontSize: 14,
    },
    overrides: {
      MuiTableCell: {
        head: {
          fontSize: 13,
          lineHeight: "unset",
        },
        root: {
          fontSize: 13,
          lineHeight: "unset",
        },
        sizeSmall: {
          padding: "6px 12px 6px 8px",
        },
      },
      MuiMenuItem: {
        root: {
          paddingTop: 3,
          paddingBottom: 3,
        },
      },
      RaMenuItemLink: {
        root: {
          fontSize: 12,
        },
      },
      RaSidebar: {
        drawerPaper: {
          width: 190,
        },
      },
      RaMenu: {
        open: {
          width: 190,
        },
      },
    },
  })
);
