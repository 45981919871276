import React, { memo, useState, useMemo, useRef } from "react";

import lget from "lodash/get";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import { IconFlagFR, IconFlagUK } from "material-ui-flags";

import { Langue } from "../../lib/langue";
import TextInputCustom from "./TextInputCustom";
import { useOnlyForTargets } from "../../candidatures/PortalTarget";
import { useLangue } from "../../candidatures/transalations";
import { FormDataConsumer } from "react-admin";

const flags = {
  [Langue.Fr]: IconFlagFR,
  [Langue.En]: IconFlagUK,
};

function find(object, path) {
  if (object === null) return undefined;
  path = path.replaceAll(/\[(\d+)\]/g, ".$1");
  let pathParts = path.split("."),
    property = pathParts.shift();

  while (property) {
    if (!Object.hasOwnProperty.call(object, property)) {
      return undefined;
    }

    object = object[property];
    property = pathParts.shift();
  }

  return object;
}

function findTranslatedSource(record, source, langue) {
  const pathParts = source.split(".");
  const property = pathParts.pop();
  let path = pathParts.join(".");

  let translatable = find(record, path);

  if (langue !== defaultLangue) {
    let traductions = translatable?.traductions || [],
      i = 0;

    // Find the first traduction that matches the langue
    let targetTraduction = traductions.find((s) => s.lang === langue);
    if (targetTraduction) {
      i = traductions.indexOf(targetTraduction);
    } else {
      i = traductions.length;
    }

    source = `traductions[${i}].${property}`;

    if (path.length > 0) {
      path += ".";
    }

    return path + source;
  }

  return source;
}

const defaultLangue = Langue.Fr;
const _memo = {};
const propsMemo = {};

const translationMemo = {};
function memoTranslations(record, sources, source) {
  const translatedValues = {};
  for (let l of Langue) {
    translatedValues[l] = lget(record, sources[l]);
  }

  if (!translationMemo[source]) {
    translationMemo[source] = {};
  }

  if (
    Object.keys(translationMemo[source]).length !==
      Object.keys(translatedValues).length ||
    Object.entries(translationMemo[source]).some(([k, v]) => {
      return translatedValues[k] !== v;
    })
  ) {
    translationMemo[source] = translatedValues;
  }

  return translationMemo[source];
}

function Inputs(props) {
  let {
    record,
    source,
    style,
    className,
    portalTargets,
    disabled: origDisabled,
    forwardedRef: ref,
    validate,
    ...rest
  } = props;

  let disabled = useOnlyForTargets(portalTargets, origDisabled);

  const rkey = source + JSON.stringify(rest);
  if (propsMemo[rkey]) {
    rest = propsMemo[rkey];
  } else {
    propsMemo[rkey] = rest;
  }

  const { langue: initialLangue } = useLangue();
  let multilingue =
    record.multilingue || (initialLangue && initialLangue !== Langue.Fr);

  const [langue, setLangue] = useState(initialLangue || Langue.Fr);
  const mounted = useRef(false);

  let sources = useMemo(() => {
    if (multilingue && !mounted.current) {
      mounted.current = true;

      const s = Langue.reduce((acc, l) => {
        if (l !== defaultLangue) {
          acc[l] = findTranslatedSource(record, source, l);
        } else {
          acc[l] = source;
        }

        return acc;
      }, {});

      _memo[source] = s;
    }

    return _memo[source] || {};
  }, [multilingue, record, source]);

  const translatedValues = memoTranslations(record, sources, source);

  return useMemo(
    () => {
      if (!multilingue) {
        return (
          <TextInputCustom
            ref={ref}
            {...rest}
            validate={validate}
            disabled={disabled}
            key={`${langue}_${source}`}
            className={className}
            source={source}
          />
        );
      }

      // using for here guarantees the order of things
      const i = Langue.map((l) => {
        let localStyle = { ...style };
        if (l === langue) {
          delete localStyle.display;
        } else {
          localStyle.display = "none";
        }

        const Flag = flags[l];
        const nextLangue = Langue.next(l);
        const s = sources[l];
        const t = translatedValues || {};

        const atLeastOneMissing = Object.values(t).some((v) => {
          return !v;
        });
        const atLeastOneOk = Object.values(t).some((v) => !!v);

        let missingTranslation = atLeastOneMissing && atLeastOneOk;

        let localClassName = className;
        if (missingTranslation) {
          localClassName += " missing-translation";
        }
        let validateFn = l === Langue.Fr ? validate : undefined;

        return (
          <TextInputCustom
            ref={ref}
            {...rest}
            validate={validateFn}
            disabled={disabled}
            className={localClassName}
            style={localStyle}
            key={`${l}_${s}`}
            source={s}
            helperText={
              missingTranslation ? "Traduction manquante" : rest.helperText
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setLangue(nextLangue)}>
                    <Flag />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        );
      });

      return i;
    },
    /* eslint-disable-next-line */
    [
      className,
      disabled,
      langue,
      multilingue,
      // ref,
      source,
      // style,
      translatedValues,
    ],
  );
}

const MInputs = memo(Inputs);

const TranslatableTextInputCustom = memo(
  React.forwardRef((props, ref) => {
    // let { style, source, ...rest } = props;
    // if (style === undefined) {
    //   style = EMPTY_OBJECT;
    // }
    return (
      <FormDataConsumer>
        {({ formData: record }) => (
          <MInputs
            record={record}
            // source={source}
            // style={style}
            forwardedRef={ref}
            // {...rest}
            {...props}
          />
        )}
      </FormDataConsumer>
    );
  }),
);
TranslatableTextInputCustom.whyDidYouRender = true;

export default TranslatableTextInputCustom;
