import React from "react";

import { MuiThemeProvider } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import theme from "../../theme";

Layout.defaultProps = {
  theme,
};

export default function Layout({ children, theme }) {
  return (
    <MuiThemeProvider theme={theme}>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ backgroundColor: "#7e7e7e", minHeight: "100vh" }}
      >
        <Paper style={{ height: "auto", width: "32em" }}>
          <Box p="1em">{children}</Box>
        </Paper>
      </Grid>
    </MuiThemeProvider>
  );
}
