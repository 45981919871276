import React, { useEffect, useRef, useState, useCallback, memo } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-admin";
import { Alert, AlertTitle } from "@material-ui/lab";
import Box from "@material-ui/core/Box";

import { CreateReportModal } from "../bugreport/Create";

import CandidatEditAside from "./CandidatEditAside";
import Edit from "../components/Edit";
import { DisconnectionAlert } from "../components/DisconnectionAlert";
import ConflitAlert from "../candidatures/ConflitAlert";
import CandidatForm from "./form/CandidatForm";
import PostEditActions from "../components/custom/PostEditActions";
import { ErrorBoundary } from "../lib/error-report";
import { FONCTIONS } from "../candidatures/fonctionNames";

import { authedFetch } from "../authProvider";

const EditTitle = ({ record }) => (
  <span>
    Candidat{" "}
    {record
      ? `#${record.id || ""} - ${record.prenom || ""} ${record.nom || ""}`
      : ""}
  </span>
);

const { REACT_APP_API_URL } = process.env;
function useIsBlocked(id) {
  const [blocked, setBlocked] = useState(false);
  const [candidature, setCandidature] = useState(null);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
  }, []);

  useEffect(() => {
    (async () => {
      const response = await authedFetch(
        `${REACT_APP_API_URL}/candidats/${id}/blocked`
      );

      if (response.status === 200) {
        let c = await response.text();

        if (mounted.current) {
          try {
            c = JSON.parse(c);
            setCandidature(c);
            setBlocked(true);
          } catch (_) {
            setCandidature(null);
            setBlocked(false);
          }
        }
      } else {
      }
    })();
  }, [id]);

  return {
    blocked,
    candidature,
  };
}

function AlertContent({ candidature }) {
  if (!candidature || !candidature.mandatFonctions) return null;
  const fonction = candidature.mandatFonctions[0];
  const entite = candidature.entite;

  const intituleFonction =
    fonction.fonctionId !== "autre"
      ? FONCTIONS[fonction.fonctionId].name[entite.langue]
      : fonction.intituleFonction;

  return (
    <span>
      La{" "}
      <Link to={`/candidatures/${candidature.id}`} style={{ color: "white" }}>
        candidature n°{candidature.id} aux fonctions de {intituleFonction} chez{" "}
        {entite.denominationSociale}
      </Link>{" "}
      bloque l’édition des informations de ce·tte candidat·e.
      <br />
      <br />
      Si des modifications sont impératives, vous pouvez basculer cette
      candidature au statut « Brouillon ».
      <br />
      Le ou la candidate n’aura plus accès au formulaire et vous devrez lui
      envoyer un nouveau lien.
    </span>
  );
}

export default memo((props) => {
  const [showModal, setShowModal] = useState(false);
  const form = useRef({});

  const onFormChange = useCallback((f) => {
    form.current = f;
  }, []);

  const record = useRef({});

  const { blocked, candidature } = useIsBlocked(props.id);

  return (
    <ErrorBoundary>
      <DisconnectionAlert />
      <ConflitAlert createdAt={record.current.createdAt} />
      {blocked && (
        <Box mt="1.75em">
          <Alert severity="warning" variant="filled">
            <AlertTitle>
              <strong>Candidature soumise au candidat</strong>
            </AlertTitle>
            <AlertContent candidature={candidature} />
          </Alert>
        </Box>
      )}{" "}
      <Edit
        title={<EditTitle />}
        actions={
          <PostEditActions>
            <Button
              label="Signaler un incident"
              onClick={() => setShowModal(true)}
            />
          </PostEditActions>
        }
        aside={<CandidatEditAside />}
        {...props}
        redirect={false}
      >
        <CandidatForm
          onFormChange={onFormChange}
          disabled={blocked}
          redirect={false}
          recordRef={record}
        />
      </Edit>
      <CreateReportModal
        {...props}
        open={showModal}
        onClose={() => setShowModal(false)}
        formState={form}
      />
    </ErrorBoundary>
  );
});
