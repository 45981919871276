import React from "react";
import Typography from "@material-ui/core/Typography";

import { useLangue } from "../../candidatures/transalations";
import { useOnlyForTargets } from "../../candidatures/PortalTarget";

export default React.forwardRef(
  ({ children, portalTargets, ...props }, ref) => {
    const { t } = useLangue();
    let _children = children;

    if (typeof _children === "string") {
      _children = t(children);
    }

    const disabled = useOnlyForTargets(portalTargets, false);
    const style = { opacity: disabled ? "0.3" : "1" };

    return (
      <Typography ref={ref} {...props} style={style}>
        {_children}
      </Typography>
    );
  }
);
